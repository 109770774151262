import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgEdit = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1 14.993V10.0515L9.47121 1.58221C10.2522 0.801373 11.5183 0.801373 12.2993 1.58221L14.4132 3.69561C15.1945 4.47669 15.1945 5.74327 14.4132 6.52436L5.94263 14.993H1ZM5.52848 13.993L10.7581 8.7646L7.22982 5.23714L2 10.4658V13.993H5.52848ZM7.937 4.53012L11.4653 8.05757L13.7062 5.81717C14.0968 5.42663 14.0968 4.79334 13.7062 4.4028L11.5923 2.28939C11.2018 1.89898 10.5687 1.89898 10.1782 2.28939L7.937 4.53012Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M9.48975 13.9982C9.2136 13.9982 8.98975 14.2221 8.98975 14.4982C8.98975 14.7743 9.2136 14.9982 9.48975 14.9982H14.4865C14.7626 14.9982 14.9865 14.7743 14.9865 14.4982C14.9865 14.2221 14.7626 13.9982 14.4865 13.9982H9.48975Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
