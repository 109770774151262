import React, { HTMLAttributes } from 'react';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { BadgeStyle } from './badgeStyle.ts';
import { StyledBadge } from './components/StyledBadge.tsx';
import { StyledBadgeContent } from './components/StyledBadgeContent.tsx';

type Props = {
  readonly badgeStyle?: BadgeStyle;
  readonly value?: number;
};

export const maxBadgeValue = 99;

export const Badge = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props & HTMLAttributes<HTMLDivElement>>
>(({ badgeStyle, value, ...otherProps }, forwardedRef) => {
  const withValue = typeof value !== 'undefined' && value <= maxBadgeValue;
  const showBadge = value !== 0;

  if (!showBadge) {
    return null;
  }

  return (
    <StyledBadge
      ref={forwardedRef}
      $badgeStyle={badgeStyle ?? 'contextual'}
      withValue={withValue}
      {...otherProps}
      {...getDataUiComponentAttribute(Badge)}
    >
      {withValue && <StyledBadgeContent>{value}</StyledBadgeContent>}
    </StyledBadge>
  );
});

Badge.displayName = 'Badge';
