import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgBrandInstagram = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.00146 7.99951C4.00146 5.79105 5.79178 4.00073 8.00025 4.00073C10.2087 4.00073 11.999 5.79105 11.999 7.99951C11.999 10.208 10.2087 11.9983 8.00025 11.9983C5.79178 11.9983 4.00146 10.208 4.00146 7.99951ZM8.00025 5.00073C6.34406 5.00073 5.00146 6.34333 5.00146 7.99951C5.00146 9.65569 6.34406 10.9983 8.00025 10.9983C9.65643 10.9983 10.999 9.65569 10.999 7.99951C10.999 6.34333 9.65643 5.00073 8.00025 5.00073Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M12.5 3C12.2239 3 12 3.22386 12 3.5C12 3.77614 12.2239 4 12.5 4C12.7761 4 13 3.77614 13 3.5C13 3.22386 12.7761 3 12.5 3Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.00293 1.0022C2.34608 1.0022 1.00293 2.34534 1.00293 4.0022V11.9973C1.00293 13.6542 2.34608 14.9973 4.00293 14.9973H11.998C13.6549 14.9973 14.998 13.6542 14.998 11.9973V4.0022C14.998 2.34534 13.6549 1.0022 11.998 1.0022H4.00293ZM11.998 2.0022H4.00293C2.89836 2.0022 2.00293 2.89763 2.00293 4.0022V11.9973C2.00293 13.1019 2.89836 13.9973 4.00293 13.9973H11.998C13.1026 13.9973 13.998 13.1019 13.998 11.9973V4.0022C13.998 2.89763 13.1026 2.0022 11.998 2.0022Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
