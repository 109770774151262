import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgRotateDoubleRightSlash = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M14.8817 2.17704C15.0601 2.38784 15.0338 2.70333 14.823 2.8817L12.8877 4.51926C13.4039 5.24304 13.7637 6.08666 13.917 7.00001H15.0284C15.4351 7.00001 15.6716 7.4597 15.4353 7.79063L13.9069 9.9304C13.7074 10.2096 13.2925 10.2096 13.0931 9.9304L11.5647 7.79063C11.3283 7.4597 11.5649 7.00001 11.9716 7.00001H12.9001C12.7643 6.33107 12.4951 5.71079 12.1212 5.16784L4.52515 11.5952C4.5834 11.6515 4.64301 11.7064 4.70393 11.7599C5.58425 12.5323 6.73688 13 7.99999 13C9.32889 13 10.5358 12.4822 11.4317 11.6364C11.6325 11.4469 11.9489 11.456 12.1385 11.6568C12.3281 11.8576 12.319 12.174 12.1182 12.3636C11.0441 13.3776 9.59416 14 7.99999 14C6.48504 14 5.10014 13.4379 4.0444 12.5116C3.94624 12.4254 3.85092 12.3362 3.75858 12.2439L1.82296 13.8817C1.61216 14.0601 1.29667 14.0338 1.1183 13.823C0.939925 13.6122 0.966215 13.2967 1.17702 13.1183L3.11233 11.4807C2.59606 10.757 2.23631 9.91347 2.08299 9.00001H0.971585C0.564898 9.00001 0.328336 8.54033 0.564718 8.20939L2.09312 6.06962C2.29253 5.79045 2.70745 5.79045 2.90686 6.06962L4.43526 8.20939C4.67164 8.54033 4.43508 9.00001 4.0284 9.00001H3.09995C3.23566 9.66887 3.50488 10.2892 3.8788 10.8322L11.4748 4.40479C10.5747 3.53457 9.35007 3.00001 7.99999 3.00001C6.67108 3.00001 5.4642 3.51778 4.56829 4.36358C4.3675 4.55315 4.05104 4.54405 3.86148 4.34325C3.67191 4.14245 3.68101 3.826 3.88181 3.63644C4.95593 2.62239 6.40582 2.00001 7.99999 2.00001C9.65625 2.00001 11.1562 2.67166 12.2414 3.75615L14.177 2.11832C14.3878 1.93995 14.7033 1.96624 14.8817 2.17704Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
