import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgDocTorn = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M11 16.0002L9.8 13.8335L8 16.0002L5.6 15.1335L4.4 16.0002L2.32989 14.1313C2.11987 13.9417 2 13.672 2 13.3891V0.999999C2 0.447714 2.44772 0 3 0H9.41466L14 4.57582V12.5126C14 12.6688 13.9634 12.8228 13.8932 12.9623L12.8 15.1335L11 16.0002ZM7.67939 14.8212L5.44277 14.0135L4.46996 14.7161L3 13.3891V0.999999L7.96436 1V4.49668C7.96436 5.32511 8.63593 5.99668 9.46436 5.99668H13V12.5126L12.0598 14.3801L11.4172 14.6895L9.96585 12.069L7.67939 14.8212ZM13 4.99668V4.99064L9.00105 1H8.96436V4.49668C8.96436 4.77282 9.18821 4.99668 9.46436 4.99668H13Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
