export const LineHeight = {
  XS: 14,
  S: 16,
  M: 18,
  L: 20,
  XL: 24,
  XXL: 32,
  XXXL: 40,
} as const;

export type LineHeight = (typeof LineHeight)[keyof typeof LineHeight];
