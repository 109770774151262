import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgDocArrows = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.00363 1H9.42188L13.0032 4.6157V14.0001C13.0032 14.5523 12.5555 15 12.0032 15H4.00363C3.45137 15 3.00368 14.5523 3.00368 14.0001V1.99989C3.00368 1.44767 3.45137 1 4.00363 1ZM12.0032 14.0001H4.00363V1.99989L7.99951 1.99989V4.50595C7.99951 5.33428 8.67105 6.00578 9.49944 6.00578H12.0032V14.0001ZM11.9823 5.00589L9.00487 1.99989H8.99946V4.50595C8.99946 4.78206 9.22331 5.00589 9.49944 5.00589H11.9823Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M14.3865 10.7975C14.2199 11.0178 13.9063 11.0613 13.6861 10.8947 13.4659 10.7282 13.4223 10.4147 13.5889 10.1944L14.8732 8.49621 13.5889 6.79799C13.4223 6.57776 13.4659 6.26422 13.6861 6.09768 13.9063 5.93114 14.2199 5.97466 14.3865 6.19489L15.8988 8.19466C16.0337 8.37306 16.0337 8.61936 15.8988 8.79776L14.3865 10.7975ZM1.62132 10.7985C1.78837 11.0183 2.10203 11.0611 2.3219 10.8941 2.54176 10.7271 2.58458 10.4134 2.41754 10.1936L1.12788 8.49624 2.41753 6.79892C2.58458 6.57906 2.54176 6.26543 2.3219 6.09839 2.10203 5.93135 1.78837 5.97417 1.62132 6.19402L.101869 8.19379C-.0339563 8.37255-.0339563 8.61993.101869 8.79869L1.62132 10.7985Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
