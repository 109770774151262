import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgCbCheck = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M12.5045 4.79055C12.665 4.56584 12.613 4.25357 12.3883 4.09306C12.1636 3.93256 11.8513 3.98461 11.6908 4.20931L7.0221 10.7455L4.43882 8.3344C4.23694 8.14599 3.92055 8.1569 3.73213 8.35877C3.54371 8.56065 3.55462 8.87704 3.7565 9.06546L6.7565 11.8655C6.86174 11.9637 7.00423 12.0118 7.14748 11.9974C7.29073 11.9831 7.42084 11.9077 7.50452 11.7906L12.5045 4.79055Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M0.999512 2.99927C0.999512 1.8947 1.89494 0.999268 2.99951 0.999268H13.0008C14.1054 0.999268 15.0008 1.8947 15.0008 2.99927V13.0006C15.0008 14.1052 14.1054 15.0006 13.0008 15.0006H2.99951C1.89494 15.0006 0.999512 14.1052 0.999512 13.0006V2.99927ZM2.99951 1.99927H13.0008C13.5531 1.99927 14.0008 2.44698 14.0008 2.99927V13.0006C14.0008 13.5529 13.5531 14.0006 13.0008 14.0006H2.99951C2.44723 14.0006 1.99951 13.5529 1.99951 13.0006V2.99927C1.99951 2.44698 2.44723 1.99927 2.99951 1.99927Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
