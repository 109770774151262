import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgBoxCart = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M7.5 8C7.22386 8 7 8.22386 7 8.5C7 8.77614 7.22386 9 7.5 9H10.5C10.7761 9 11 8.77614 11 8.5C11 8.22386 10.7761 8 10.5 8H7.5Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M0 0.5C0 0.223858 0.223858 0 0.5 0H3.5C3.77614 0 4 0.223858 4 0.5V3H14.5C14.7761 3 15 3.22386 15 3.5V14H15.5C15.7761 14 16 14.2239 16 14.5C16 14.7761 15.7761 15 15.5 15H4.50018C4.04408 15.6072 3.31791 16 2.5 16C1.11929 16 0 14.8807 0 13.5C0 12.1193 1.11929 11 2.5 11C2.67123 11 2.83844 11.0172 3 11.05V1H0.5C0.223858 1 0 0.776142 0 0.5ZM4.94999 14C4.98278 13.8384 5 13.6712 5 13.5C5 12.6821 4.60722 11.9559 4 11.4998V6H14V14H4.94999ZM4 4H14V5H4V4ZM2.5 15C3.32843 15 4 14.3284 4 13.5C4 12.6716 3.32843 12 2.5 12C1.67157 12 1 12.6716 1 13.5C1 14.3284 1.67157 15 2.5 15Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
