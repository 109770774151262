import styled from 'styled-components';
import { FontFamily } from '../../../tokens/quarks/fontFamily.ts';
import { FontSize } from '../../../tokens/quarks/fontSize.ts';
import { FontWeight } from '../../../tokens/quarks/fontWeight.ts';
import { LineHeight } from '../../../tokens/quarks/lineHeight.ts';
import { px } from '../../../tokens/utils/utils.ts';

export const StyledBadgeContent = styled.span`
  font-family: ${FontFamily.ProductPrimary};
  font-size: ${px(FontSize.XS)};
  font-weight: ${FontWeight.Regular};
  line-height: ${px(LineHeight.XS)};
`;
