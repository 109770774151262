import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgALowercase = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M8.50012 0C6.37236 0 5.02145 0.522106 4.18792 1.08583C3.77336 1.3662 3.4947 1.65163 3.31596 1.87571C3.22675 1.98754 3.16275 2.08369 3.11924 2.15583C3.09749 2.1919 3.08085 2.22197 3.06875 2.24502C3.06269 2.25654 3.05777 2.26631 3.0539 2.2742L3.04889 2.2846L3.04695 2.28872L3.04613 2.2905L3.0454 2.29209C2.93057 2.54323 3.04108 2.8399 3.29222 2.95473C3.54143 3.06867 3.8355 2.96071 3.95219 2.71363L3.95403 2.71006C3.95732 2.70381 3.96432 2.69091 3.97555 2.67229C3.99801 2.63506 4.03736 2.57496 4.09771 2.49929C4.21811 2.34837 4.4234 2.1338 4.74814 1.91417C5.39322 1.47789 6.54231 1 8.50012 1C9.75957 1 10.6135 1.37599 11.1564 1.95627C11.7048 2.54231 12.0001 3.40527 12.0001 4.5V6.7271C11.8334 6.66221 11.6404 6.59281 11.4232 6.5236C10.5995 6.26112 9.42287 6 8 6C6.51718 6 5.03401 6.56343 3.91741 7.45121C2.80395 8.3365 2 9.59266 2 11C2 13.742 4.54857 16 7.5 16C9.03671 16 10.3997 15.1607 11.3395 14.3858C11.585 14.1834 11.8065 13.9813 12.0001 13.7927V15.4999C12.0001 15.7761 12.224 16 12.5001 16C12.7763 16 13.0001 15.7758 13.0001 15.4997V4.5C13.0001 3.2338 12.6573 2.09676 11.8866 1.27304C11.1105 0.443547 9.96439 0 8.50012 0ZM12.0001 12.3241V7.81117C11.992 7.8075 11.9838 7.80377 11.9753 7.79998C11.7816 7.71309 11.492 7.59508 11.1196 7.4764C10.3743 7.23888 9.30091 7 8 7C6.75905 7 5.49222 7.47667 4.53976 8.23395C3.58417 8.99372 3 9.98756 3 11C3 13.0975 5.00389 15 7.5 15C8.68707 15 9.82405 14.3393 10.7033 13.6142C11.1353 13.258 11.4877 12.9006 11.732 12.6322C11.847 12.5058 11.9374 12.3999 12.0001 12.3241Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
