import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgOrganisationalScheme = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3.98228 1.49996C3.98228 1.22384 4.20657 1 4.48324 1H11.4967C11.7734 1 11.9977 1.22384 11.9977 1.49996V6.50291C11.9977 6.77903 11.7734 6.9878 11.4967 6.9878H7.99918V7.99553H12.4938C12.7705 7.99553 12.9948 8.21937 12.9948 8.49549V9.99595H14.499C14.7757 9.99595 15 10.2198 15 10.4959V14.5C15 14.7762 14.7757 15 14.499 15H9.48943C9.21276 15 8.98847 14.7762 8.98847 14.5V10.4959C8.98847 10.2198 9.21276 9.99595 9.48943 9.99595H11.9929V8.99545H4.00147V9.99595H6.51057C6.78724 9.99595 7.01153 10.2198 7.01153 10.4959V14.5C7.01153 14.7762 6.78724 15 6.51057 15H1.50096C1.22429 15 1 14.7762 1 14.5V10.4959C1 10.2198 1.22429 9.99595 1.50096 9.99595H2.99954V8.49549C2.99954 8.21937 3.22383 7.99553 3.5005 7.99553H6.99726V6.9878H4.48324C4.20657 6.9878 3.98228 6.77903 3.98228 6.50291V1.49996ZM7.52696 6.00295C7.51745 6.00241 7.50786 6.00214 7.49822 6.00214C7.48857 6.00214 7.47899 6.00241 7.46947 6.00295H4.98421V1.99992H10.9957V6.00295H7.52696ZM2.00192 10.9959H3.35156C3.38037 10.9959 3.41691 10.9959 3.45931 10.9959C3.50172 10.9959 3.54443 10.9959 3.59738 10.9959H6.00961V14.0001H2.00192V10.9959ZM12.4938 10.9959C12.4514 10.9959 12.4194 10.9959 12.3709 10.9959H10.0015V14.0001H13.9981V10.9959H12.6168C12.5562 10.9959 12.5363 10.9959 12.4938 10.9959Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
