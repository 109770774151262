import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgCalendar = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M4.50839 -0.000488281C4.78437 -0.000488281 5.00809 0.223369 5.00809 0.499512V1H11.0027V0.504639C11.0027 0.228496 11.2265 0.00463867 11.5024 0.00463867C11.7784 0.00463867 12.0021 0.228496 12.0021 0.504639V1H13.0012C14.1051 1 15 1.89543 15 3V13C15 14.1046 14.1051 15 13.0012 15H3.00952C1.90562 15 1.01074 14.1046 1.01074 13V5.63312C1.0037 5.5997 1 5.56505 1 5.52954C1 5.49403 1.0037 5.45938 1.01074 5.42596V3C1.01074 1.89543 1.90562 1 3.00952 1H4.0087V0.499512C4.0087 0.223369 4.23242 -0.000488281 4.50839 -0.000488281ZM2.01013 6.02954V13C2.01013 13.5523 2.45757 14 3.00952 14H13.0012C13.5532 14 14.0006 13.5523 14.0006 13V6.02954L2.01013 6.02954ZM14.0006 5.02954L2.01013 5.02954V3C2.01013 2.44772 2.45757 2 3.00952 2H11.4341C11.4564 2.00306 11.4792 2.00464 11.5024 2.00464C11.5256 2.00464 11.5484 2.00306 11.5708 2H13.0012C13.5532 2 14.0006 2.44771 14.0006 3V5.02954Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
