import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgRectangleParagraph = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M3.49999 1.0002L12.5 1C12.7761 0.999994 13 1.22385 13 1.5V14.5C13 14.7761 12.7761 15 12.5 15H3.5C3.22386 15 3 14.7761 3 14.5V1.5002C3 1.22407 3.22385 1.00021 3.49999 1.0002ZM12.5 3.9364e-10L3.49997 0.000203998C2.67155 0.000222774 2 0.67179 2 1.5002V14.5C2 15.3284 2.67157 16 3.5 16H12.5C13.3284 16 14 15.3284 14 14.5V1.5C14 0.67156 13.3284 -1.87743e-05 12.5 3.9364e-10ZM5.50049 3.02539C5.22435 3.02539 5.00049 3.24925 5.00049 3.52539C5.00049 3.80153 5.22435 4.02539 5.50049 4.02539H10.5005C10.7766 4.02539 11.0005 3.80153 11.0005 3.52539C11.0005 3.24925 10.7766 3.02539 10.5005 3.02539H5.50049ZM5 5.52344C5 5.24729 5.22386 5.02344 5.5 5.02344H10.5C10.7761 5.02344 11 5.24729 11 5.52344C11 5.79958 10.7761 6.02344 10.5 6.02344H5.5C5.22386 6.02344 5 5.79958 5 5.52344ZM5.5 7.01953C5.22386 7.01953 5 7.24339 5 7.51953C5 7.79567 5.22386 8.01953 5.5 8.01953H10.5C10.7761 8.01953 11 7.79567 11 7.51953C11 7.24339 10.7761 7.01953 10.5 7.01953H5.5ZM5 9.49609C5 9.21995 5.22386 8.99609 5.5 8.99609H10.5C10.7761 8.99609 11 9.21995 11 9.49609C11 9.77224 10.7761 9.99609 10.5 9.99609H5.5C5.22386 9.99609 5 9.77224 5 9.49609ZM5.46631 11.0137C5.19017 11.0137 4.96631 11.2375 4.96631 11.5137C4.96631 11.7898 5.19017 12.0137 5.46631 12.0137H8.46631C8.74245 12.0137 8.96631 11.7898 8.96631 11.5137C8.96631 11.2375 8.74245 11.0137 8.46631 11.0137H5.46631Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
