import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgArrowLeaveSquare = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <g fill="currentColor" clipPath="url(#b)">
            <path d="M0.00537109 1.5C0.00537108 1.22386 0.229229 1 0.505372 1L14.5078 1C14.784 1 15.0078 1.22386 15.0078 1.5L15.0078 3.49538C15.0078 3.77152 14.784 3.99538 14.5078 3.99538C14.2317 3.99538 14.0078 3.77152 14.0078 3.49538V2L1.00537 2V15H14.0078V13.5147C14.0078 13.2386 14.2317 13.0147 14.5078 13.0147C14.784 13.0147 15.0078 13.2386 15.0078 13.5147V15.5C15.0078 15.7761 14.784 16 14.5078 16L0.505372 16C0.229229 16 0.00537172 15.7761 0.00537171 15.5L0.00537109 1.5Z" />
            <path d="M6.00342 8.5C6.00342 8.22386 6.22728 8 6.50342 8L14.2963 8L12.6499 6.35355C12.4546 6.15829 12.4546 5.84171 12.6499 5.64645C12.8451 5.45118 13.1617 5.45118 13.357 5.64645L15.857 8.14645C16.0522 8.34171 16.0522 8.65829 15.857 8.85355L13.357 11.3536C13.1617 11.5488 12.8451 11.5488 12.6499 11.3536C12.4546 11.1583 12.4546 10.8417 12.6499 10.6464L14.2963 9L6.50342 9C6.22728 9 6.00342 8.77614 6.00342 8.5Z" />
          </g>
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" transform="rotate(-90 8 8)" />
          </clipPath>
          <clipPath id="b">
            <path fill="#fff" d="M0 0H16V15H0z" transform="translate(0 1)" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
