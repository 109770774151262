import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgOpera = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path
            fillRule="evenodd"
            d="M8.56334 0.00013093C8.56787 0.000123257 8.5724 0.00011942 8.57692 0.00011942C8.85307 0.00011942 9.07692 0.223977 9.07692 0.500119C9.07692 0.736529 8.91285 0.934617 8.69236 0.986727C7.13069 1.72661 5.98288 2.67624 5.22105 3.81899C4.44902 4.97704 4.05225 6.3649 4.05225 8.00012C4.05225 9.63534 4.44902 11.0232 5.22105 12.1813C5.98288 13.324 7.13069 14.2736 8.69236 15.0135C8.91285 15.0656 9.07692 15.2637 9.07692 15.5001C9.07692 15.7763 8.85307 16.0001 8.57692 16.0001C8.57239 16.0001 8.56785 16.0001 8.56331 16.0001C8.55464 16.0003 8.54595 16.0003 8.53724 16C4.20711 15.9786 0 12.452 0 8.00012C0 3.54823 4.2071 0.0216715 8.53721 0.000217829C8.54594 -4.05692e-05 8.55465 -6.84834e-05 8.56334 0.00013093ZM6.07682 14.5657C5.40613 14.025 4.84202 13.4155 4.389 12.736C3.49252 11.3912 3.05225 9.80399 3.05225 8.00012C3.05225 6.19625 3.49252 4.60901 4.389 3.26429C4.84202 2.58476 5.40613 1.97523 6.07683 1.43453C3.24303 2.42998 1 5.02105 1 8.00012C1 10.9792 3.24302 13.5703 6.07682 14.5657Z"
            clipRule="evenodd"
          />
          <path d="M10.3405 1.33923C10.0788 1.25114 9.93803 0.96756 10.0261 0.705846C10.1142 0.444132 10.3978 0.303385 10.6595 0.391479C13.7681 1.43783 16 4.45569 16 7.99984C16 11.544 13.7681 14.5619 10.6595 15.6082C10.3978 15.6963 10.1142 15.5556 10.0261 15.2938C9.93803 15.0321 10.0788 14.7486 10.3405 14.6605C13.0361 13.7531 15 11.121 15 7.99984C15 4.87867 13.0361 2.24659 10.3405 1.33923Z" />
          <path d="M9.35263 3.28582C9.08859 3.20497 8.94008 2.92538 9.02094 2.66134C9.10179 2.3973 9.38138 2.2488 9.64542 2.32965C12.1548 3.09807 13.999 5.33231 13.999 8.00004C13.999 10.6678 12.1548 12.902 9.64542 13.6704C9.38138 13.7513 9.10179 13.6028 9.02094 13.3387C8.94008 13.0747 9.08859 12.7951 9.35263 12.7143C11.4866 12.0608 12.999 10.1832 12.999 8.00004C12.999 5.81691 11.4866 3.93927 9.35263 3.28582Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
