import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgClipboardChecklist = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
          <path d="M5.51416 0.00195312C5.23802 0.00195312 5.01416 0.225811 5.01416 0.501953V1.00195H2.5C2.22386 1.00195 2 1.22581 2 1.50195V15.502C2 15.7781 2.22386 16.002 2.5 16.002H13.4961C13.7723 16.002 13.9961 15.7781 13.9961 15.502V1.50195C13.9961 1.22581 13.7723 1.00195 13.4961 1.00195H11.0169V0.501953C11.0169 0.225811 10.793 0.00195312 10.5169 0.00195312H5.51416ZM11.0169 2.00195V2.50195C11.0169 2.7781 10.793 3.00195 10.5169 3.00195H5.51416C5.23802 3.00195 5.01416 2.7781 5.01416 2.50195V2.00195H3V15.002H12.9961V2.00195H11.0169ZM10.0169 2.00195H6.01416V1.00195H10.0169V2.00195Z" />
          <path d="M10.7395 6.06115C10.9819 6.19338 11.0712 6.4971 10.939 6.73952L7.93899 12.2395C7.85654 12.3907 7.70211 12.4888 7.53025 12.4992C7.35838 12.5096 7.19325 12.4308 7.09317 12.2907L5.09317 9.49071C4.93267 9.26601 4.98471 8.95373 5.20942 8.79323C5.43413 8.63272 5.7464 8.68477 5.90691 8.90948L7.44315 11.0602L10.0611 6.26067C10.1933 6.01824 10.497 5.92892 10.7395 6.06115Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
