import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLListArticleCol = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.49958 1C1.22367 1 1 1.22386 1 1.5V3.5C1 3.77614 1.22367 4 1.49958 4H7.49456C7.77048 4 7.99415 3.77614 7.99415 3.5V1.5C7.99415 1.22386 7.77048 1 7.49456 1H1.49958ZM1.99916 3V2H6.99498V3H1.99916ZM10.5038 1C10.2279 1 10.0042 1.22386 10.0042 1.5V14.5C10.0042 14.7761 10.2279 15 10.5038 15H14.5004C14.7763 15 15 14.7761 15 14.5V1.5C15 1.22386 14.7763 1 14.5004 1H10.5038ZM11.0033 14V2H14.0008V14H11.0033ZM1.01951 9.49316C1.01951 9.21702 1.24319 8.99316 1.5191 8.99316H7.51408C7.78999 8.99316 8.01366 9.21702 8.01366 9.49316V11.4932C8.01366 11.7693 7.78999 11.9932 7.51408 11.9932H1.5191C1.24319 11.9932 1.01951 11.7693 1.01951 11.4932V9.49316ZM2.01868 9.99316V10.9932H7.0145V9.99316H2.01868Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M1.5191 4.99609C1.24319 4.99609 1.01951 5.21995 1.01951 5.49609 1.01951 5.77224 1.24319 5.99609 1.5191 5.99609H7.51408C7.78999 5.99609 8.01366 5.77224 8.01366 5.49609 8.01366 5.21995 7.78999 4.99609 7.51408 4.99609H1.5191ZM1.01951 13.4922C1.01951 13.216 1.24319 12.9922 1.5191 12.9922H7.51408C7.78999 12.9922 8.01366 13.216 8.01366 13.4922 8.01366 13.7683 7.78999 13.9922 7.51408 13.9922H1.5191C1.24319 13.9922 1.01951 13.7683 1.01951 13.4922ZM1.5191 6.99316C1.24319 6.99316 1.01951 7.21702 1.01951 7.49316 1.01951 7.76931 1.24319 7.99316 1.5191 7.99316H5.51575C5.79166 7.99316 6.01533 7.76931 6.01533 7.49316 6.01533 7.21702 5.79166 6.99316 5.51575 6.99316H1.5191Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
