import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgSmartphone = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M7 3.50781C7 3.23167 7.22386 3.00781 7.5 3.00781H8.5C8.77614 3.00781 9 3.23167 9 3.50781 9 3.78395 8.77614 4.00781 8.5 4.00781H7.5C7.22386 4.00781 7 3.78395 7 3.50781ZM6.5 11.9922C6.22386 11.9922 6 12.216 6 12.4922 6 12.7683 6.22386 12.9922 6.5 12.9922H9.5C9.77614 12.9922 10 12.7683 10 12.4922 10 12.216 9.77614 11.9922 9.5 11.9922H6.5Z" />
          <path
            fillRule="evenodd"
            d="M2.00146 2.04041C2.00146 0.891516 2.98583 0.00195312 4.13064 0.00195312H11.8692C13.014 0.00195312 13.9984 0.891516 13.9984 2.04041V13.9635C13.9984 15.1124 13.014 16.002 11.8692 16.002H4.13064C2.98583 16.002 2.00146 15.1124 2.00146 13.9635V2.04041ZM4.13064 1.00195C3.48336 1.00195 3.00146 1.49701 3.00146 2.04041V13.9635C3.00146 14.5069 3.48336 15.002 4.13064 15.002H11.8692C12.5165 15.002 12.9984 14.5069 12.9984 13.9635V2.04041C12.9984 1.49701 12.5165 1.00195 11.8692 1.00195H4.13064Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
