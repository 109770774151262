import { Spacing, gridUnit } from '../../tokens/quarks/spacing.ts';

export const badgeCoordinateSmallWithValue = 0;
export const badgeCoordinateSmall = 0.75 * gridUnit;

export const badgeCoordinateMediumWithValue = 0.75 * gridUnit;
export const badgeCoordinateMedium = 1.25 * gridUnit;

export const badgeCoordinateLargeWithValue = Spacing.S;
export const badgeCoordinateLarge = Spacing.M;
