import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgScissors = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M14.8181 1.82631C14.9957 1.61479 14.9681 1.29941 14.7566 1.12189C14.545 0.944378 14.2297 0.97195 14.0521 1.18348L7.99764 8.39799L1.94314 1.18348C1.76563 0.97195 1.45025 0.944378 1.23872 1.12189C1.0272 1.29941 0.999624 1.61479 1.17714 1.82631L7.3449 9.17579L6.94014 9.6581L6.39991 9.11616C5.15641 7.86873 3.13332 7.87784 1.90111 9.13643C0.697677 10.3656 0.697676 12.3314 1.90111 13.5605L3.16813 14.8547C3.26684 14.9555 3.40356 15.0099 3.54455 15.0045C3.68555 14.9991 3.8177 14.9344 3.90841 14.8263L7.99764 9.9536L12.0869 14.8263C12.1776 14.9344 12.3097 14.9991 12.4507 15.0045C12.5917 15.0099 12.7284 14.9555 12.8272 14.8547L14.0942 13.5605C15.2976 12.3314 15.2976 10.3656 14.0942 9.13643C12.862 7.87784 10.8389 7.86873 9.59538 9.11616L9.05514 9.6581L8.65038 9.17579L14.8181 1.82631ZM5.69168 9.82215L6.29476 10.4271L3.4969 13.7611L2.61566 12.861C1.79282 12.0205 1.79283 10.6765 2.61567 9.83601C3.45818 8.97546 4.84145 8.96923 5.69168 9.82215ZM10.3036 9.82215L9.70052 10.4271L12.4984 13.7611L13.3796 12.861C14.2025 12.0205 14.2025 10.6765 13.3796 9.83601C12.5371 8.97546 11.1538 8.96923 10.3036 9.82215Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
