import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgBookOpened = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M16.0001 0.500004C16.0001 0.352844 15.9353 0.213156 15.8229 0.118154C15.7105 0.0231515 15.5619 -0.0175175 15.4168 0.006981L8.50006 1.17475L1.5862 0.00698673C1.44109 -0.0175227 1.29255 0.0231404 1.18016 0.118143C1.06776 0.213145 1.00293 0.352838 1.00293 0.500004V14.3182C1.00293 14.5622 1.17906 14.7706 1.41966 14.8112L8.40589 15.9912C8.46287 16.0021 8.52274 16.0033 8.58332 15.993L15.5833 14.8112C15.8239 14.7706 16.0001 14.5622 16.0001 14.3182V0.500004ZM9 14.9085L15.0001 13.8955V1.0915L9 2.1045V14.9085ZM2.00293 13.8956V1.09154L8 2.10445V14.9085L2.00293 13.8956Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
