import { UnreachableCaseException } from '@kontent-ai/errors';
import { css } from 'styled-components';
import { borderWidthHighEmphasis } from '../../../tokens/decision/border.ts';
import {
  colorAlertBackgroundInverse,
  colorAlertHover,
  colorAlertIcon,
  colorBackgroundDisabled,
  colorBackgroundHoverInverse,
  colorBorderAlert,
  colorBorderDisabled,
  colorIconDefaultInverse,
  colorIconDisabled,
  colorPrimary,
  colorPrimaryHover,
  colorPrimaryHoverInverse,
  colorTextDefault,
} from '../../../tokens/decision/colors.ts';
import { BaseColor } from '../../../tokens/quarks/colors.ts';
import { IconSize } from '../../../tokens/quarks/iconSize.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { ButtonSize } from '../buttonSize.ts';
import { ButtonStyle } from '../buttonStyle.ts';
import { colorTextOnDark } from '../decisionTokens.ts';
import {
  buttonBackgroundColorVariableName,
  buttonBackgroundHoverColorVariableName,
  buttonBorderColorVariableName,
  buttonBorderHoverColorVariableName,
  buttonIconColorVariableName,
  buttonIconHoverColorVariableName,
  buttonIconSizeVariableName,
} from './cssVariableNames.ts';

// buttons with border and transparent bg should have focus ring around button border
// and focus inner shadow should be visible under border - so it needs to be handled by wrapper around button
export const shouldWrapperHandleFocus = (buttonStyle: ButtonStyle): boolean => {
  switch (buttonStyle) {
    case 'primary':
    case 'primary-inverse':
      return false;
    case 'secondary':
    case 'secondary-inverse':
    case 'tertiary':
      return true;
    default:
      throw UnreachableCaseException(buttonStyle);
  }
};

type ButtonStyleProps = {
  readonly $activated?: boolean;
  readonly disabled?: boolean;
};

const getIconHoverColor = (disabled: boolean, destructive: boolean) => {
  if (disabled) {
    return colorIconDisabled;
  }

  if (destructive) {
    return colorIconDefaultInverse;
  }

  return `var(${buttonIconColorVariableName})`;
};

const getButtonIconSize = (size: ButtonSize) => {
  switch (size) {
    case 'small':
      return px(IconSize.S);
    case 'medium':
      return px(IconSize.M);
    case 'large':
      return px(IconSize.L);
    default:
      throw UnreachableCaseException(size);
  }
};

export const getTypographyStyles = (buttonSize: ButtonSize) => {
  switch (buttonSize) {
    case 'small':
      return Typography.ActionSmall;
    case 'medium':
      return Typography.ActionMedium;
    case 'large':
      return Typography.ActionLarge;
    default:
      throw UnreachableCaseException(buttonSize);
  }
};

export const getSpecificButtonStyles = ({ disabled, $activated }: ButtonStyleProps) => {
  if (disabled) {
    return css`
      &:disabled {
        background: var(${buttonBackgroundColorVariableName});
        border-color: var(${buttonBorderColorVariableName});
      }
    `;
  }
  return css`
    background: ${
      $activated
        ? `var(${buttonBackgroundHoverColorVariableName})`
        : `var(${buttonBackgroundColorVariableName})`
    };
    border-color: ${
      $activated
        ? `var(${buttonBorderHoverColorVariableName})`
        : `var(${buttonBorderColorVariableName})`
    };
  
    &:active,
    &:hover {
      background: var(${buttonBackgroundHoverColorVariableName});
      border-color: var(${buttonBorderHoverColorVariableName});
    }
  `;
};

export const getBorderWidth = (buttonStyle: ButtonStyle) => {
  switch (buttonStyle) {
    case 'secondary':
    case 'secondary-inverse':
    case 'tertiary':
      return borderWidthHighEmphasis;
    default:
      return 0;
  }
};

const getBgColors = (buttonStyle: ButtonStyle, disabled: boolean, destructive: boolean) => {
  if (disabled) {
    if (buttonStyle === 'secondary-inverse') {
      return BaseColor.Transparent;
    }
    return colorBackgroundDisabled;
  }

  if (destructive) {
    switch (buttonStyle) {
      case 'primary':
      case 'primary-inverse':
        return colorAlertBackgroundInverse;
      case 'secondary':
      case 'secondary-inverse':
      case 'tertiary':
        return BaseColor.White;
      default:
        throw UnreachableCaseException(buttonStyle);
    }
  }

  switch (buttonStyle) {
    case 'primary':
      return colorPrimary;
    case 'primary-inverse':
    case 'secondary':
    case 'tertiary':
      return BaseColor.White;
    case 'secondary-inverse':
      return BaseColor.Transparent;
    default:
      throw UnreachableCaseException(buttonStyle);
  }
};

const getBgHoverColors = (buttonStyle: ButtonStyle, disabled: boolean, destructive: boolean) => {
  if (disabled) {
    return colorBackgroundDisabled;
  }

  if (destructive) {
    return colorAlertHover;
  }

  switch (buttonStyle) {
    case 'primary':
      return colorPrimaryHover;
    case 'primary-inverse':
    case 'secondary':
    case 'tertiary':
      return colorPrimaryHoverInverse;
    case 'secondary-inverse':
      return colorBackgroundHoverInverse;
    default:
      throw UnreachableCaseException(buttonStyle);
  }
};

const getBorderColor = (buttonStyle: ButtonStyle, disabled: boolean, destructive: boolean) => {
  if (disabled) {
    if (buttonStyle === 'secondary-inverse') {
      return colorBorderDisabled;
    }
    return BaseColor.Transparent;
  }
  if (destructive) {
    switch (buttonStyle) {
      case 'primary':
      case 'primary-inverse':
      case 'secondary-inverse':
        return BaseColor.Transparent;
      case 'secondary':
      case 'tertiary':
        return colorBorderAlert;
      default:
        throw UnreachableCaseException(buttonStyle);
    }
  }
  switch (buttonStyle) {
    case 'primary':
    case 'primary-inverse':
      return BaseColor.Transparent;
    case 'secondary':
    case 'tertiary':
      return colorPrimary;
    case 'secondary-inverse':
      return BaseColor.White;
    default:
      throw UnreachableCaseException(buttonStyle);
  }
};

const getBorderHoverColor = (buttonStyle: ButtonStyle, disabled: boolean, destructive: boolean) => {
  if (disabled || destructive) {
    return BaseColor.Transparent;
  }
  switch (buttonStyle) {
    case 'primary':
    case 'primary-inverse':
      return BaseColor.Transparent;
    case 'secondary':
    case 'tertiary':
      return colorPrimary;
    case 'secondary-inverse':
      return BaseColor.White;
    default:
      throw UnreachableCaseException(buttonStyle);
  }
};

export const getIconColor = (
  buttonStyle: ButtonStyle,
  disabled?: boolean,
  destructive?: boolean,
  activated?: boolean,
) => {
  if (disabled) {
    return colorIconDisabled;
  }

  if (destructive) {
    switch (buttonStyle) {
      case 'primary':
      case 'primary-inverse':
        return colorTextOnDark;
      case 'secondary':
      case 'secondary-inverse':
      case 'tertiary':
        return activated ? colorTextOnDark : colorAlertIcon;
      default:
        throw UnreachableCaseException(buttonStyle);
    }
  }

  switch (buttonStyle) {
    case 'primary':
    case 'secondary-inverse':
      return colorTextOnDark;
    case 'primary-inverse':
      return colorPrimary;
    case 'secondary':
    case 'tertiary':
      return colorTextDefault;
    default:
      throw UnreachableCaseException(buttonStyle);
  }
};

type PickedProps = {
  readonly $buttonStyle: ButtonStyle;
  readonly $size: ButtonSize;
  readonly $activated?: boolean;
  readonly $destructive?: boolean;
  readonly $disabled?: boolean;
};

export const getTokens = ({
  $activated = false,
  $buttonStyle,
  $size,
  $destructive = false,
  $disabled = false,
}: PickedProps) => ({
  style: {
    [buttonIconColorVariableName]: getIconColor($buttonStyle, $disabled, $destructive, $activated),
    [buttonIconHoverColorVariableName]: getIconHoverColor($disabled, $destructive),
    [buttonIconSizeVariableName]: getButtonIconSize($size),
    [buttonBackgroundColorVariableName]: getBgColors($buttonStyle, $disabled, $destructive),
    [buttonBackgroundHoverColorVariableName]: getBgHoverColors(
      $buttonStyle,
      $disabled,
      $destructive,
    ),
    [buttonBorderColorVariableName]: getBorderColor($buttonStyle, $disabled, $destructive),
    [buttonBorderHoverColorVariableName]: getBorderHoverColor(
      $buttonStyle,
      $disabled,
      $destructive,
    ),
  },
});
