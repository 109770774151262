import React from 'react';
import styled from 'styled-components';
import { Icon } from '../Icons/types.ts';
import { StyledButton } from './components/StyledButton.tsx';
import {
  buttonIconColorVariableName,
  buttonIconHoverColorVariableName,
  buttonIconSizeVariableName,
} from './utils/cssVariableNames.ts';

interface IButtonIconProps {
  readonly className?: string;
  readonly icon: Icon;
}

const StyledIcon = styled.div`
  display: block;
  color: var(${buttonIconColorVariableName});

  ${StyledButton}:hover &&,
  ${StyledButton}:active && {
    color: var(${buttonIconHoverColorVariableName});
  }

  font-size: var(${buttonIconSizeVariableName});
`;

export const ButtonIcon: React.FC<IButtonIconProps> = ({ icon, className }) => (
  <StyledIcon as={icon} className={className} />
);

ButtonIcon.displayName = 'ButtonIcon';
