import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgBrush = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.98684 1C7.19896 1 6.6376 1.34204 6.2972 1.83078C5.97541 2.2928 5.86575 2.85935 5.86575 3.34542V5.70215L2.85914 7.52536C2.70705 7.61759 2.61539 7.77473 2.61539 7.94323V9.17324C2.56032 9.22979 2.5187 9.29854 2.49587 9.3757L1.01918 14.3687C0.974758 14.5188 1.00846 14.6797 1.11032 14.8035C1.21217 14.9273 1.37051 15 1.53847 15H8.58741C8.75863 15 8.91964 14.9245 9.02112 14.7966L9.76224 13.8627L10.5034 14.7966C10.6048 14.9245 10.7658 15 10.9371 15H14.4615C14.6294 15 14.7877 14.9274 14.8895 14.8037C14.9914 14.6799 15.0252 14.5192 14.981 14.3691L13.5095 9.37614C13.486 9.29637 13.4424 9.22558 13.3846 9.16794V7.94323C13.3846 7.77541 13.2937 7.61881 13.1426 7.52642L10.1615 5.7034V3.29966C10.1615 2.80226 10.0333 2.24228 9.69132 1.79148C9.33255 1.3186 8.76097 1 7.98684 1ZM12.3077 9.00846V8.21205L9.32657 6.38903C9.17548 6.29663 9.08455 6.14004 9.08455 5.97221V3.29966C9.08455 2.94185 8.98926 2.60164 8.81053 2.36606C8.64855 2.15256 8.40203 1.99859 7.98684 1.99859C7.58541 1.99859 7.35546 2.152 7.20187 2.37253C7.02967 2.61976 6.94268 2.97628 6.94268 3.34542V5.97221C6.94268 6.14072 6.85101 6.29786 6.69893 6.39008L3.69231 8.21329V9.00846H12.3077ZM2.24448 14.0014L3.42584 10.0071H12.579L13.7562 14.0014H11.2094L10.1959 12.7243C10.0945 12.5964 9.93345 12.5209 9.76224 12.5209C9.59102 12.5209 9.43001 12.5964 9.32853 12.7243L8.3151 14.0014H2.24448Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
