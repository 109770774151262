import React from 'react';
import {
  PolymorphicComponent,
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../@types/PolymorphicComponent.type.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { YAlignment } from '../../types/alignments.ts';
import { Box } from '../Box/Box.tsx';

export type InlineProps = Readonly<{
  align?: YAlignment;
  noWrap?: boolean;
  spacing?: Spacing;
  spacingX?: Spacing;
  spacingY?: Spacing;
}>;

export const Inline = React.forwardRef(
  <TElement extends React.ElementType = 'div'>(
    {
      align = 'normal',
      children,
      className,
      component,
      noWrap,
      spacing = 0,
      spacingX = spacing,
      spacingY = spacing,
      ...otherProps
    }: React.PropsWithChildren<InlineProps> & PolymorphicComponentProps<TElement>,
    forwardedRef: PolymorphicRef<TElement>,
  ) => (
    <Box
      component={component ?? 'div'}
      display="flex"
      flexWrap={noWrap ? 'nowrap' : 'wrap'}
      alignItems={align}
      rowGap={spacingY}
      columnGap={spacingX}
      className={className}
      ref={forwardedRef}
      {...otherProps}
    >
      {children}
    </Box>
  ),
) as PolymorphicComponent<InlineProps>;

Inline.displayName = 'Inline';
