import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgRotateDoubleRightA = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M7.99999 3C10.4189 3 12.4367 4.71776 12.9 7H11.9716C11.5649 7 11.3283 7.45968 11.5647 7.79062L13.0931 9.93039C13.2925 10.2096 13.7074 10.2096 13.9069 9.93039L15.4353 7.79062C15.6716 7.45968 15.4351 7 15.0284 7H13.917C13.441 4.16229 10.973 2 7.99999 2 6.40582 2 4.95593 2.62238 3.88181 3.63643 3.68101 3.82599 3.67191 4.14244 3.86148 4.34324 4.05104 4.54404 4.3675 4.55314 4.56829 4.36357 5.4642 3.51777 6.67108 3 7.99999 3ZM2.08295 9H.971585C.564898 9 .328336 8.54031.564718 8.20938L2.09312 6.06961C2.29253 5.79044 2.70745 5.79044 2.90686 6.06961L4.43526 8.20938C4.67164 8.54032 4.43508 9 4.0284 9H3.10001C3.56328 11.2822 5.58103 13 7.99999 13 9.32889 13 10.5358 12.4822 11.4317 11.6364 11.6325 11.4469 11.9489 11.456 12.1385 11.6568 12.3281 11.8576 12.319 12.174 12.1182 12.3636 11.0441 13.3776 9.59416 14 7.99999 14 5.02699 14 2.55903 11.8377 2.08295 9Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.99999 5C8.20444 5 8.3883 5.12448 8.46423 5.3143L10.4642 10.3143C10.5668 10.5707 10.4421 10.8617 10.1857 10.9642C9.92929 11.0668 9.63831 10.9421 9.53575 10.6857L8.85809 8.99154H7.14189L6.46423 10.6857C6.36167 10.9421 6.07069 11.0668 5.81429 10.9642C5.5579 10.8617 5.4332 10.5707 5.53575 10.3143L7.53575 5.3143C7.61168 5.12448 7.79554 5 7.99999 5ZM7.54189 7.99154H8.45809L7.99999 6.84629L7.54189 7.99154Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
