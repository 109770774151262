import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgChevronRightSquare = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M7.18705 11.8528C6.99179 12.0481 6.6752 12.0481 6.47994 11.8528C6.28468 11.6576 6.28468 11.341 6.47994 11.1457L9.62521 8.00045L6.47994 4.85519C6.28468 4.65992 6.28468 4.34334 6.47994 4.14808C6.67521 3.95282 6.99179 3.95282 7.18705 4.14808L10.6859 7.6469C10.8811 7.84216 10.8811 8.15874 10.6859 8.354L7.18705 11.8528Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M12.9976 1.00269C14.1021 1.00269 14.9976 1.89812 14.9976 3.00269L14.9976 12.998C14.9976 14.1025 14.1021 14.998 12.9976 14.998L3.00229 14.998C1.89772 14.998 1.00229 14.1025 1.00229 12.998L1.00229 3.00268C1.00229 1.89812 1.89772 1.00268 3.00229 1.00269L12.9976 1.00269ZM13.9976 3.00269L13.9976 12.998C13.9976 13.5502 13.5498 13.998 12.9976 13.998L3.00229 13.998C2.45 13.998 2.00229 13.5502 2.00229 12.998L2.00229 3.00268C2.00229 2.4504 2.45 2.00268 3.00229 2.00269L12.9976 2.00269C13.5498 2.00269 13.9976 2.4504 13.9976 3.00269Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
