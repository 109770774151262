import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgPermissionList = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M5.02959 3.78114C5.20179 3.56557 5.16688 3.25103 4.95162 3.07858C4.73636 2.90614 4.42226 2.94109 4.25006 3.15666L3.75144 3.78085L3.72224 3.75161C3.52731 3.55641 3.21128 3.55642 3.01636 3.75163C2.82144 3.94683 2.82145 4.26331 3.01638 4.45851L3.43991 4.88261C3.5403 4.98314 3.6787 5.0361 3.82045 5.02824C3.96221 5.02037 4.09391 4.95243 4.1826 4.8414L5.02959 3.78114Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M2.3408 1C1.97855 1 1.63755 1.12244 1.38562 1.37348C1.13346 1.62474 1.00978 1.96575 1.00978 2.32877V5.66929C1.00978 6.03231 1.13346 6.37332 1.38562 6.62458C1.63755 6.87562 1.97855 6.99806 2.3408 6.99806H5.66834C6.03059 6.99806 6.37158 6.87562 6.62352 6.62458C6.87567 6.37332 6.99936 6.03231 6.99936 5.66929V2.32877C6.99936 1.96575 6.87567 1.62474 6.62352 1.37348C6.37158 1.12244 6.03059 1 5.66834 1H2.3408ZM2.00804 2.32877C2.00804 2.19443 2.05074 2.12098 2.08974 2.08211C2.12896 2.04303 2.20391 1.99968 2.3408 1.99968H5.66834C5.80522 1.99968 5.88017 2.04303 5.91939 2.08211C5.9584 2.12098 6.00109 2.19443 6.00109 2.32877V5.66929C6.00109 5.80363 5.9584 5.87708 5.91939 5.91595C5.88017 5.95503 5.80522 5.99838 5.66834 5.99838H2.3408C2.20391 5.99838 2.12896 5.95503 2.08974 5.91595C2.05074 5.87708 2.00804 5.80363 2.00804 5.66929V2.32877Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M9.01042 3.51087C9.01042 3.23482 9.23389 3.01103 9.50955 3.01103H14.5009C14.7765 3.01103 15 3.23482 15 3.51087 15 3.78692 14.7765 4.01071 14.5009 4.01071H9.50955C9.23389 4.01071 9.01042 3.78692 9.01042 3.51087ZM9.50955 11.0134C9.23389 11.0134 9.01042 11.2372 9.01042 11.5132 9.01042 11.7893 9.23389 12.0131 9.50955 12.0131H14.5009C14.7765 12.0131 15 11.7893 15 11.5132 15 11.2372 14.7765 11.0134 14.5009 11.0134H9.50955ZM9.50955 13.0049C9.23389 13.0049 9.01042 13.2287 9.01042 13.5047 9.01042 13.7808 9.23389 14.0046 9.50955 14.0046H14.5009C14.7765 14.0046 15 13.7808 15 13.5047 15 13.2287 14.7765 13.0049 14.5009 13.0049H9.50955Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.37584 9.37542C1.62778 9.12438 1.96877 9.00194 2.33102 9.00194H5.65856C6.02081 9.00194 6.3618 9.12438 6.61374 9.37542C6.86589 9.62668 6.98958 9.96769 6.98958 10.3307V13.6712C6.98958 14.0342 6.86589 14.3753 6.61374 14.6265C6.3618 14.8776 6.02081 15 5.65856 15H2.33102C1.96877 15 1.62778 14.8776 1.37584 14.6265C1.12368 14.3753 1 14.0342 1 13.6712V10.3307C1 9.96769 1.12368 9.62668 1.37584 9.37542ZM2.07996 10.0841C2.04096 10.1229 1.99826 10.1964 1.99826 10.3307V13.6712C1.99826 13.8056 2.04096 13.879 2.07996 13.9179C2.11918 13.957 2.19413 14.0003 2.33102 14.0003H5.65856C5.79544 14.0003 5.87039 13.957 5.90962 13.9179C5.94862 13.879 5.99131 13.8056 5.99131 13.6712V10.3307C5.99131 10.1964 5.94862 10.1229 5.90962 10.0841C5.87039 10.045 5.79544 10.0016 5.65856 10.0016H2.33102C2.19413 10.0016 2.11918 10.045 2.07996 10.0841Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
