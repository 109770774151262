import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgPants = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1 1.5C1 1.22386 1.22386 1 1.5 1H14.5C14.7761 1 15 1.22386 15 1.5V4.49211C15 4.49311 15 4.49411 15 4.49511C15 4.49608 15 4.49706 15 4.49803V13.5C15 14.3284 14.3284 15 13.5 15H9.53326C9.25711 15 9.03326 14.7761 9.03326 14.5C9.03326 14.2239 9.25711 14 9.53326 14H13.5C13.7761 14 14 13.7761 14 13.5V4.99504L2 4.99418V13.5C2 13.7761 2.22386 14 2.5 14H7.02468V8.99023H4.48438C4.20823 8.99023 3.98438 8.76638 3.98438 8.49023C3.98438 8.21409 4.20823 7.99023 4.48438 7.99023H10.4844C10.7605 7.99023 10.9844 8.21409 10.9844 8.49023C10.9844 8.76638 10.7605 8.99023 10.4844 8.99023H8.02468V14.5C8.02468 14.7761 7.80082 15 7.52468 15H2.5C1.67157 15 1 14.3284 1 13.5V4.49418C1 4.4942 1 4.49415 1 4.49418V1.5ZM2 3.99418L14 3.99504V2H2V3.99418Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
