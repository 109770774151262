import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgTShirt = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M6.31382 1.01382C6.53994 0.95962 6.77359 1.06846 6.87758 1.27644C7.08232 1.68593 7.38685 2.18187 8.00006 2.18187C8.60222 2.18187 8.86018 1.70844 9.07618 1.27644C9.17999 1.06881 9.41306 0.959944 9.63891 1.01358L14.6156 2.1954C14.8676 2.25524 15.0322 2.49766 14.9948 2.75398L14.4387 6.5699C14.4029 6.81559 14.1922 6.99779 13.9439 6.99779H12.9968V14.5C12.9968 14.7762 12.7729 15 12.4968 15H3.51485C3.23871 15 3.01485 14.7762 3.01485 14.5V6.99779H2.09096C1.84437 6.99779 1.63459 6.818 1.59685 6.5743L1.00594 2.75838C0.966106 2.50112 1.13034 2.25633 1.3835 2.19564L6.31382 1.01382ZM9.78422 2.07589C9.51124 2.5383 8.98476 3.18187 8.00006 3.18187C7.01679 3.18187 6.46449 2.54239 6.17593 2.0752L2.06468 3.06069L2.5195 5.99779H3.51485C3.791 5.99779 4.01485 6.22165 4.01485 6.49779V14H11.9968V6.49779C11.9968 6.22165 12.2206 5.99779 12.4968 5.99779H13.5115L13.9393 3.06261L9.78422 2.07589Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
