import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgTrophy = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M5.0096 1.49865C5.01018 1.22267 5.23358 0.999413 5.50856 1L10.5003 1.01062C10.5056 1.01045 10.5109 1.01037 10.5163 1.01037C10.7912 1.01037 11.0142 1.2341 11.0142 1.51009V3.0127L12.5021 3.0127C12.7771 3.0127 13 3.23643 13 3.51242C13 4.124 12.8965 5.06369 12.5551 5.79929C12.3831 6.16985 12.132 6.53476 11.763 6.77274C11.5314 6.92211 11.2978 7.01 11.0142 7.0286V7.50672C11.0142 7.94612 10.8549 8.57009 10.3884 9.09031C9.9823 9.54323 9.34687 9.88383 8.49492 9.97872V12.6528L11.0064 14.0643C11.2041 14.1754 11.3021 14.4064 11.2449 14.6264C11.1877 14.8465 10.9897 15 10.7631 15H5.23089C5.00432 15 4.80632 14.8465 4.74912 14.6264C4.69192 14.4064 4.78994 14.1754 4.98763 14.0643L7.49911 12.6528V9.97808C6.65015 9.88231 6.04341 9.54222 5.63817 9.09032C5.17168 8.5701 5.01246 7.94612 5.01246 7.50672V7.02761C4.72887 7.00897 4.4685 6.92109 4.23698 6.77176C3.868 6.53378 3.61688 6.16887 3.44489 5.79831C3.10347 5.06272 3 4.12302 3 3.51144C3 3.23545 3.22292 3.01172 3.4979 3.01172H5.01246V1.5536C5.01053 1.53555 5.00956 1.51722 5.0096 1.49865ZM6.00826 2.00051V7.50672C6.00826 7.73361 6.09799 8.10908 6.37835 8.42172C6.64436 8.71837 7.12455 9.00588 7.99987 9.00588C8.87519 9.00588 9.38226 8.71837 9.64827 8.42172C9.92863 8.10908 10.0184 7.73361 10.0184 7.50672V2.00903L6.00826 2.00051ZM5.01246 4.01116H4.0204C4.06302 4.45981 4.16416 4.98114 4.34756 5.37628C4.47431 5.64937 4.62151 5.83176 4.77533 5.93097C4.84194 5.97393 4.91903 6.00756 5.01246 6.02293V4.01116ZM7.99701 13.5184L7.13915 14.0006H8.85488L7.99701 13.5184ZM11.0142 6.02395C11.1077 6.0086 11.158 5.97495 11.2247 5.93195C11.3785 5.83274 11.5257 5.65035 11.6524 5.37726C11.8358 4.98212 11.937 4.46079 11.9796 4.01213L11.0142 4.01213V6.02395Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
