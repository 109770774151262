import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgArrowDoubleLeft = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M4.30965 12.1746C4.48942 12.3843 4.46513 12.6999 4.2554 12.8796C4.04566 13.0593 3.72991 13.0351 3.55013 12.8254L0.120412 8.82541C-0.0401374 8.63816 -0.0401374 8.36186 0.120412 8.17461L3.55013 4.17461C3.72991 3.96495 4.04566 3.94067 4.2554 4.12038C4.46513 4.30009 4.48942 4.61574 4.30965 4.8254L1.15893 8.50001L4.30965 12.1746Z"
        />
        <path
          fill="currentColor"
          d="M7.68526 12.8796C7.89499 12.6999 7.91928 12.3843 7.73951 12.1746L5.02734 9.01148L15.4998 9.01148C15.7761 9.01148 16 8.78763 16 8.51148C16 8.23534 15.7761 8.01148 15.4998 8.01148L5.00767 8.01148L7.73951 4.8254C7.91928 4.61574 7.89499 4.30009 7.68526 4.12038C7.47552 3.94067 7.15977 3.96495 6.98 4.17461L3.55027 8.17461C3.38972 8.36186 3.38972 8.63816 3.55027 8.82541L6.98 12.8254C7.15977 13.0351 7.47552 13.0593 7.68526 12.8796Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
