import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLImg2Cols3 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.5 1C1.22386 1 1 1.22385 1 1.49998V4.49633C1 4.77247 1.22386 4.99631 1.5 4.99631H9.5C9.77614 4.99631 10 4.77247 10 4.49633V1.49998C10 1.22385 9.77614 1 9.5 1H1.5ZM2 3.99636V1.99996H9V3.99636H2ZM1.5 6.01333C1.22386 6.01333 1 6.23718 1 6.51331V14.5C1 14.7762 1.22386 15 1.5 15H4.5C4.77614 15 5 14.7762 5 14.5V6.51331C5 6.23718 4.77614 6.01333 4.5 6.01333H1.5ZM2 14V7.01329H4V14H2ZM6 6.51331C6 6.23718 6.22386 6.01333 6.5 6.01333H9.5C9.77614 6.01333 10 6.23718 10 6.51331V14.5C10 14.7762 9.77614 15 9.5 15H6.5C6.22386 15 6 14.7762 6 14.5V6.51331ZM7 7.01329V14H9V7.01329H7ZM11.5 1C11.2239 1 11 1.22385 11 1.49998V14.4842C11 14.7603 11.2239 14.9842 11.5 14.9842H14.5C14.7761 14.9842 15 14.7603 15 14.4842V1.49998C15 1.22385 14.7761 1 14.5 1H11.5ZM12 13.9842V1.99996H14V13.9842H12Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
