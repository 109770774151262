import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgChevronDown = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M14.9194 5.7236C15.0727 5.95436 15.0107 6.26621 14.7809 6.42013L8.28092 11.4156C8.11292 11.5281 7.89401 11.5281 7.72601 11.4156L1.22602 6.42013C0.99629 6.26621 0.934281 5.95436 1.08752 5.7236C1.24075 5.49284 1.5512 5.43055 1.78093 5.58448L8.00346 10.394L14.226 5.58448C14.4557 5.43055 14.7662 5.49284 14.9194 5.7236Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
