import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgHighlighter = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M11.5038 2.14652C11.3255 1.96813 11.0423 1.95049 10.8433 2.10538L5.55289 6.41335C5.46931 6.47839 5.40931 6.56762 5.38005 6.66751L4.2479 10.0656L3.15475 11.1593C3.01175 11.3024 2.96897 11.5175 3.04636 11.7045C3.12375 11.8914 3.30607 12.0133 3.5083 12.0133H5.85218C5.90772 12.0142 5.96387 12.0058 6.01832 11.9876L9.54523 10.8114C9.63786 10.7805 9.72032 10.7226 9.78145 10.644L13.8962 5.15978C14.051 4.96064 14.0334 4.67733 13.855 4.49894L11.5038 2.14652ZM5.25663 10.2018L6.07863 7.73455L8.46126 10.1183L5.99525 10.9407L5.25663 10.2018ZM9.33982 9.58242L6.61392 6.85519L11.1088 3.16624L12.8358 4.89413L9.33982 9.58242Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M0.5 12.9995C0.223858 12.9995 0 13.2235 0 13.4998C0 13.776 0.223858 14 0.5 14L15.5 14C15.7761 14 16 13.776 16 13.4998C16 13.2235 15.7761 12.9995 15.5 12.9995L0.5 12.9995Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
