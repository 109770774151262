import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgArrowLeftCircle = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M7.52524 5.67847C7.71421 5.87983 7.70416 6.19625 7.5028 6.38522L5.76836 8.01294L12.52 8.01294C12.7962 8.01294 13.02 8.2368 13.02 8.51294C13.02 8.78908 12.7962 9.01294 12.52 9.01294H5.75197L7.5028 10.656C7.70416 10.845 7.71421 11.1614 7.52524 11.3628C7.33627 11.5641 7.01984 11.5742 6.81849 11.3852L4.15457 8.88522C4.05386 8.79071 3.99673 8.65874 3.99673 8.52063C3.99673 8.38251 4.05386 8.25055 4.15457 8.15604L6.81849 5.65604C7.01985 5.46707 7.33627 5.47711 7.52524 5.67847Z" />
          <path
            fillRule="evenodd"
            d="M15.998 8.5C15.998 4.35786 12.6402 1 8.49805 1C4.35591 1 0.998047 4.35786 0.998047 8.5C0.998047 12.6421 4.35591 16 8.49805 16C12.6402 16 15.998 12.6421 15.998 8.5ZM8.49805 2C12.0879 2 14.998 4.91015 14.998 8.5C14.998 12.0899 12.0879 15 8.49805 15C4.9082 15 1.99805 12.0899 1.99805 8.5C1.99805 4.91015 4.9082 2 8.49805 2Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" transform="rotate(-90 8 8)" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
