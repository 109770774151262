import React from 'react';

export const GlobalFonts: React.FC = () => (
  <style>
    {`
    @font-face {
     font-family: "Inter";
     font-style:  normal;
     font-weight: 400;
     font-display: swap;
     src: url("/fonts/Inter-Regular.woff2") format("woff2");
    }

    @font-face {
     font-family: "Inter";
     font-style:  normal;
     font-weight: 500;
     font-display: swap;
     src: url("/fonts/Inter-Medium.woff2") format("woff2");
    }

    @font-face {
     font-family: "Inter";
     font-style:  normal;
     font-weight: 700;
     font-display: swap;
     src: url("/fonts/Inter-Bold.woff2") format("woff2");
    }
    
    @font-face {
      font-family: "kentico-icons";
      font-style: normal;
      font-weight: normal;
      src: url("/fonts/kentico-icons-v3.3.0.woff2") format("woff2");
    }
  `}
  </style>
);

GlobalFonts.displayName = 'GlobalFonts';
