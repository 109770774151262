import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgRss = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M2.51741 1C2.24166 1 2.01811 1.22433 2.01811 1.50105C2.01811 1.77777 2.24166 2.0021 2.51741 2.0021C8.85984 2.0021 14.0014 7.16162 14.0014 13.5262C14.0014 13.8029 14.2249 14.0273 14.5007 14.0273C14.7765 14.0273 15 13.8029 15 13.5262C15 6.60818 9.41136 1 2.51741 1Z"
        />
        <path
          fill="currentColor"
          d="M2.01909 4.50245C2.01909 4.22572 2.24263 4.0014 2.51839 4.0014C7.62111 4.0014 12.0052 8.39659 12.0052 13.5213C12.0052 13.798 11.7816 14.0224 11.5059 14.0224C11.2301 14.0224 11.0065 13.798 11.0065 13.5213C11.0065 8.95031 7.06987 5.0035 2.51839 5.0035C2.24263 5.0035 2.01909 4.77917 2.01909 4.50245Z"
        />
        <path
          fill="currentColor"
          d="M2.53155 6.9979C2.2558 6.9979 2.03225 7.22223 2.03225 7.49895C2.03225 7.77567 2.2558 8 2.53155 8C3.89668 8 5.27344 8.66885 6.31731 9.71801C7.36133 10.7673 8.02389 12.1484 8.02389 13.5086C8.02389 13.7853 8.24744 14.0096 8.5232 14.0096C8.79895 14.0096 9.0225 13.7853 9.0225 13.5086C9.0225 11.8294 8.21361 10.2056 7.02398 9.00997C5.83421 7.81416 4.21516 6.9979 2.53155 6.9979Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.99443 12.9958C4.99443 14.1027 4.10024 15 2.99721 15C1.89418 15 1 14.1027 1 12.9958C1 11.8889 1.89418 10.9916 2.99721 10.9916C4.10024 10.9916 4.99443 11.8889 4.99443 12.9958ZM3.99582 12.9958C3.99582 13.5492 3.54873 13.9979 2.99721 13.9979C2.4457 13.9979 1.99861 13.5492 1.99861 12.9958C1.99861 12.4424 2.4457 11.9937 2.99721 11.9937C3.54873 11.9937 3.99582 12.4424 3.99582 12.9958Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
