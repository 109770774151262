import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgParagraph = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1.5 3C1.22386 3 1 3.22386 1 3.5 1 3.77614 1.22386 4 1.5 4H12.5C12.7761 4 13 3.77614 13 3.5 13 3.22386 12.7761 3 12.5 3H1.5ZM1.5 6.02539C1.22386 6.02539 1 6.24925 1 6.52539 1 6.80153 1.22386 7.02539 1.5 7.02539H14.5C14.7761 7.02539 15 6.80153 15 6.52539 15 6.24925 14.7761 6.02539 14.5 6.02539H1.5ZM1 9.49414C1 9.218 1.22386 8.99414 1.5 8.99414H12.5C12.7761 8.99414 13 9.218 13 9.49414 13 9.77028 12.7761 9.99414 12.5 9.99414H1.5C1.22386 9.99414 1 9.77028 1 9.49414ZM1.5 12C1.22386 12 1 12.2239 1 12.5 1 12.7761 1.22386 13 1.5 13H5.5C5.77614 13 6 12.7761 6 12.5 6 12.2239 5.77614 12 5.5 12H1.5Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
