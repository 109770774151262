import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLMenuListImgCol = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1.5001 1C1.2239 1 1 1.22351 1 1.49923 1 1.77495 1.2239 1.99847 1.5001 1.99847H2.50031C2.77651 1.99847 3.00042 1.77495 3.00042 1.49923 3.00042 1.22351 2.77651 1 2.50031 1H1.5001ZM3.99428 1.50118C3.99428 1.22546 4.21818 1.00195 4.49438 1.00195H9.49543C9.77163 1.00195 9.99553 1.22546 9.99553 1.50118V3.49812C9.99553 3.77383 9.77163 3.99735 9.49543 3.99735H4.49438C4.21818 3.99735 3.99428 3.77383 3.99428 3.49812V1.50118ZM6.51287 5.00264H5.51266C5.23646 5.00264 5.01256 5.22615 5.01256 5.50187V6.50034C5.01256 6.77606 5.23646 6.99957 5.51266 6.99957H6.51287C6.78907 6.99957 7.01298 6.77606 7.01298 6.50034V5.50187C7.01298 5.22615 6.78907 5.00264 6.51287 5.00264ZM5.49313 8.99358H6.49334C6.76954 8.99358 6.99344 9.21709 6.99344 9.49281V10.4913C6.99344 10.767 6.76954 10.9905 6.49334 10.9905H5.49313C5.21693 10.9905 4.99302 10.767 4.99302 10.4913V9.49281C4.99302 9.21709 5.21693 8.99358 5.49313 8.99358ZM6.4992 13.0031H5.49899C5.22279 13.0031 4.99888 13.2266 4.99888 13.5023V14.5008C4.99888 14.7765 5.22279 15 5.49899 15H6.4992C6.7754 15 6.9993 14.7765 6.9993 14.5008V13.5023C6.9993 13.2266 6.7754 13.0031 6.4992 13.0031ZM8.5089 5.00264H9.5091C9.78531 5.00264 10.0092 5.22615 10.0092 5.50187V6.50034C10.0092 6.77606 9.78531 6.99957 9.5091 6.99957H8.5089C8.2327 6.99957 8.00879 6.77606 8.00879 6.50034V5.50187C8.00879 5.22615 8.2327 5.00264 8.5089 5.00264ZM9.5091 8.99358H8.5089C8.2327 8.99358 8.00879 9.21709 8.00879 9.49281V10.4913C8.00879 10.767 8.2327 10.9905 8.5089 10.9905H9.5091C9.78531 10.9905 10.0092 10.767 10.0092 10.4913V9.49281C10.0092 9.21709 9.78531 8.99358 9.5091 8.99358ZM8.49278 13.0031H9.49299C9.76919 13.0031 9.99309 13.2266 9.99309 13.5023V14.5008C9.99309 14.7765 9.76919 15 9.49299 15H8.49278C8.21658 15 7.99267 14.7765 7.99267 14.5008V13.5023C7.99267 13.2266 8.21658 13.0031 8.49278 13.0031Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M10.9992 1.51483C10.9992 1.23912 11.2231 1.0156 11.4993 1.0156H14.4999C14.7761 1.0156 15 1.23912 15 1.51483V14.4949C15 14.7706 14.7761 14.9941 14.4999 14.9941H11.4993C11.2231 14.9941 10.9992 14.7706 10.9992 14.4949V1.51483ZM11.9994 2.01407V13.9957H13.9998V2.01407H11.9994Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M1 3.49812C1 3.2224 1.2239 2.99888 1.5001 2.99888H2.50031C2.77651 2.99888 3.00042 3.2224 3.00042 3.49812 3.00042 3.77383 2.77651 3.99735 2.50031 3.99735H1.5001C1.2239 3.99735 1 3.77383 1 3.49812ZM1.5001 4.99679C1.2239 4.99679 1 5.2203 1 5.49602 1 5.77174 1.2239 5.99526 1.5001 5.99526H2.50031C2.77651 5.99526 3.00042 5.77174 3.00042 5.49602 3.00042 5.2203 2.77651 4.99679 2.50031 4.99679H1.5001Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
