import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgBubbleInverted = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            d="M2.46763e-05 2.3216C2.46763e-05 1.04546 1.04206 0.00341797 2.3182 0.00341797H13.6818C14.958 0.00341797 16 1.04546 16 2.3216V10.6982C16 11.9774 14.9549 13.0078 13.6818 13.0078H8.18465L4.82276 15.8834C4.67444 16.0102 4.46587 16.0392 4.28859 15.9576C4.11132 15.8759 3.99776 15.6986 3.99776 15.5034V13.0078H2.31818C1.0451 13.0078 0 11.9774 0 10.6982L2.46763e-05 2.3216Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
