import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgProcessScheme = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M11.131 1.14644C11.2248 1.05267 11.352 0.999995 11.4846 1L14.5 1.00011C14.7762 1.00012 15 1.22397 15 1.50011V5.50347C15 5.63608 14.9473 5.76326 14.8535 5.85703C14.7598 5.95079 14.6326 6.00347 14.5 6.00347L11.4846 6.00336C11.2084 6.00335 10.9846 5.77949 10.9846 5.50336V3.97754L8.00428 3.97762V5.86422L10.3939 8.14426C10.4899 8.23587 10.5456 8.3619 10.5486 8.49457C10.5516 8.62725 10.5018 8.75569 10.4101 8.85159L8.00428 11.3671V12.9987H14V12.4738C14 12.1976 14.2239 11.9738 14.5 11.9738C14.7761 11.9738 15 12.1976 15 12.4738V14.5C15 14.7761 14.7761 15 14.5 15C14.2239 15 14 14.7761 14 14.5V13.9987H7.50428C7.22813 13.9987 7.00428 13.7749 7.00428 13.4987V11.3671L4.74615 9.00601H2V9.49112C2 9.76726 1.77614 9.99112 1.5 9.99112C1.22386 9.99112 1 9.76726 1 9.49112V7.49729C1 7.22115 1.22386 6.99729 1.5 6.99729C1.77614 6.99729 2 7.22115 2 7.49729V8.00601H4.75955L7.00428 5.86422V3.47764C7.00428 3.2015 7.22813 2.97765 7.50426 2.97764L10.9846 2.97754V1.5C10.9846 1.36739 11.0373 1.24021 11.131 1.14644ZM11.9846 5.00338L14 5.00345V2.00009L11.9846 2.00002V5.00338ZM7.50428 6.76932L5.66715 8.5222L7.50428 10.4431L9.3414 8.5222L7.50428 6.76932Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
