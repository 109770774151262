import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgShoppingCart = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.49555 1C4.77013 1 4.99272 1.22398 4.99272 1.50028V2.99622H14.5028C14.6688 2.99622 14.8238 3.07956 14.9161 3.21839C15.0084 3.35723 15.0257 3.53323 14.9623 3.68758L12.4964 9.69089C12.4196 9.87802 12.2382 10.0001 12.0369 10.0001H4.99272V11.0035L11.7396 11.0035C11.7895 10.9997 11.8399 10.9978 11.8908 10.9978C12.9892 10.9978 13.8795 11.8937 13.8795 12.9989C13.8795 14.1041 12.9892 15 11.8908 15C10.7925 15 9.90215 14.1041 9.90215 12.9989C9.90215 12.6368 9.99775 12.2971 10.1649 12.0041L5.75042 12.0041C5.91753 12.2971 6.01309 12.6367 6.01309 12.9987C6.01309 14.1039 5.12272 14.9998 4.0244 14.9998C2.92607 14.9998 2.0357 14.1039 2.0357 12.9987C2.0357 11.9023 2.91204 11.0118 3.99837 10.9978L3.99837 2.00112L1.49708 2.00066C1.2225 2.00061 0.99995 1.77659 1 1.50029C1.00005 1.224 1.22268 1.00006 1.49726 1.00011L4.46988 1.00066C4.47838 1.00022 4.48694 1 4.49555 1ZM11.7841 12.0041C11.2851 12.0577 10.8965 12.4826 10.8965 12.9989C10.8965 13.5515 11.3417 13.9994 11.8908 13.9994C12.44 13.9994 12.8852 13.5515 12.8852 12.9989C12.8852 12.479 12.4912 12.0518 11.9872 12.003C11.9764 12.0037 11.9655 12.0041 11.9545 12.0041L11.7841 12.0041ZM4.99272 8.99952L4.99272 3.99677H13.7594L11.7045 8.99952H4.99272ZM4.0244 13.9993C4.57356 13.9993 5.01874 13.5513 5.01874 12.9987C5.01874 12.4461 4.57356 11.9982 4.0244 11.9982C3.47523 11.9982 3.03005 12.4461 3.03005 12.9987C3.03005 13.5513 3.47523 13.9993 4.0244 13.9993Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
