import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgRightDoubleQuotationMark = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M8.99951 2.50217C8.99951 1.67416 9.67108 1.00293 10.4995 1.00293H13.4995C14.3279 1.00293 14.9995 1.67416 14.9995 2.50217V7.49964C14.9995 7.50315 14.9995 7.50665 14.9995 7.51015 14.9995 7.51217 14.9995 7.5142 14.9995 7.51622V12.5042C14.9995 13.8842 13.8802 15.0029 12.4995 15.0029H11.4995C11.2234 15.0029 10.9995 14.7792 10.9995 14.5032 10.9995 14.2272 11.2234 14.0034 11.4995 14.0034H12.4995C13.3279 14.0034 13.9995 13.3322 13.9995 12.5042V8.91357C13.8431 8.96882 13.6748 8.99888 13.4995 8.99888H10.4995C9.67108 8.99888 8.99951 8.32765 8.99951 7.49964V2.50217ZM10.4995 2.00242C10.2234 2.00242 9.99951 2.22617 9.99951 2.50217V7.49964C9.99951 7.77564 10.2234 7.99938 10.4995 7.99938H13.4995C13.7757 7.99938 13.9995 7.77564 13.9995 7.49964V2.50217C13.9995 2.22617 13.7757 2.00242 13.4995 2.00242H10.4995ZM2.49658 1.01464C1.66815 1.01464.996582 1.68588.996582 2.51388V7.51135C.996582 8.33936 1.66815 9.01059 2.49658 9.01059H5.49658C5.67117 9.01059 5.83879 8.98078 5.99463 8.92597V12.4981C5.99463 13.3261 5.32306 13.9973 4.49463 13.9973H3.49463C3.21849 13.9973 2.99463 14.2211 2.99463 14.4971 2.99463 14.7731 3.21849 14.9968 3.49463 14.9968H4.49463C5.87534 14.9968 6.99463 13.8781 6.99463 12.4981V7.58853C6.99593 7.56297 6.99658 7.53723 6.99658 7.51135V2.51388C6.99658 1.68588 6.32501 1.01464 5.49658 1.01464H2.49658ZM5.99658 7.45457V2.51388C5.99658 2.23788 5.77272 2.01414 5.49658 2.01414H2.49658C2.22044 2.01414 1.99658 2.23788 1.99658 2.51388V7.51135C1.99658 7.78735 2.22044 8.0111 2.49658 8.0111H5.49658C5.75773 8.0111 5.97211 7.811 5.99463 7.55583V7.49905C5.99463 7.48406 5.99529 7.46922 5.99658 7.45457Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
