import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgCrosshairF = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.99159 5.03409C6.46064 5.25325 5.24969 6.46515 5.0307 7.9973H1.49767C1.22187 7.9973 0.998291 8.22105 0.998291 8.49707C0.998291 8.77309 1.22187 8.99684 1.49767 8.99684H5.0307C5.24969 10.529 6.46064 11.7409 7.99159 11.96L7.99159 15.4958C7.99159 15.7718 8.21518 15.9956 8.49098 15.9956C8.76678 15.9956 8.99036 15.7718 8.99036 15.4958V11.96C10.5213 11.7409 11.7323 10.529 11.9513 8.99684H15.4989C15.7747 8.99684 15.9983 8.77309 15.9983 8.49707C15.9983 8.22105 15.7747 7.9973 15.4989 7.9973H11.9513C11.7323 6.46515 10.5213 5.25325 8.99036 5.03409V1.49538C8.99036 1.21936 8.76678 0.995605 8.49098 0.995605C8.21517 0.995605 7.99159 1.21936 7.99159 1.49538L7.99159 5.03409ZM5.99406 8.49707C5.99406 7.11699 7.11197 5.99821 8.49098 5.99821C9.86998 5.99821 10.9879 7.11699 10.9879 8.49707C10.9879 9.87715 9.86998 10.9959 8.49098 10.9959C7.11197 10.9959 5.99406 9.87715 5.99406 8.49707Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
