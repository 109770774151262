import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgMonitorBroken = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M10.0205 12H14.3462C14.8186 12 15.2449 11.8261 15.5501 11.4954C15.852 11.1684 16 10.7272 16 10.25V2.75C16 2.2728 15.852 1.83165 15.5501 1.50461C15.2449 1.17394 14.8186 1 14.3462 1H1.65385C1.18143 1 0.755136 1.17394 0.449906 1.50461C0.148024 1.83165 0 2.2728 0 2.75V10.25C0 10.7272 0.148024 11.1684 0.449906 11.4954C0.755136 11.8261 1.18143 12 1.65385 12H5.98974C5.97265 12.0758 5.94808 12.1608 5.91304 12.2536C5.74521 12.6985 5.31977 13.3692 4.22721 14.0805C4.04171 14.2013 3.95769 14.4296 4.02067 14.6418C4.08365 14.854 4.27866 14.9995 4.5 14.9995H11.5C11.7213 14.9995 11.9163 14.854 11.9793 14.6418C12.0423 14.4296 11.9583 14.2013 11.7728 14.0805C10.6807 13.3695 10.26 12.6997 10.0958 12.2565C10.061 12.1624 10.0369 12.0765 10.0205 12ZM1.18471 2.18289C1.08275 2.29335 1 2.4772 1 2.75V10.25C1 10.5228 1.08275 10.7066 1.18471 10.8171C1.28333 10.9239 1.43395 11 1.65385 11H14.3462C14.566 11 14.7167 10.9239 14.8153 10.8171C14.9173 10.7066 15 10.5228 15 10.25V2.75C15 2.4772 14.9173 2.29335 14.8153 2.18289C14.7167 2.07606 14.566 2 14.3462 2H11.3848V3.27273C11.3848 3.40326 11.3338 3.52862 11.2426 3.62202L10.3846 4.50083L11.8651 6.52278C11.9601 6.65252 11.9869 6.82002 11.9372 6.97292L11.3603 8.74564C11.2748 9.00823 10.9927 9.15182 10.7301 9.06636C10.4675 8.98091 10.3239 8.69876 10.4093 8.43617L10.907 6.90696L9.32755 4.74994C9.18316 4.55277 9.20247 4.28011 9.37319 4.10525L10.3848 3.06912V2H1.65385C1.43395 2 1.28333 2.07606 1.18471 2.18289ZM9.00647 12H7.00516C6.98132 12.1744 6.93442 12.3793 6.84868 12.6066C6.69062 13.0256 6.40711 13.5039 5.91816 13.9995H10.0833C9.59516 13.5032 9.31365 13.0239 9.15806 12.6037C9.07438 12.3777 9.02906 12.1738 9.00647 12Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
