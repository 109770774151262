import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgArrowDoubleRight = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M11.695 4.8254C11.5154 4.61574 11.5396 4.30009 11.7491 4.12038C11.9587 3.94067 12.2741 3.96495 12.4537 4.17461L15.8797 8.17461C16.0401 8.36186 16.0401 8.63816 15.8797 8.8254L12.4537 12.8254C12.2741 13.0351 11.9587 13.0593 11.7491 12.8796C11.5396 12.6999 11.5154 12.3843 11.695 12.1746L14.8423 8.50001L11.695 4.8254Z"
        />
        <path
          fill="currentColor"
          d="M8.32294 4.12038C8.11343 4.30009 8.08917 4.61574 8.26875 4.8254L10.978 7.98853H0.499634C0.223693 7.98853 0 8.21239 0 8.48853C0 8.76468 0.223693 8.98853 0.499634 8.98853H10.9977L8.26875 12.1746C8.08917 12.3843 8.11343 12.6999 8.32294 12.8796C8.53245 13.0593 8.84787 13.0351 9.02745 12.8254L12.4535 8.8254C12.6139 8.63816 12.6139 8.36186 12.4535 8.17461L9.02745 4.17461C8.84787 3.96495 8.53245 3.94067 8.32294 4.12038Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
