import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgVb = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M0.320223 3.03378C0.57811 2.93419 0.868005 3.06225 0.967719 3.31981L3.98782 11.1205L7.04345 3.31801C7.14417 3.06084 7.43455 2.9339 7.69205 3.03448C7.94955 3.13507 8.07665 3.42508 7.97594 3.68225L4.45139 12.6821C4.37618 12.8742 4.19064 13.0004 3.98418 13C3.77771 12.9996 3.59266 12.8727 3.5182 12.6803L0.033825 3.68045C-0.0658898 3.42289 0.0623348 3.13337 0.320223 3.03378Z" />
          <path
            fillRule="evenodd"
            d="M8.99108 3.50013C8.99108 3.22399 9.21522 3.00013 9.49171 3.00013H13.4513C14.8589 3.00013 16 4.13976 16 5.54555C16 6.42568 15.5377 7.19797 14.8423 7.63338C15.5469 8.18239 16 9.03837 16 10C16 11.6569 14.6551 13 12.9962 13H9.49171C9.21522 13 8.99108 12.7761 8.99108 12.5V3.50013ZM13.2169 7.00805C13.144 7.00277 13.0704 7.00008 12.9962 7.00008H9.99235V4.00012H13.4513C14.3059 4.00012 14.9987 4.69204 14.9987 5.54555C14.9987 6.35327 14.3431 7.00805 13.5343 7.00805H13.2169ZM13.1762 8.00804H9.99235V12H12.9962C14.1022 12 14.9987 11.1046 14.9987 10C14.9987 8.95609 14.1978 8.09895 13.1762 8.00804Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
