import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgTable = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M10.501 15H13.1212C13.6528 15 14.1372 14.8411 14.4891 14.4892C14.841 14.1373 15 13.6529 15 13.1213V2.87884C15 2.34719 14.841 1.86276 14.4892 1.51087C14.1373 1.15898 13.6528 1 13.1212 1H2.87878C2.34713 1 1.86271 1.15898 1.51084 1.51088C1.15897 1.86277 1.00002 2.3472 1.00002 2.87884L1.00002 4.49561L1 4.5L1.00002 4.50439L1.00001 9.49715L1 9.5L1.00001 9.50285L1 13.1213C1 13.6529 1.15896 14.1373 1.51085 14.4892C1.86273 14.8411 2.34715 15 2.87878 15H10.499C10.4993 15 10.5007 15 10.501 15C10.5007 15 10.5013 15 10.501 15ZM2.21797 2.21796C2.09712 2.33882 2.00002 2.54381 2.00002 2.87884L2.00002 4H14V2.87884C14 2.54382 13.9029 2.33883 13.782 2.21797C13.6612 2.0971 13.4562 2 13.1212 2H2.87878C2.54378 2 2.33882 2.0971 2.21797 2.21796ZM2.00002 5H4.99561V9H2.00001L2.00002 5ZM5.99561 5V9H10V5H5.99561ZM11 5V9H14V5H11ZM10 10H5.99561V14H10V10ZM11 14V10H14V13.1213C14 13.4563 13.9029 13.6612 13.7821 13.7821C13.6612 13.9029 13.4562 14 13.1212 14H11ZM2.00001 10H4.99561V14H2.87878C2.54375 14 2.33878 13.9029 2.21794 13.7821C2.0971 13.6612 2 13.4563 2 13.1213L2.00001 10Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
