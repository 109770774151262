import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgFolderClock = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M2.5 1C1.11929 1 0 2.11886 0 3.49904V12.4928C0 13.873 1.11929 14.9918 2.5 14.9918H7.49864C7.77478 14.9918 7.99864 14.7681 7.99864 14.492C7.99864 14.216 7.77478 13.9922 7.49864 13.9922H2.5C1.67157 13.9922 1 13.3209 1 12.4928V3.49904C1 2.67093 1.67157 1.99962 2.5 1.99962H6.84415C7.02268 1.99962 7.18766 2.09477 7.27702 2.24926L7.85299 3.24512C8.12105 3.7086 8.616 3.99405 9.15158 3.99405H14.4957C14.7719 3.99405 14.9957 4.21782 14.9957 4.49386V6.81481C14.9957 7.09085 15.2196 7.31462 15.4957 7.31462C15.7719 7.31462 15.9957 7.09085 15.9957 6.81481V4.49386C15.9957 3.66575 15.3242 2.99444 14.4957 2.99444H9.15158C8.97305 2.99444 8.80807 2.89929 8.71871 2.74479L8.14274 1.74894C7.87468 1.28545 7.37973 1 6.84415 1H2.5Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M15.998 11.0015C15.998 13.2098 14.2072 15 11.998 15C9.78891 15 7.99805 13.2098 7.99805 11.0015C7.99805 8.79324 9.78891 7.00307 11.998 7.00307C14.2072 7.00307 15.998 8.79324 15.998 11.0015ZM11.998 14.0004C13.6549 14.0004 14.998 12.6578 14.998 11.0015C14.998 9.34532 13.6549 8.00268 11.998 8.00268L11.998 10.9899H13.498C13.7742 10.9899 13.998 11.2137 13.998 11.4897C13.998 11.7657 13.7742 11.9895 13.498 11.9895H11.498C11.2219 11.9895 10.998 11.7657 10.998 11.4897V8.17333C9.83285 8.58501 8.99805 9.69582 8.99805 11.0015C8.99805 12.6578 10.3412 14.0004 11.998 14.0004Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
