import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgKey = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3.5 5C4.32837 5 5 4.32837 5 3.5C5 2.67163 4.32837 2 3.5 2C2.67162 2 2 2.67163 2 3.5C2 4.32837 2.67162 5 3.5 5ZM3.5 4C3.77609 4 4 3.77609 4 3.5C4 3.22391 3.77609 3 3.5 3C3.22391 3 3 3.22391 3 3.5C3 3.77609 3.22391 4 3.5 4Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M9 12L6.7025 9.7025C6.17109 9.89484 5.59781 10 5 10C2.23859 10 0 7.76141 0 5C0 2.23859 2.23859 0 5 0C7.76141 0 10 2.23859 10 5C10 5.60328 9.89313 6.18156 9.69734 6.71703L16 13.0923V16H13V14H11V12H9ZM8.54042 6.96915L8.75815 6.37364C8.91431 5.94653 9 5.48439 9 5C9 2.79088 7.20912 1 5 1C2.79088 1 1 2.79088 1 5C1 7.20912 2.79088 9 5 9C5.47977 9 5.93799 8.91573 6.36216 8.7622L6.96002 8.5458L9.41421 11H12V13H14V15H15V13.5032L8.54042 6.96915Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
