import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgShareGoogle = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M13.5002 1C14.881 1 16.0002 2.11929 16.0002 3.5C16.0002 4.88071 14.881 6 13.5002 6C12.5403 6 11.7067 5.45898 11.2878 4.66522L4.91509 7.85159C4.97047 8.05838 5 8.27574 5 8.5C5 8.72426 4.97047 8.94162 4.91509 9.14841L11.2878 12.3348C11.7067 11.541 12.5403 11 13.5002 11C14.881 11 16.0002 12.1193 16.0002 13.5C16.0002 14.8807 14.881 16 13.5002 16C12.1195 16 11.0002 14.8807 11.0002 13.5C11.0002 13.4369 11.0026 13.3744 11.0072 13.3125L4.46745 10.0426C4.00976 10.6255 3.29861 11 2.5 11C1.11929 11 0 9.88071 0 8.5C0 7.11929 1.11929 6 2.5 6C3.29861 6 4.00976 6.37446 4.46745 6.95738L11.0072 3.68752C11.0026 3.62561 11.0002 3.56308 11.0002 3.5C11.0002 2.11929 12.1195 1 13.5002 1ZM13.5002 2C14.3287 2 15.0002 2.67157 15.0002 3.5C15.0002 4.32843 14.3287 5 13.5002 5C12.6718 5 12.0002 4.32843 12.0002 3.5C12.0002 2.67157 12.6718 2 13.5002 2ZM13.5002 12C14.3287 12 15.0002 12.6716 15.0002 13.5C15.0002 14.3284 14.3287 15 13.5002 15C12.6718 15 12.0002 14.3284 12.0002 13.5C12.0002 12.6716 12.6718 12 13.5002 12ZM4 8.5C4 7.67157 3.32843 7 2.5 7C1.67157 7 1 7.67157 1 8.5C1 9.32843 1.67157 10 2.5 10C3.32843 10 4 9.32843 4 8.5Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
