export const FontSize = {
  XS: 11,
  S: 12,
  M: 14,
  L: 16,
  XL: 24,
  XXL: 28,
} as const;

export type FontSize = (typeof FontSize)[keyof typeof FontSize];
