import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgParagraphShort = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1.5 3C1.22386 3 1 3.22719 1 3.50743 1 3.78768 1.22386 4.01487 1.5 4.01487H14.5C14.7761 4.01487 15 3.78768 15 3.50743 15 3.22719 14.7761 3 14.5 3H1.5ZM1.5 5.98513C1.22386 5.98513 1 6.21232 1 6.49257 1 6.77281 1.22386 7 1.5 7H5.5C5.77614 7 6 6.77281 6 6.49257 6 6.21232 5.77614 5.98513 5.5 5.98513H1.5Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
