import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLMenuListImg = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1.5 2C1.22386 2 1 2.22386 1 2.5C1 2.77614 1.22386 3 1.5 3H2.5C2.77614 3 3 2.77614 3 2.5C3 2.22386 2.77614 2 2.5 2H1.5Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.5 2C4.22386 2 4 2.22386 4 2.5V4.5C4 4.77614 4.22386 5 4.5 5H6.5C6.77614 5 7 4.77614 7 4.5V2.5C7 2.22386 6.77614 2 6.5 2H4.5ZM5 4V3H6V4H5ZM4.5 6C4.22386 6 4 6.22386 4 6.5V8.5C4 8.77614 4.22386 9 4.5 9H6.5C6.77614 9 7 8.77614 7 8.5V6.5C7 6.22386 6.77614 6 6.5 6H4.5ZM5 8V7H6V8H5ZM4 10.5C4 10.2239 4.22386 10 4.5 10H6.5C6.77614 10 7 10.2239 7 10.5V12.5C7 12.7761 6.77614 13 6.5 13H4.5C4.22386 13 4 12.7761 4 12.5V10.5ZM5 11V12H6V11H5ZM8.5 2C8.22386 2 8 2.22386 8 2.5V4.5C8 4.77614 8.22386 5 8.5 5H10.5C10.7761 5 11 4.77614 11 4.5V2.5C11 2.22386 10.7761 2 10.5 2H8.5ZM9 4V3H10V4H9ZM8 6.5C8 6.22386 8.22386 6 8.5 6H10.5C10.7761 6 11 6.22386 11 6.5V8.5C11 8.77614 10.7761 9 10.5 9H8.5C8.22386 9 8 8.77614 8 8.5V6.5ZM9 7V8H10V7H9ZM8.5 10C8.22386 10 8 10.2239 8 10.5V12.5C8 12.7761 8.22386 13 8.5 13H10.5C10.7761 13 11 12.7761 11 12.5V10.5C11 10.2239 10.7761 10 10.5 10H8.5ZM9 12V11H10V12H9ZM12 2.5C12 2.22386 12.2239 2 12.5 2H14.5C14.7761 2 15 2.22386 15 2.5V4.5C15 4.77614 14.7761 5 14.5 5H12.5C12.2239 5 12 4.77614 12 4.5V2.5ZM13 3V4H14V3H13ZM12.5 6C12.2239 6 12 6.22386 12 6.5V8.5C12 8.77614 12.2239 9 12.5 9H14.5C14.7761 9 15 8.77614 15 8.5V6.5C15 6.22386 14.7761 6 14.5 6H12.5ZM13 8V7H14V8H13ZM12 10.5C12 10.2239 12.2239 10 12.5 10H14.5C14.7761 10 15 10.2239 15 10.5V12.5C15 12.7761 14.7761 13 14.5 13H12.5C12.2239 13 12 12.7761 12 12.5V10.5ZM13 11V12H14V11H13Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M1 4.5C1 4.22386 1.22386 4 1.5 4H2.5C2.77614 4 3 4.22386 3 4.5 3 4.77614 2.77614 5 2.5 5H1.5C1.22386 5 1 4.77614 1 4.5ZM1.5 6C1.22386 6 1 6.22386 1 6.5 1 6.77614 1.22386 7 1.5 7H2.5C2.77614 7 3 6.77614 3 6.5 3 6.22386 2.77614 6 2.5 6H1.5Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
