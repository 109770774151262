import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgChevronRightLine = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M12.9982 1.49854C12.9982 1.22239 12.7743.998536 12.4982.998536 12.222.998536 11.9982 1.22239 11.9982 1.49854V14.5008C11.9982 14.7769 12.222 15.0008 12.4982 15.0008 12.7743 15.0008 12.9982 14.7769 12.9982 14.5008V1.49854ZM3.9044 14.7949C3.74198 15.0183 3.42928 15.0676 3.20595 14.9052 2.98262 14.7428 2.93325 14.4301 3.09567 14.2068L7.60988 7.99971 3.09567 1.79266C2.93325 1.56933 2.98262 1.25662 3.20595 1.0942 3.42928.931783 3.74198.981159 3.9044 1.20448L8.6325 7.70562C8.76001 7.88094 8.76001 8.11847 8.6325 8.29379L3.9044 14.7949Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
