import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgDiamond = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.03462 1.00293L0.5 6.25066L8.00024 15.0029L15.5 6.25066L11.9659 1.00293H4.03462ZM1.85717 6.00807L4.55358 1.98373H6.56616L3.70468 6.00807H1.85717ZM4.91932 6.00807L7.78081 1.98373H8.21947L11.0805 6.00807L4.91932 6.00807ZM11.0061 6.98887L4.99374 6.98887L8.00014 13.1014L11.0061 6.98887ZM10.1151 10.9914L12.0982 6.98887H13.5858L10.1151 10.9914ZM12.2951 6.00807L9.43411 1.98373H11.4469L14.1428 6.00807H12.2951ZM2.41424 6.98887H3.90157L5.88492 10.9909L2.41424 6.98887Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
