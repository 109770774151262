import { UnreachableCaseException } from '@kontent-ai/errors';
import styled, { css } from 'styled-components';
import {
  colorAlertText,
  colorPrimary,
  colorPrimaryHover,
  colorTextDefault,
  colorTextDisabled,
} from '../../../tokens/decision/colors.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { transition250 } from '../../../tokens/quarks/transitions.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { colorTextOnDark } from '../decisionTokens.ts';
import { IBaseButtonComponentProps, IInjectedProps } from './BaseButtonComponent.tsx';
import { StyledButton } from './StyledButton.tsx';

type StyledButtonContentProps = Pick<
  IBaseButtonComponentProps,
  'buttonStyle' | 'disabled' | 'destructive'
> &
  Pick<IInjectedProps, '$size' | '$activated'>;

const getTextColor = ({ buttonStyle, disabled, $activated }: StyledButtonContentProps) => {
  if (disabled) {
    return colorTextDisabled;
  }

  switch (buttonStyle) {
    case 'primary':
    case 'secondary-inverse':
      return colorTextOnDark;
    case 'primary-inverse':
      return $activated ? colorPrimaryHover : colorPrimary;
    case 'secondary':
    case 'tertiary':
      return colorTextDefault;
    default:
      throw UnreachableCaseException(buttonStyle);
  }
};

const getDestructiveTextColor = ({
  buttonStyle,
  disabled,
  $activated,
}: StyledButtonContentProps) => {
  if (disabled) {
    return colorTextDisabled;
  }
  switch (buttonStyle) {
    case 'primary':
    case 'primary-inverse':
      return colorTextOnDark;
    case 'secondary':
    case 'secondary-inverse':
    case 'tertiary':
      return $activated ? colorTextOnDark : colorAlertText;
    default:
      throw UnreachableCaseException(buttonStyle);
  }
};

const getTextColorHoverStyles = ({
  buttonStyle,
  disabled,
  destructive,
}: StyledButtonContentProps) => {
  if (disabled) {
    return;
  }
  if (destructive) {
    return css`
      ${StyledButton}:hover &&,
      ${StyledButton}:active && {
        color: ${colorTextOnDark};
      }
    `;
  }
  switch (buttonStyle) {
    case 'primary-inverse':
      return css`
        ${StyledButton}:hover &&,
        ${StyledButton}:active && {
          color: ${colorPrimaryHover};
        }
      `;
    default:
      return;
  }
};

export const StyledButtonContent = styled.span<StyledButtonContentProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${px(Spacing.S)};
  height: 100%;
  width: 100%;

  color: ${({ destructive }) => (destructive ? getDestructiveTextColor : getTextColor)};
  ${getTextColorHoverStyles};

  transition: color ${transition250};
`;
