import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgTWithCross = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M10.5 1C10.7761 1 11 1.22386 11 1.5V2.5C11 2.77614 10.7761 3 10.5 3 10.2239 3 10 2.77614 10 2.5V2H6.94947L6.06057 9H6.5C6.77614 9 7 9.22386 7 9.5 7 9.77614 6.77614 10 6.5 10H4.5C4.22386 10 4 9.77614 4 9.5 4 9.22386 4.22386 9 4.5 9H5.05041L5.93932 2H3V2.5C3 2.77614 2.77614 3 2.5 3 2.22386 3 2 2.77614 2 2.5V1.5C2 1.22386 2.22386 1 2.5 1H10.5ZM1.5 12C1.22386 12 1 12.2239 1 12.5 1 12.7761 1.22386 13 1.5 13H8.5C8.77614 13 9 12.7761 9 12.5 9 12.2239 8.77614 12 8.5 12H1.5ZM13.2071 12.5 14.8536 14.1464C15.0488 14.3417 15.0488 14.6583 14.8536 14.8536 14.6583 15.0488 14.3417 15.0488 14.1464 14.8536L12.5 13.2071 10.8536 14.8536C10.6583 15.0488 10.3417 15.0488 10.1464 14.8536 9.95118 14.6583 9.95118 14.3417 10.1464 14.1464L11.7929 12.5 10.1464 10.8536C9.95118 10.6583 9.95118 10.3417 10.1464 10.1464 10.3417 9.95118 10.6583 9.95118 10.8536 10.1464L12.5 11.7929 14.1464 10.1464C14.3417 9.95118 14.6583 9.95118 14.8536 10.1464 15.0488 10.3417 15.0488 10.6583 14.8536 10.8536L13.2071 12.5Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
