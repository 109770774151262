import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgModalQuestion = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M5 5.04545C5 3.3064 6.29887 2 8 2 9.70113 2 11 3.3064 11 5.04545 11 5.62404 10.6792 6.20859 10.1486 6.76965 9.62438 7.3239 8.95066 7.79584 8.3758 8.14205 7.5726 8.62577 6.98477 9.4896 6.98477 10.4851V11.5039C6.98477 11.7801 7.20862 12.0039 7.48477 12.0039 7.76091 12.0039 7.98477 11.7801 7.98477 11.5039V10.4851C7.98477 9.8962 8.33769 9.33235 8.89171 8.9987 9.50342 8.63029 10.2628 8.10416 10.8751 7.45679 11.4809 6.81622 12 5.99562 12 5.04545 12 2.76632 10.2655 1 8 1 5.73446 1 4 2.76632 4 5.04545 4 5.3216 4.22386 5.54545 4.5 5.54545 4.77614 5.54545 5 5.3216 5 5.04545ZM7.47852 15.002C8.0308 15.002 8.47852 14.5542 8.47852 14.002 8.47852 13.4497 8.0308 13.002 7.47852 13.002 6.92623 13.002 6.47852 13.4497 6.47852 14.002 6.47852 14.5542 6.92623 15.002 7.47852 15.002Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
