import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLRows4 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
          <path d="M1 15.5C1 15.7761 1.22386 16 1.5 16L14.5 16C14.7761 16 15 15.7761 15 15.5V13.5C15 13.2239 14.7761 13 14.5 13L1.5 13C1.22386 13 1 13.2239 1 13.5L1 15.5ZM14 15 2 15V14L14 14V15ZM1 7.5C1 7.77614 1.22386 8 1.5 8L14.5 8C14.7761 8 15 7.77614 15 7.5V5.5C15 5.22386 14.7761 5 14.5 5L1.5 5C1.22386 5 1 5.22386 1 5.5L1 7.5ZM14 7 2 7V6L14 6V7ZM1.5 12C1.22386 12 1 11.7761 1 11.5L1 9.5C1 9.22386 1.22386 9 1.5 9L14.5 9C14.7761 9 15 9.22386 15 9.5V11.5C15 11.7761 14.7761 12 14.5 12L1.5 12ZM2 11 14 11V10L2 10V11ZM1 3.5C1 3.77614 1.22386 4 1.5 4L14.5 4C14.7761 4 15 3.77614 15 3.5V1.5C15 1.22386 14.7761 1 14.5 1L1.5 1C1.22386 1 1 1.22386 1 1.5L1 3.5ZM14 3 2 3 2 2 14 2V3Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" transform="rotate(-90 8 8)" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
