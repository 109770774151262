import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgBroom = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M6.01648 2.99988C6.01648 1.96161 6.77004 1 7.98326 1C9.06079 1 9.98158 1.92453 9.98158 2.99988V4.19011L13.7261 6.05218C13.896 6.13668 14.0034 6.3101 14.0034 6.49988V7.49988C14.0034 7.51499 14.0028 7.52994 14.0014 7.54471L14.9983 14.4283C15.0191 14.572 14.9764 14.7175 14.8815 14.8272C14.7865 14.937 14.6485 15 14.5034 15H11.5034C11.3362 15 11.1801 14.9165 11.0874 14.7774L10.5774 14.0124C10.5561 14.0152 10.5343 14.0166 10.5122 14.0166C10.4846 14.0166 10.4574 14.0144 10.431 14.01L9.91945 14.7774C9.82671 14.9165 9.6706 15 9.50342 15H1.50342C1.35868 15 1.22105 14.9373 1.12608 14.8281C1.03112 14.7188 0.988151 14.5738 1.00828 14.4305L1.99131 7.43047C1.99417 7.41009 1.99823 7.39015 2.00342 7.37071V6.49988C2.00342 6.31026 2.11068 6.13695 2.2804 6.05237L6.01648 4.19041V2.99988ZM11.0122 12.8618V10.5166C11.0122 10.2405 10.7884 10.0166 10.5122 10.0166C10.2361 10.0166 10.0122 10.2405 10.0122 10.5166V12.8354L9.23583 14H2.07854L2.92114 8L13.0569 8L13.9258 14H11.771L11.0122 12.8618ZM13.0034 6.80965V7L3.00342 7V6.80935L6.73951 4.94738C6.90922 4.8628 7.01648 4.6895 7.01648 4.49988V2.99988C7.01648 2.43815 7.39514 2 7.98326 2C8.50692 2 8.98158 2.47523 8.98158 2.99988V4.49988C8.98158 4.68966 9.08902 4.86308 9.25895 4.94758L13.0034 6.80965Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
