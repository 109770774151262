import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgDocPlus = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M3.9873 10.4985C3.9873 10.2224 4.21116 9.99854 4.4873 9.99854H5.99854V8.52832C5.99854 8.25218 6.22239 8.02832 6.49854 8.02832C6.77468 8.02832 6.99854 8.25218 6.99854 8.52832V9.99854H8.4873C8.76345 9.99854 8.9873 10.2224 8.9873 10.4985C8.9873 10.7747 8.76345 10.9985 8.4873 10.9985H6.99854V12.5283C6.99854 12.8045 6.77468 13.0283 6.49854 13.0283C6.22239 13.0283 5.99854 12.8045 5.99854 12.5283V10.9985H4.4873C4.21116 10.9985 3.9873 10.7747 3.9873 10.4985Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3 0H9.19879L13.998 4.57141V14.9999C13.998 15.5522 13.5503 15.9999 12.998 15.9999H3C2.44771 15.9999 2 15.5522 2 14.9999V0.999999C2 0.447714 2.44772 0 3 0ZM12.998 14.9999H3L3 0.999999L8 1V4.49561C8 5.32403 8.67157 5.99561 9.5 5.99561H12.998V14.9999ZM12.9934 4.99561L9 1.19171V4.49561C9 4.77175 9.22386 4.99561 9.5 4.99561H12.9934Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
