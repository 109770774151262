import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgChevronLeft = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M10.2763 1.08395C10.0456 0.930839 9.73402 0.99301 9.58032 1.22282L4.58422 7.72282C4.47193 7.89071 4.47193 8.10939 4.58422 8.27729L9.58032 14.7773C9.73403 15.0071 10.0456 15.0693 10.2763 14.9162C10.507 14.763 10.5694 14.4526 10.4157 14.2228L5.60505 8.00005L10.4157 1.77729C10.5694 1.54749 10.507 1.23707 10.2763 1.08395Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
