import { UnreachableCaseException } from '@kontent-ai/errors';
import { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { offsetFocus } from '../../../tokens/decision/focus.ts';
import { Size } from '../../../tokens/decision/spacing.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { transition250 } from '../../../tokens/quarks/transitions.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { Anchor, RouterLink, RouterLinkProps } from '../../Anchor/Anchor.tsx';
import { ButtonDisplay } from '../buttonDisplay.ts';
import { ButtonSize } from '../buttonSize.ts';
import { ButtonStyle } from '../buttonStyle.ts';
import {
  getBorderWidth,
  getSpecificButtonStyles,
  getTypographyStyles,
} from '../utils/stylingUtils.ts';

const getButtonSizeStyles = (
  buttonSize: Size,
  horizontalPadding: Spacing,
  borderWidth: number,
) => css`
  border-width: ${px(borderWidth)};
  border-style: solid;
  height: ${px(buttonSize)};
  padding: 0 ${px(horizontalPadding - borderWidth)};
`;

const getSizeButtonStyles = (buttonStyle: ButtonStyle, size: ButtonSize) => {
  const borderWidth = getBorderWidth(buttonStyle);

  switch (size) {
    case 'small':
      return getButtonSizeStyles(Size.S, Spacing.L, borderWidth);
    case 'medium':
      return getButtonSizeStyles(Size.M, Spacing.XL, borderWidth);
    case 'large':
      return getButtonSizeStyles(Size.L, Spacing.XXL, borderWidth);
    default:
      throw UnreachableCaseException(size);
  }
};

const baseButtonStyles = css<BaseStyledButtonProps>`
  &::before {
    ${({ $isFocusVisible }) => $isFocusVisible && shadowFocusStyles};
    inset: ${px(-1 * offsetFocus)};
    content: '';
    position: absolute;
    border-radius: ${px(BorderRadius.Pill)};
  }

  border-radius: ${px(BorderRadius.Pill)};
  border-width: 0;
  border-style: solid;
  cursor: pointer;
  margin-bottom: 0;
  outline: none;
  position: relative;
  touch-action: manipulation;
  transition: background-color ${transition250};
  transition-property: background-color, color, border-color;

  user-select: none;
  white-space: nowrap;

  &:hover,
  &:focus,
  & {
    outline: none;
    text-decoration: none;
  }

  &:active {
    outline: none;
  }

  ${({ $buttonDisplay }) =>
    $buttonDisplay === 'block' &&
    css`
      display: block;
      width: 100%;
    `};
  ${({ $buttonStyle, $size }) => getSizeButtonStyles($buttonStyle, $size)};
  ${({ $size }) => getTypographyStyles($size)};
`;

type BaseStyledButtonProps = {
  readonly $buttonDisplay?: ButtonDisplay;
  readonly $buttonStyle: ButtonStyle;
  readonly $size: ButtonSize;
  readonly disabled?: boolean;
  readonly destructive?: boolean;
  readonly $isFocusVisible: boolean;
};

export interface IStyledButtonProps
  extends BaseStyledButtonProps,
    ButtonHTMLAttributes<HTMLButtonElement> {
  readonly disabled?: boolean;
  readonly $activated?: boolean;
}

export const StyledButton = styled.button<IStyledButtonProps>`
  ${baseButtonStyles};
  ${({ disabled }) =>
    disabled &&
    css`
    &,
    &[disabled] {
      cursor: not-allowed;
    }
  `};
  ${getSpecificButtonStyles};
`;

StyledButton.displayName = 'StyledButton';

export interface IStyledRouterLinkButtonProps
  extends Omit<BaseStyledButtonProps, 'disabled'>,
    RouterLinkProps {}

export const StyledRouterLinkButton = styled(RouterLink).attrs(() => ({
  disabled: false,
}))<IStyledRouterLinkButtonProps>`
  ${baseButtonStyles};
  ${getSpecificButtonStyles};
  display: flex;

  &:focus-visible {
    border-radius: ${px(BorderRadius.Pill)};
  }
`;

StyledRouterLinkButton.displayName = 'StyledRouterLinkButton';

export interface IStyledAnchorButtonProps
  extends Omit<BaseStyledButtonProps, 'disabled'>,
    AnchorHTMLAttributes<HTMLAnchorElement> {}

export const StyledAnchorButton = styled(Anchor).attrs(() => ({
  disabled: false,
}))<IStyledAnchorButtonProps>`
  ${baseButtonStyles};
  ${getSpecificButtonStyles};
  display: flex;

  &:focus-visible {
    border-radius: ${px(BorderRadius.Pill)};
  }
`;

StyledAnchorButton.displayName = 'StyledAnchorButton';
