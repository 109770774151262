import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgH3 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M0 15V2H1V8H6V2H7V15H6V9H1V15H0ZM12.0714 3C10.9585 3 10 3.97521 10 5.25H9C9 3.48723 10.344 2 12.0714 2 13.7988 2 15.1429 3.48723 15.1429 5.25 15.1429 6.28793 14.6769 7.23032 13.9411 7.82985 15.168 8.42364 16 9.7511 16 11.25 16 13.2826 14.4699 15 12.5 15 10.5301 15 9 13.2826 9 11.25H10C10 12.8072 11.1562 14 12.5 14 13.8438 14 15 12.8072 15 11.25 15 9.69278 13.8438 8.5 12.5 8.5V8.46895C12.3603 8.48941 12.2172 8.5 12.0714 8.5H10.7857V7.5H12.0714C13.1844 7.5 14.1429 6.52479 14.1429 5.25 14.1429 3.97521 13.1844 3 12.0714 3Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
