import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgArrowCurvedRight = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M6.8724 3C10.5255 3 14.0171 5.90841 14.0171 9.472V10.2955L15.1482 9.17636C15.3452 8.98141 15.6622 8.98392 15.8562 9.18197C16.0501 9.38001 16.0476 9.6986 15.8506 9.89355L13.8677 11.8554C13.6729 12.0482 13.3602 12.0482 13.1654 11.8554L11.1825 9.89355C10.9855 9.6986 10.983 9.38001 11.177 9.18197C11.3709 8.98392 11.6879 8.98141 11.8849 9.17636L13.0159 10.2954V9.472C13.0159 6.58138 10.0968 4.00636 6.8724 4.00636C3.67603 4.00636 1.00122 6.5559 1.00122 9.472V10.1949C1.00122 10.4728 0.777091 10.6981 0.500611 10.6981C0.224131 10.6981 0 10.4728 0 10.1949V9.472C0 5.93389 3.19133 3 6.8724 3Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
