import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLImg3Cols3 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.5 1C1.22386 1 1 1.22358 1 1.49937V4.49561C1 4.77141 1.22386 4.99498 1.5 4.99498H14.5C14.7761 4.99498 15 4.77141 15 4.49561V1.49937C15 1.22358 14.7761 1 14.5 1H1.5ZM2 3.99624V1.99875H14V3.99624H2ZM1.5 6.01129C1.22386 6.01129 1 6.23486 1 6.51066V14.5006C1 14.7764 1.22386 15 1.5 15H4.5C4.77614 15 5 14.7764 5 14.5006V6.51066C5 6.23486 4.77614 6.01129 4.5 6.01129H1.5ZM2 14.0013V7.01003H4V14.0013H2ZM6 6.51066C6 6.23486 6.22386 6.01129 6.5 6.01129H9.5C9.77614 6.01129 10 6.23486 10 6.51066V14.5006C10 14.7764 9.77614 15 9.5 15H6.5C6.22386 15 6 14.7764 6 14.5006V6.51066ZM7 7.01003V14.0013H9V7.01003H7ZM11.5 6.01129C11.2239 6.01129 11 6.23486 11 6.51066V14.5006C11 14.7764 11.2239 15 11.5 15H14.5C14.7761 15 15 14.7764 15 14.5006V6.51066C15 6.23486 14.7761 6.01129 14.5 6.01129H11.5ZM12 14.0013V7.01003H14V14.0013H12Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
