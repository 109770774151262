import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgListBullets = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 18 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M2.49805 1C1.66962 1 0.998047 1.67157 0.998047 2.5C0.998047 3.32843 1.66962 4 2.49805 4C3.32647 4 3.99805 3.32843 3.99805 2.5C3.99805 1.67157 3.32647 1 2.49805 1ZM1.99805 2.5C1.99805 2.22386 2.2219 2 2.49805 2C2.77419 2 2.99805 2.22386 2.99805 2.5C2.99805 2.77614 2.77419 3 2.49805 3C2.2219 3 1.99805 2.77614 1.99805 2.5Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M6.00049 2.50391C6.00049 2.22776 6.22435 2.00391 6.50049 2.00391H16.5005C16.7766 2.00391 17.0005 2.22776 17.0005 2.50391 17.0005 2.78005 16.7766 3.00391 16.5005 3.00391H6.50049C6.22435 3.00391 6.00049 2.78005 6.00049 2.50391ZM6.49561 8.01562C6.21946 8.01562 5.99561 8.23948 5.99561 8.51562 5.99561 8.79177 6.21946 9.01562 6.49561 9.01562H16.4956C16.7717 9.01562 16.9956 8.79177 16.9956 8.51562 16.9956 8.23948 16.7717 8.01562 16.4956 8.01562H6.49561ZM6.5083 14.001C6.23216 14.001 6.0083 14.2248 6.0083 14.501 6.0083 14.7771 6.23216 15.001 6.5083 15.001H16.5083C16.7844 15.001 17.0083 14.7771 17.0083 14.501 17.0083 14.2248 16.7844 14.001 16.5083 14.001H6.5083Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M.998047 8.5C.998047 7.67157 1.66962 7 2.49805 7 3.32647 7 3.99805 7.67157 3.99805 8.5 3.99805 9.32843 3.32647 10 2.49805 10 1.66962 10 .998047 9.32843.998047 8.5ZM2.49805 8C2.2219 8 1.99805 8.22386 1.99805 8.5 1.99805 8.77614 2.2219 9 2.49805 9 2.77419 9 2.99805 8.77614 2.99805 8.5 2.99805 8.22386 2.77419 8 2.49805 8ZM2.49805 13C1.66962 13 .998047 13.6716.998047 14.5.998047 15.3284 1.66962 16 2.49805 16 3.32647 16 3.99805 15.3284 3.99805 14.5 3.99805 13.6716 3.32647 13 2.49805 13ZM1.99805 14.5C1.99805 14.2239 2.2219 14 2.49805 14 2.77419 14 2.99805 14.2239 2.99805 14.5 2.99805 14.7761 2.77419 15 2.49805 15 2.2219 15 1.99805 14.7761 1.99805 14.5Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
