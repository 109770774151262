import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgMessage = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M13.9999 2.00488C13.9999 2.00488 14 2.00488 13.9999 2.00488H14.0015C14.5537 2.00488 15.0015 2.4526 15.0015 3.00488V13.0036C15.0015 13.5559 14.5537 14.0036 14.0015 14.0036H2.00147C1.44918 14.0036 1.00146 13.5559 1.00146 13.0036V3.00488C1.00146 2.45423 1.44653 2.00754 1.99656 2.00489C1.99819 2.00489 1.99983 2.00488 2.00146 2.00488H13.9999ZM2.00146 3.21345V13.0036L14.0015 13.0036V3.21053L8.35355 8.85844C8.15829 9.0537 7.84171 9.0537 7.64645 8.85844L2.00146 3.21345ZM3.20711 3.00488L12.7929 3.00488L8 7.79778L3.20711 3.00488Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
