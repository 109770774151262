import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLMenuTextColBottom = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1.5 1.00488C1.22386 1.00488 1 1.22852 1 1.5044 1 1.78028 1.22386 2.00392 1.5 2.00392H3.5C3.77614 2.00392 4 1.78028 4 1.5044 4 1.22852 3.77614 1.00488 3.5 1.00488H1.5ZM1.5 2.99222C1.22386 2.99222 1 3.21586 1 3.49174 1 3.76761 1.22386 3.99125 1.5 3.99125H3.5C3.77614 3.99125 4 3.76761 4 3.49174 4 3.21586 3.77614 2.99222 3.5 2.99222H1.5ZM1 5.51224C1 5.23637 1.22386 5.01273 1.5 5.01273H3.5C3.77614 5.01273 4 5.23637 4 5.51224 4 5.78812 3.77614 6.01176 3.5 6.01176H1.5C1.22386 6.01176 1 5.78812 1 5.51224ZM6.5 1.00488C6.22386 1.00488 6 1.22852 6 1.5044 6 1.78028 6.22386 2.00392 6.5 2.00392H14.5009C14.777 2.00392 15.0009 1.78028 15.0009 1.5044 15.0009 1.22852 14.777 1.00488 14.5009 1.00488H6.5Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M6 5.51224C6 5.23637 6.22386 5.01273 6.5 5.01273H14.5009C14.777 5.01273 15.0009 5.23637 15.0009 5.51224V13.5054C15.0009 13.7812 14.777 14.0049 14.5009 14.0049H6.5C6.22386 14.0049 6 13.7812 6 13.5054V5.51224ZM7 6.01176V13.0058H14.0009V6.01176H7Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M6.5 3.00295C6.22386 3.00295 6 3.22659 6 3.50247C6 3.77834 6.22386 4.00198 6.5 4.00198H12.5C12.7761 4.00198 13 3.77834 13 3.50247C13 3.22659 12.7761 3.00295 12.5 3.00295H6.5Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
