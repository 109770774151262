import React from 'react';
import styled from 'styled-components';
import { Box } from '../../../layout/Box/Box.tsx';
import { borderWidthDefault } from '../../../tokens/decision/border.ts';
import {
  colorTextLowEmphasis,
  colorTextLowEmphasisInverse,
} from '../../../tokens/decision/colors.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { Spacing, gridUnit } from '../../../tokens/quarks/spacing.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { ShortcutStyle } from '../shortcutStyle.ts';

type ShortcutKeyProps = {
  readonly shortcutStyle?: ShortcutStyle;
  readonly text: string;
};

type StyledShortcutKeyProps = {
  readonly $isInverse: boolean;
};

const StyledShortcutKey = styled(Box)<StyledShortcutKeyProps>`
  border: ${px(borderWidthDefault)} solid ${({ $isInverse }) => ($isInverse ? colorTextLowEmphasisInverse : colorTextLowEmphasis)};
  color: ${({ $isInverse }) => ($isInverse ? colorTextLowEmphasisInverse : colorTextLowEmphasis)};
`;

export const ShortcutKey: React.FC<ShortcutKeyProps> = ({ shortcutStyle = 'default', text }) => (
  <StyledShortcutKey
    $isInverse={shortcutStyle === 'inverse'}
    alignItems="center"
    borderRadius={BorderRadius.S}
    component="span"
    display="inline-flex"
    height={gridUnit * 2.25}
    paddingX={Spacing.XS}
    typography={Typography.Caption}
  >
    {text}
  </StyledShortcutKey>
);

ShortcutKey.displayName = 'ShortcutKey';
