import {
  colorAlertBackgroundInverse,
  colorAlertText,
  colorIconDefaultInverse,
  colorTextDefaultInverse,
} from '../../tokens/decision/colors.ts';

export const inverseBadgeStyles = {
  color: colorAlertText,
  backgroundColor: colorIconDefaultInverse,
};

export const defaultBadgeStyles = {
  color: colorTextDefaultInverse,
  backgroundColor: colorAlertBackgroundInverse,
};
