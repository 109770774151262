import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgSchemeConnectedCircles = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1 3.50279C1 4.71362 1.85816 5.72363 2.99833 5.95552L2.99833 10.0446C1.8584 10.2767 1.00049 11.2865 1.00049 12.4972C1.00049 13.8795 2.11884 15 3.4984 15C4.87795 15 5.99631 13.8795 5.99631 12.4972C5.99631 12.4251 5.99326 12.3536 5.98729 12.283L10.7959 9.39221C10.8116 9.38277 10.8266 9.3726 10.8409 9.36177C11.2823 9.7559 11.8643 9.99539 12.5021 9.99539C13.8816 9.99539 15 8.87485 15 7.4926C15 6.11034 13.8816 4.9898 12.5021 4.9898C11.6384 4.9898 10.8771 5.42902 10.4285 6.09669L5.94151 4.02432C5.9771 3.8561 5.99582 3.68164 5.99582 3.50279C5.99582 2.12054 4.87747 1 3.49791 1C2.11835 1 1 2.12054 1 3.50279ZM3.49791 2.00112C2.67018 2.00112 1.99916 2.67344 1.99916 3.50279C1.99916 4.30633 2.62905 4.96246 3.42108 5.00253C3.44612 4.99866 3.47178 4.99665 3.49791 4.99665C3.52404 4.99665 3.54969 4.99866 3.57474 5.00253C4.36677 4.96246 4.99665 4.30633 4.99665 3.50279C4.99665 2.67344 4.32564 2.00112 3.49791 2.00112ZM10.048 7.02333L5.54174 4.94204C5.18123 5.45505 4.63319 5.82623 3.99749 5.95552L3.99749 10.0444C4.72742 10.1927 5.34181 10.6599 5.68954 11.2945L10.2412 8.55818C10.0892 8.23488 10.0042 7.87368 10.0042 7.4926C10.0042 7.3322 10.0192 7.17533 10.048 7.02333ZM3.49791 11.0034C3.47182 11.0034 3.44621 11.0013 3.4212 10.9975C2.62934 11.0377 1.99965 11.6938 1.99965 12.4972C1.99965 13.3266 2.67066 13.9989 3.4984 13.9989C4.32613 13.9989 4.99714 13.3266 4.99714 12.4972C4.99714 11.6935 4.36706 11.0373 3.57486 10.9975C3.54977 11.0013 3.52408 11.0034 3.49791 11.0034ZM11.0033 7.4926C11.0033 6.66325 11.6744 5.99092 12.5021 5.99092C13.3298 5.99092 14.0008 6.66325 14.0008 7.4926C14.0008 8.32195 13.3298 8.99427 12.5021 8.99427C11.6744 8.99427 11.0033 8.32195 11.0033 7.4926Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
