import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgPalette = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M10.04 7.51526C10.04 6.68501 10.7116 6.01196 11.54 6.01196C12.3685 6.01196 13.04 6.68501 13.04 7.51526C13.04 8.3455 12.3685 9.01855 11.54 9.01855C10.7116 9.01855 10.04 8.3455 10.04 7.51526ZM11.54 7.01416C11.2639 7.01416 11.04 7.23851 11.04 7.51526C11.04 7.79201 11.2639 8.01635 11.54 8.01635C11.8162 8.01635 12.04 7.79201 12.04 7.51526C12.04 7.23851 11.8162 7.01416 11.54 7.01416Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M4.4707 10.0207C4.74685 10.0207 4.9707 9.7964 4.9707 9.51965 4.9707 9.2429 4.74685 9.01855 4.4707 9.01855 4.19456 9.01855 3.9707 9.2429 3.9707 9.51965 3.9707 9.7964 4.19456 10.0207 4.4707 10.0207ZM5.9707 6.05698C5.9707 6.61048 5.52299 7.05918 4.9707 7.05918 4.41842 7.05918 3.9707 6.61048 3.9707 6.05698 3.9707 5.50348 4.41842 5.05479 4.9707 5.05479 5.52299 5.05479 5.9707 5.50348 5.9707 6.05698Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M8.50195 3.00244C7.67353 3.00244 7.00195 3.67548 7.00195 4.50573C7.00195 5.33598 7.67353 6.00903 8.50195 6.00903C9.33038 6.00903 10.002 5.33598 10.002 4.50573C10.002 3.67548 9.33038 3.00244 8.50195 3.00244ZM8.00195 4.50573C8.00195 4.22898 8.22581 4.00463 8.50195 4.00463C8.7781 4.00463 9.00195 4.22898 9.00195 4.50573C9.00195 4.78248 8.7781 5.00683 8.50195 5.00683C8.22581 5.00683 8.00195 4.78248 8.00195 4.50573Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M8.22108 11.8963C9.27735 12.7248 10.7599 12.9821 12.0611 12.5829C13.6947 12.0818 15 10.5719 15 8.01538C15 4.14089 11.866 1 8 1C4.13401 1 1 4.14089 1 8.01538C1 11.0201 3.0498 13.8489 5.72517 14.9052C6.52899 15.2225 7.31424 14.7001 7.54119 13.9781C7.65599 13.6129 7.78475 13.2082 7.88343 12.9115L8.22108 11.8963ZM8 2.0022C4.68629 2.0022 2 4.69439 2 8.01538C2 10.5893 3.78048 13.0602 6.0917 13.9727C6.25759 14.0382 6.50119 13.9512 6.58739 13.677C6.70243 13.311 6.83328 12.8996 6.93475 12.5946L7.5254 10.8187C7.58343 10.6443 7.73243 10.5149 7.91315 10.4829C8.09387 10.451 8.27753 10.5208 8.39162 10.6649C9.1371 11.6061 10.5408 12.0012 11.7684 11.6246C12.9417 11.2647 14 10.1749 14 8.01538C14 4.69439 11.3137 2.0022 8 2.0022Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
