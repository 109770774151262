import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgCookie = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
          <path d="M6.92309 1.02361C3.56068 1.63203 1 4.52391 1 7.96635C1 11.8604 4.1223 14.9958 8 14.9958C11.8777 14.9958 15 11.8604 15 7.96635C15 7.94871 14.9941 7.92613 14.969 7.89978C14.9419 7.8713 14.8988 7.84671 14.848 7.83874C13.0191 7.55192 11.668 6.07466 11.5473 4.18736C11.1662 4.32826 10.7588 4.413 10.3077 4.413C8.41931 4.413 6.92804 2.91861 6.92309 1.02361ZM7.68647 0.29787C7.86791 0.512623 7.92308 0.784245 7.92308 1.01415C7.92308 2.35903 8.96845 3.4088 10.3077 3.4088C10.7094 3.4088 11.0675 3.31579 11.4259 3.15228C11.6684 3.0417 11.9776 3.02706 12.2316 3.22581C12.4644 3.40796 12.5385 3.68376 12.5385 3.9109C12.5385 5.43744 13.5749 6.62271 15.0023 6.84656C15.5119 6.92648 16 7.35261 16 7.96635C16 12.415 12.43 16 8 16C3.57001 16 0 12.415 0 7.96635C0 3.96385 3.00234 0.652681 6.87136 0.0135793C7.18197 -0.0377286 7.4823 0.0562151 7.68647 0.29787Z" />
          <path d="M5.11702 4.6167C4.20401 4.6167 3.46387 5.35684 3.46387 6.26985 3.46387 7.18286 4.20401 7.923 5.11702 7.923 6.03003 7.923 6.77017 7.18286 6.77017 6.26985 6.77017 5.35684 6.03003 4.6167 5.11702 4.6167ZM4.46387 6.26985C4.46387 5.90913 4.75629 5.6167 5.11702 5.6167 5.47775 5.6167 5.77017 5.90913 5.77017 6.26985 5.77017 6.63058 5.47775 6.923 5.11702 6.923 4.75629 6.923 4.46387 6.63058 4.46387 6.26985ZM8.57649 9.22998C7.66348 9.22998 6.92334 9.97012 6.92334 10.8831 6.92334 11.7961 7.66348 12.5363 8.57649 12.5363 9.4895 12.5363 10.2296 11.7961 10.2296 10.8831 10.2296 9.97012 9.4895 9.22998 8.57649 9.22998ZM7.92334 10.8831C7.92334 10.5224 8.21577 10.23 8.57649 10.23 8.93722 10.23 9.22964 10.5224 9.22964 10.8831 9.22964 11.2439 8.93722 11.5363 8.57649 11.5363 8.21577 11.5363 7.92334 11.2439 7.92334 10.8831Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
