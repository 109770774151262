import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgPuzzlePlus = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M4.5 8.97021C4.22386 8.97021 4 9.19407 4 9.47021C4 9.74636 4.22386 9.97021 4.5 9.97021H6.01123V11.5C6.01123 11.7761 6.23509 12 6.51123 12C6.78737 12 7.01123 11.7761 7.01123 11.5V9.97021H8.5C8.77614 9.97021 9 9.74636 9 9.47021C9 9.19407 8.77614 8.97021 8.5 8.97021H7.01123V7.5C7.01123 7.22386 6.78737 7 6.51123 7C6.23509 7 6.01123 7.22386 6.01123 7.5V8.97021H4.5Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M6.49743 1C5.6319 1 5.00495 1.36753 4.60286 1.84119C4.21696 2.29579 4.03429 2.85223 4.03429 3.27273V4.00861H1.5C1.22386 4.00861 1 4.23247 1 4.50861V10.9545C1 12.5357 1.67001 13.5722 2.53739 14.1967C3.37928 14.8028 4.37453 15 5.04545 15H11.5125C11.7886 15 12.0125 14.7761 12.0125 14.5V11.9936H12.7273C13.1531 11.9936 13.7103 11.8011 14.1624 11.41C14.6333 11.0025 15 10.3712 15 9.50642C15 8.64268 14.6343 8.00572 14.1667 7.59172C13.7177 7.19422 13.1597 6.98937 12.7273 6.98937H12.0125V4.50861C12.0125 4.23247 11.7886 4.00861 11.5125 4.00861H9.0246V3.27273C9.0246 2.83807 8.81554 2.27978 8.41592 1.83189C7.9997 1.36541 7.36085 1 6.49743 1ZM5.03429 3.27273C5.03429 3.10232 5.12409 2.7724 5.36522 2.48835C5.59016 2.22337 5.94479 2 6.49743 2C7.0522 2 7.42692 2.2255 7.66975 2.49765C7.92916 2.7884 8.02459 3.11648 8.02459 3.27273V4.50861C8.02459 4.78476 8.24845 5.00861 8.52459 5.00861H11.0125V7.48937C11.0125 7.76551 11.2363 7.98937 11.5125 7.98937H12.7273C12.8857 7.98937 13.2141 8.08395 13.5038 8.34043C13.7748 8.58042 14 8.95199 14 9.50642C14 10.0599 13.7758 10.4221 13.5081 10.6537C13.2215 10.9016 12.8923 10.9936 12.7273 10.9936H11.5125C11.2363 10.9936 11.0125 11.2175 11.0125 11.4936V14H5.04545C4.53456 14 3.75708 13.8426 3.1217 13.3851C2.51181 12.946 2 12.2098 2 10.9545V5.00861H4.53429C4.81043 5.00861 5.03429 4.78476 5.03429 4.50861V3.27273Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
