import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLListImgArticle = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M2 2.5C2 2.22386 2.22386 2 2.5 2H5.5C5.77614 2 6 2.22386 6 2.5V5.5C6 5.77614 5.77614 6 5.5 6H2.5C2.22386 6 2 5.77614 2 5.5V2.5ZM3 3V5H5V3H3ZM2 10.5C2 10.2239 2.22386 10 2.5 10H5.5C5.77614 10 6 10.2239 6 10.5V13.5C6 13.7761 5.77614 14 5.5 14H2.5C2.22386 14 2 13.7761 2 13.5V10.5ZM3 11V13H5V11H3Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M7.5 5C7.22386 5 7 5.22386 7 5.5 7 5.77614 7.22386 6 7.5 6H13.5C13.7761 6 14 5.77614 14 5.5 14 5.22386 13.7761 5 13.5 5H7.5ZM7 13.5C7 13.2239 7.22386 13 7.5 13H10.5C10.7761 13 11 13.2239 11 13.5 11 13.7761 10.7761 14 10.5 14H7.5C7.22386 14 7 13.7761 7 13.5ZM7.5 7C7.22386 7 7 7.22386 7 7.5 7 7.77614 7.22386 8 7.5 8H10.5C10.7761 8 11 7.77614 11 7.5 11 7.22386 10.7761 7 10.5 7H7.5ZM12.4907 2.00488H7.49072C7.21458 2.00488 6.99072 2.22874 6.99072 2.50488V3.50488C6.99072 3.78103 7.21458 4.00488 7.49072 4.00488H12.4907C12.7669 4.00488 12.9907 3.78103 12.9907 3.50488V2.50488C12.9907 2.22874 12.7669 2.00488 12.4907 2.00488ZM7.51025 10.0039H12.5103C12.7864 10.0039 13.0103 10.2278 13.0103 10.5039V11.5039C13.0103 11.78 12.7864 12.0039 12.5103 12.0039H7.51025C7.23411 12.0039 7.01025 11.78 7.01025 11.5039V10.5039C7.01025 10.2278 7.23411 10.0039 7.51025 10.0039Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
