import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLMenuList = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M6.99747 7.50341C6.99747 7.22733 7.2214 7.00353 7.49762 7.00353H11.4989C11.7751 7.00353 11.999 7.22733 11.999 7.50341C11.999 7.77949 11.7751 8.0033 11.4989 8.0033H7.49762C7.2214 8.0033 6.99747 7.77949 6.99747 7.50341Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M6.99309 1.49989C6.99309 1.22381 7.21702 1 7.49325 1H13.4951C13.7714 1 13.9953 1.22381 13.9953 1.49989V3.49944C13.9953 3.77552 13.7714 3.99932 13.4951 3.99932H7.49325C7.21702 3.99932 6.99309 3.77552 6.99309 3.49944V1.49989ZM1 1.50086C1 1.22478 1.22393 1.00098 1.50016 1.00098H5.50141C5.77764 1.00098 6.00157 1.22478 6.00157 1.50086 6.00157 1.77694 5.77764 2.00075 5.50141 2.00075H1.50016C1.22393 2.00075 1 1.77694 1 1.50086ZM1 3.49944C1 3.22336 1.22393 2.99955 1.50016 2.99955H5.50141C5.77764 2.99955 6.00157 3.22336 6.00157 3.49944 6.00157 3.77552 5.77764 3.99932 5.50141 3.99932H1.50016C1.22393 3.99932 1 3.77552 1 3.49944ZM1.50016 4.99617C1.22393 4.99617 1 5.21998 1 5.49606 1 5.77214 1.22393 5.99594 1.50016 5.99594H5.50141C5.77764 5.99594 6.00157 5.77214 6.00157 5.49606 6.00157 5.21998 5.77764 4.99617 5.50141 4.99617H1.50016ZM6.99749 5.49606C6.99749 5.21998 7.22142 4.99617 7.49764 4.99617H14.4998C14.7761 4.99617 15 5.21998 15 5.49606 15 5.77214 14.7761 5.99594 14.4998 5.99594H7.49764C7.22142 5.99594 6.99749 5.77214 6.99749 5.49606ZM7.49667 13.0002C7.22044 13.0002 6.99651 13.224 6.99651 13.5001 6.99651 13.7762 7.22044 14 7.49667 14H11.4979C11.7742 14 11.9981 13.7762 11.9981 13.5001 11.9981 13.224 11.7742 13.0002 11.4979 13.0002H7.49667ZM7.49325 9.99211C7.21702 9.99211 6.99309 10.2159 6.99309 10.492V11.4918C6.99309 11.7679 7.21702 11.9917 7.49325 11.9917H13.4951C13.7714 11.9917 13.9953 11.7679 13.9953 11.4918V10.492C13.9953 10.2159 13.7714 9.99211 13.4951 9.99211H7.49325Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
