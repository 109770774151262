import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgArrowCurvedLeft = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M9.12756 2.99768C5.4768 2.99768 1.98288 5.86856 1.98288 9.45439V10.2951L0.851802 9.1772C0.654767 8.98247 0.337806 8.98497 0.14385 9.1828C-0.0501061 9.38062 -0.0476104 9.69885 0.149424 9.89358L2.13226 11.8533C2.3271 12.0458 2.63979 12.0458 2.83464 11.8533L4.81747 9.89358C5.01451 9.69885 5.017 9.38062 4.82305 9.1828C4.62909 8.98497 4.31213 8.98247 4.1151 9.1772L2.9841 10.295V9.45439C2.9841 6.54484 5.90088 4.00292 9.12756 4.00292C12.3262 4.00292 14.9988 6.51947 14.9988 9.45439V10.2426C14.9988 10.5202 15.2229 10.7453 15.4994 10.7453C15.7759 10.7453 16 10.5202 16 10.2426V9.45439C16 5.89393 12.8064 2.99768 9.12756 2.99768Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
