import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLGrid22 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.52231 1C1.24651 1 1.02292 1.22395 1.02292 1.5002V6.49999C1.02292 6.77624 1.24651 7.00019 1.52231 7.00019H6.51622C6.79202 7.00019 7.01561 6.77624 7.01561 6.49999V1.5002C7.01561 1.22395 6.79202 1 6.51622 1H1.52231ZM2.0217 5.99979V2.0004H6.01683V5.99979H2.0217ZM1.49939 8.99733C1.22358 8.99733 1 9.22127 1 9.49753V14.4998C1 14.7761 1.22358 15 1.49939 15H6.48836C6.76416 15 6.98775 14.7761 6.98775 14.4998V9.49753C6.98775 9.22127 6.76416 8.99733 6.48836 8.99733H1.49939ZM1.99878 13.9996V9.99773H5.98897V13.9996H1.99878ZM9.00732 1.5002C9.00732 1.22395 9.2309 1 9.50671 1H14.5006C14.7764 1 15 1.22395 15 1.5002V6.49999C15 6.77624 14.7764 7.00019 14.5006 7.00019H9.50671C9.2309 7.00019 9.00732 6.77624 9.00732 6.49999V1.5002ZM10.0061 2.0004V5.99979H14.0012V2.0004H10.0061ZM9.48378 8.99733C9.20798 8.99733 8.98439 9.22127 8.98439 9.49753V14.4998C8.98439 14.7761 9.20798 15 9.48378 15H14.4777C14.7535 15 14.9771 14.7761 14.9771 14.4998V9.49753C14.9771 9.22127 14.7535 8.99733 14.4777 8.99733H9.48378ZM9.98318 13.9996V9.99773H13.9783V13.9996H9.98318Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
