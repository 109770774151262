import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgClapperboard = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M15 1.5C15 1.34762 14.9305 1.20356 14.8113 1.1087C14.692 1.01384 14.536 0.978541 14.3875 1.01282L1.38752 4.01429C1.16068 4.06666 1 4.26866 1 4.50147V10.4773L1.0001 10.4875L1 10.4978V14.4998C1 14.7759 1.22386 14.9998 1.5 14.9998H14.5C14.7761 14.9998 15 14.7759 15 14.4998V10.4978L14.9999 10.4875L15 10.4773V7.47582C15 7.19967 14.7761 6.97582 14.5 6.97582H6.00564L14.6125 4.98865C14.8393 4.93628 15 4.73428 15 4.50147V1.5ZM2 6.87434V4.89918L14 2.1286V4.10376L2 6.87434ZM2 7.97582V9.97728H14V7.97582H2ZM2 10.9978V13.9998H14V10.9978H2Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
