import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgAndroid = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M12.02 9.04846C12.02 9.60075 11.5723 10.0485 11.02 10.0485 10.4677 10.0485 10.02 9.60075 10.02 9.04846 10.02 8.49618 10.4677 8.04846 11.02 8.04846 11.5723 8.04846 12.02 8.49618 12.02 9.04846ZM5.00342 10.0485C5.5557 10.0485 6.00342 9.60075 6.00342 9.04846 6.00342 8.49618 5.5557 8.04846 5.00342 8.04846 4.45113 8.04846 4.00342 8.49618 4.00342 9.04846 4.00342 9.60075 4.45113 10.0485 5.00342 10.0485Z" />
          <path
            fillRule="evenodd"
            d="M12.5432 3.01206C12.678 2.98778 12.8168 3.00031 12.9451 3.04822C13.0733 3.09613 13.1859 3.1775 13.2717 3.28337C13.3574 3.38923 13.4132 3.51584 13.4339 3.65C13.4545 3.78411 13.4393 3.92146 13.3897 4.04797C13.3808 4.07058 13.3703 4.09252 13.3582 4.1136L12.3543 5.86816C13.3166 6.48134 14.1371 7.29899 14.7576 8.26525C15.4881 9.4028 15.8998 11.1848 15.9985 12.4615C16.0093 12.6004 15.9616 12.7374 15.8669 12.8397C15.7723 12.9419 15.6393 13 15.5 13H0.500001C0.360726 13 0.227763 12.9419 0.133134 12.8397C0.0385058 12.7375 -0.00921126 12.6005 0.00147553 12.4616C0.0997196 11.185 0.511017 9.40316 1.24109 8.26558C1.86121 7.29934 2.6813 6.48215 3.64308 5.86904L2.63974 4.11916C2.62755 4.0979 2.61694 4.07577 2.60799 4.05296C2.54161 3.88364 2.53745 3.69619 2.5962 3.52412C2.65497 3.352 2.77319 3.20564 2.93072 3.11239C3.08835 3.01909 3.2744 2.98551 3.45504 3.0183C3.63562 3.05108 3.79765 3.14779 3.91238 3.28971C3.92919 3.3105 3.9443 3.3326 3.95758 3.35581L5.00225 5.18232C5.95718 4.81172 6.97291 4.62091 7.99886 4.62091C9.02466 4.62091 10.0402 4.81167 10.9951 5.18216L12.0399 3.35108C12.0526 3.32887 12.0669 3.30766 12.0829 3.28764C12.1972 3.14393 12.3598 3.04573 12.5415 3.01237L12.5432 3.01206ZM11.2195 6.37699C11.1314 6.3329 11.0842 6.30644 11.0037 6.27C10.0585 5.84207 9.03448 5.62091 7.99886 5.62091C6.96325 5.62091 5.93922 5.84207 4.99405 6.27C4.91368 6.30639 4.83252 6.34143 4.76611 6.38049C4.69971 6.41956 4.63818 6.44427 4.56274 6.48575C3.55792 7.03824 2.7053 7.83556 2.08268 8.8057C1.56509 9.61219 1.20666 10.8977 1.05647 12H14.9433C14.7928 10.8975 14.434 9.61206 13.9161 8.8056C13.2931 7.83535 12.4401 7.03755 11.4351 6.48522C11.3598 6.44385 11.3073 6.42091 11.2195 6.37699Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
