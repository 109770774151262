import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLMenuCols2 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M6.00204 1.50028C6.00204 1.22398 6.2259 1 6.50205 1H9.50039C9.77654 1 10.0004 1.22398 10.0004 1.50028V14.5036C10.0004 14.7799 9.77654 15.0039 9.50039 15.0039H6.50205C6.2259 15.0039 6.00204 14.7799 6.00204 14.5036V1.50028ZM7.00206 2.00056V14.0033H9.00038V2.00056H7.00206ZM10.9977 1.50028C10.9977 1.22398 11.2216 1 11.4977 1H14.5C14.7761 1 15 1.22398 15 1.50028V14.5036C15 14.7799 14.7761 15.0039 14.5 15.0039H11.4977C11.2216 15.0039 10.9977 14.7799 10.9977 14.5036V1.50028ZM11.9977 2.00056V14.0033H14V2.00056H11.9977Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M1 1.50516C1 1.22887 1.22386 1.00489 1.50001 1.00489H3.49679C3.77294 1.00489 3.9968 1.22887 3.9968 1.50516 3.9968 1.78146 3.77294 2.00544 3.49679 2.00544L1.50001 2.00544C1.22386 2.00544 1 1.78146 1 1.50516ZM1 3.50628C1 3.22998 1.22386 3.006 1.50001 3.006L3.49679 3.006C3.77294 3.006 3.9968 3.22998 3.9968 3.50628 3.9968 3.78258 3.77294 4.00656 3.49679 4.00656L1.50001 4.00656C1.22386 4.00656 1 3.78258 1 3.50628ZM1 5.50447C1 5.22817 1.22386 5.00419 1.50001 5.00419H3.49679C3.77294 5.00419 3.9968 5.22817 3.9968 5.50447 3.9968 5.78076 3.77294 6.00475 3.49679 6.00475H1.50001C1.22386 6.00475 1 5.78076 1 5.50447Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
