import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgChevronDoubleUp = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M14.7819 12.0852C15.01 12.2412 15.0687 12.5528 14.9131 12.7814 14.7574 13.0099 14.4463 13.0687 14.2182 12.9128L8.00005 8.66188 1.78186 12.9128C1.55376 13.0687 1.24268 13.0099 1.08704 12.7814.931393 12.5528.990131 12.2412 1.21823 12.0852L7.71823 7.64164C7.88821 7.52543 8.11189 7.52543 8.28186 7.64164L14.7819 12.0852ZM14.7819 6.53073C15.01 6.68667 15.0687 6.99834 14.9131 7.22687 14.7574 7.4554 14.4463 7.51425 14.2182 7.35832L8.00005 3.10739 1.78186 7.35832C1.55376 7.51425 1.24268 7.4554 1.08704 7.22687.931393 6.99834.990131 6.68667 1.21823 6.53073L7.71823 2.08715C7.88821 1.97095 8.11189 1.97095 8.28186 2.08715L14.7819 6.53073Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
