import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgCbCheckInverted = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M2.99951 1C1.89494 1 0.999512 1.89543 0.999512 3V13.0009C0.999512 14.1054 1.89494 15.0009 2.99951 15.0009H13.0004C14.105 15.0009 15.0004 14.1054 15.0004 13.0009V3C15.0004 1.89543 14.105 1 13.0004 1H2.99951ZM11.8721 5.83473C12.0568 5.62948 12.0401 5.31333 11.8349 5.1286C11.6296 4.94387 11.3135 4.96051 11.1288 5.16577L7.03527 9.71409L5.90003 8.20044C5.73434 7.97952 5.42094 7.93475 5.20003 8.10044C4.97912 8.26612 4.93434 8.57952 5.10003 8.80044L6.60012 10.8006C6.68985 10.9202 6.82841 10.9934 6.9778 11.0001C7.12719 11.0067 7.27173 10.9462 7.37177 10.835L11.8721 5.83473Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
