import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgMagnifierPlus = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M6.99873 9.50422C6.99873 9.78031 7.22254 10.0041 7.49862 10.0041C7.77471 10.0041 7.99852 9.78031 7.99852 9.50422V8.00158H9.49527C9.77135 8.00158 9.99516 7.77777 9.99516 7.50168C9.99516 7.22559 9.77135 7.00178 9.49527 7.00178H7.99852V5.50499C7.99852 5.2289 7.77471 5.00509 7.49862 5.00509C7.22254 5.00509 6.99873 5.2289 6.99873 5.50499V7.00178H5.49612C5.22003 7.00178 4.99622 7.22559 4.99622 7.50168C4.99622 7.77777 5.22003 8.00158 5.49612 8.00158H6.99873V9.50422Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M11.7271 12.4338C10.5905 13.4086 9.11335 13.9975 7.49862 13.9975C3.90953 13.9975 1 11.0879 1 7.49875C1 3.90959 3.90953 1 7.49862 1C11.0877 1 13.9972 3.90959 13.9972 7.49875C13.9972 9.11327 13.4085 10.5903 12.434 11.7268L14.8536 14.1466C15.0488 14.3419 15.0488 14.6584 14.8536 14.8536C14.6583 15.0488 14.3418 15.0488 14.1466 14.8536L11.7271 12.4338ZM7.49862 12.9977C10.5355 12.9977 12.9975 10.5357 12.9975 7.49875C12.9975 4.46177 10.5355 1.99981 7.49862 1.99981C4.4617 1.99981 1.99979 4.46177 1.99979 7.49875C1.99979 10.5357 4.4617 12.9977 7.49862 12.9977Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
