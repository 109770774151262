import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgCustomElement = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M10.8544 5.74648L11.7229 4.90126L10.9373 4.58752C10.8048 4.27004 10.606 3.95256 10.3741 3.66683L10.5729 2.04768L10.4404 1.95244C9.97666 1.60321 9.4798 1.31748 8.91669 1.09524L8.75106 1L7.35985 1.95244C6.99548 1.92069 6.59799 1.92069 6.23362 1.95244L4.84241 1L4.67679 1.0635C4.1468 1.25398 3.64994 1.53972 3.1862 1.92069L3.02058 2.01594L3.21932 3.63508C2.98745 3.92082 2.78871 4.2383 2.65621 4.58752L1.09937 5.25423L1.06625 5.41297C1.03312 5.66696 1 5.95269 1 6.23842C1 6.52415 1.03312 6.80988 1.06625 7.09562L1.09937 7.25436L2.65621 7.92106C2.78871 8.23854 2.98745 8.55602 3.21932 8.84176L3.02058 10.4609L3.15307 10.5561C3.61681 10.9054 4.11368 11.1911 4.67679 11.4133L4.84241 11.4768L5.25983 11.1911L7.05991 9.43924C6.819 9.45062 6.56685 9.4462 6.33468 9.42597C6.06769 9.40271 5.80087 9.47231 5.58163 9.6224L4.71114 10.2183C4.54318 10.1358 4.3818 10.0451 4.22504 9.94594L4.34452 8.97257C4.38026 8.6814 4.29537 8.38838 4.10863 8.15825C3.93078 7.93909 3.79155 7.71078 3.70611 7.50607C3.59523 7.24037 3.38235 7.02715 3.11263 6.91165L2.1436 6.49667C2.13697 6.40652 2.1332 6.32166 2.1332 6.23842C2.1332 6.16521 2.13618 6.09012 2.14143 6.01285L3.11263 5.59694C3.39388 5.47649 3.61282 5.25005 3.71926 4.96951C3.80178 4.75199 3.93322 4.53474 4.10863 4.31859C4.29537 4.08846 4.38026 3.79544 4.34452 3.50427L4.22519 2.53211C4.38221 2.42497 4.54152 2.33223 4.7032 2.25306L5.58163 2.85444C5.80087 3.00454 6.06769 3.07414 6.33468 3.05087C6.63181 3.02498 6.96166 3.02498 7.2588 3.05087C7.52578 3.07414 7.7926 3.00454 8.01185 2.85444L8.85526 2.27703C9.03297 2.36313 9.20326 2.45819 9.36843 2.56265L9.24895 3.53602C9.21321 3.82718 9.2981 4.12021 9.48484 4.35034C9.66269 4.5695 9.80193 4.79781 9.88736 5.00252C10.002 5.27712 10.2253 5.49527 10.5071 5.60779L10.8544 5.74648Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M8.45295 6.23842C8.45295 7.12868 7.71152 7.85024 6.79674 7.85024 5.88196 7.85024 5.14053 7.12868 5.14053 6.23842 5.14053 5.34816 5.88196 4.6266 6.79674 4.6266 7.71152 4.6266 8.45295 5.34816 8.45295 6.23842ZM7.31975 6.23842C7.31975 6.51961 7.08567 6.74742 6.79674 6.74742 6.5078 6.74742 6.27372 6.51961 6.27372 6.23842 6.27372 5.95723 6.5078 5.72942 6.79674 5.72942 7.08567 5.72942 7.31975 5.95723 7.31975 6.23842ZM6.03559 15V11.4461L11.7869 5.75317C12.5723 4.97569 13.8601 4.97569 14.6455 5.75317L15.4249 6.5246C16.1917 7.28364 16.1917 8.5008 15.4249 9.25984L9.6259 15H6.03559ZM9.20577 14.0246 12.6108 10.6542 10.4259 8.49154 7.03781 11.8453V14.0246H9.20577ZM14.7102 8.57603 13.3135 9.95859 11.1287 7.79592 12.5015 6.43698C12.8943 6.04824 13.5382 6.04824 13.9309 6.43698L14.7102 7.20841C15.0936 7.58793 15.0936 8.19651 14.7102 8.57603Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
