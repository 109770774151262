import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgMapMarker = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M10.5557 6.51367C10.5557 7.89438 9.43638 9.01367 8.05566 9.01367C6.67495 9.01367 5.55566 7.89438 5.55566 6.51367C5.55566 5.13296 6.67495 4.01367 8.05566 4.01367C9.43638 4.01367 10.5557 5.13296 10.5557 6.51367ZM9.55566 6.51367C9.55566 7.3421 8.88409 8.01367 8.05566 8.01367C7.22724 8.01367 6.55566 7.3421 6.55566 6.51367C6.55566 5.68524 7.22724 5.01367 8.05566 5.01367C8.88409 5.01367 9.55566 5.68524 9.55566 6.51367Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.72294 15.9162C7.72314 15.9164 7.72332 15.9165 8 15.5L7.72294 15.9162C7.89058 16.0276 8.10904 16.0278 8.27668 15.9165L8 15.5C8.27668 15.9165 8.27648 15.9166 8.27668 15.9165L8.27756 15.9159L8.27895 15.915L8.28321 15.9121L8.29761 15.9023C8.30976 15.894 8.32699 15.8822 8.34895 15.8668C8.39286 15.836 8.45573 15.791 8.53485 15.7321C8.69304 15.6143 8.91647 15.4408 9.18338 15.2137C9.71662 14.7601 10.4266 14.0898 11.1372 13.2202C12.5556 11.4844 14 8.92394 14 5.69231C14 3.99685 13.4165 2.56052 12.346 1.54988C11.2784 0.541999 9.77184 0 7.99996 0C6.22808 0 4.72157 0.541999 3.65396 1.54989C2.58344 2.56053 2 3.99686 2 5.69231C2 8.92394 3.44444 11.4844 4.86282 13.2202C5.57337 14.0898 6.28338 14.7601 6.81662 15.2137C7.08353 15.4408 7.30696 15.6143 7.46515 15.7321C7.54427 15.791 7.60714 15.836 7.65105 15.8668C7.67301 15.8822 7.69024 15.894 7.70239 15.9023L7.71679 15.9121L7.72105 15.915L7.72294 15.9162ZM3 5.69231C3 4.23391 3.49617 3.07409 4.34044 2.27704C5.18764 1.47723 6.4311 1 7.99996 1C9.56882 1 10.8123 1.47723 11.6595 2.27704C12.5038 3.07409 13 4.23392 13 5.69231C13 8.61452 11.6944 10.9579 10.3628 12.5875C9.69837 13.4006 9.03338 14.0284 8.53537 14.4521C8.32076 14.6347 8.13776 14.7789 8 14.8832C7.86224 14.7789 7.67924 14.6347 7.46463 14.4521C6.96662 14.0284 6.30163 13.4006 5.63718 12.5875C4.30556 10.9579 3 8.61452 3 5.69231Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
