import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgArrowsV = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            d="M4.14609 4.15705C3.95103 4.3525 3.95134 4.66909 4.1468 4.86415C4.34226 5.05922 4.65884 5.0589 4.85391 4.86345L8.00076 1.71028L8.00076 14.2912L4.89728 11.1815C4.70221 10.9861 4.38563 10.9858 4.19017 11.1808C3.99471 11.3759 3.9944 11.6925 4.18946 11.8879L8.14679 15.8532C8.24058 15.9472 8.36791 16 8.50069 16C8.63348 16 8.76081 15.9472 8.8546 15.8532L12.8119 11.8879C13.007 11.6925 13.0067 11.3759 12.8112 11.1808C12.6158 10.9858 12.2992 10.9861 12.1041 11.1815L9.00076 14.2911L9.00076 1.7098L12.1481 4.86345C12.3432 5.0589 12.6597 5.05922 12.8552 4.86415C13.0507 4.66909 13.051 4.3525 12.8559 4.15705L8.85491 0.148022C8.76111 0.0540371 8.63378 0.0012207 8.501 0.0012207C8.36822 0.00122071 8.24089 0.0540371 8.14709 0.148022L4.14609 4.15705Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" transform="rotate(-90 8 8)" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
