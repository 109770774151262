import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgFilter1 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M1.17724 0.882051C1.06884 0.790333 1 0.653224 1 0.500021C1 0.223879 1.22365 2.14808e-05 1.49953 2.14808e-05H1.50772C1.51081 -7.16882e-06 1.51391 -7.15174e-06 1.517 2.14808e-05H14.4873C14.4893 2.14808e-05 14.4913 3.34162e-05 14.4933 5.72083e-05C14.6045 -0.00159783 14.7169 0.0336891 14.8108 0.108202C15.027 0.279746 15.0633 0.594234 14.8919 0.81063L9.96062 7.03711V12.9548C9.96062 13.1006 9.89709 13.2391 9.78664 13.3341L6.82737 15.8793C6.67933 16.0066 6.47075 16.0359 6.29337 15.9544C6.11598 15.8729 6.00229 15.6954 6.00229 15.5V7.03574L1.17724 0.882051ZM2.53975 1.00002L4.10908 3.00149L11.8819 3.00149L13.467 1.00002H2.53975ZM11.0899 4.00149L4.89317 4.00149L6.89478 6.55427C6.96382 6.64233 7.00135 6.75103 7.00135 6.86296V14.4112L8.96156 12.7252V6.86296C8.96156 6.75019 8.99965 6.64073 9.06964 6.55236L11.0899 4.00149Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
