import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgCircleSlashed = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M13.9993 2.70778C15.2446 4.11814 16.0002 5.97099 16.0002 8.00033C16.0002 12.4185 12.4185 16.0002 8.00033 16.0002C5.97099 16.0002 4.11814 15.2446 2.70778 13.9993L1.3682 15.3389C1.17294 15.5342 0.856357 15.5342 0.661095 15.3389C0.465833 15.1436 0.465833 14.8271 0.661095 14.6318L2.00071 13.2922C0.755852 11.8819 0.000488281 10.0293 0.000488281 8.00033C0.000488281 3.58213 3.58213 0.000488281 8.00033 0.000488281C10.0293 0.000488281 11.8819 0.755852 13.2922 2.00071L14.6318 0.661095C14.8271 0.465833 15.1436 0.465833 15.3389 0.661095C15.5342 0.856357 15.5342 1.17294 15.3389 1.3682L13.9993 2.70778ZM15.0002 8.00033C15.0002 11.8662 11.8662 15.0002 8.00033 15.0002C6.24716 15.0002 4.64453 14.3557 3.41645 13.2907L13.2907 3.41645C14.3557 4.64453 15.0002 6.24716 15.0002 8.00033ZM2.7094 12.5835L12.5835 2.7094C11.3555 1.64475 9.75316 1.00049 8.00033 1.00049C4.13442 1.00049 1.00049 4.13442 1.00049 8.00033C1.00049 9.75316 1.64475 11.3555 2.7094 12.5835Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
