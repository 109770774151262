import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { getDataUiComponentAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { Badge, maxBadgeValue } from '../../Badge/Badge.tsx';
import {
  badgeBackgroundColorVariableName,
  badgeColorValueVariableName,
} from '../../Badge/cssVariableNames.ts';
import { defaultBadgeStyles, inverseBadgeStyles } from '../../Badge/styles.ts';
import { QuinaryButtonStyle } from './QuinaryButtonStyle.ts';

type WithValueProps = {
  readonly withValue: boolean;
};

const getBadgeSpacing = ({ withValue }: WithValueProps) => (withValue ? px(-Spacing.XS) : 0);

const StyledQuinaryButtonBadgePositioner = styled.div<WithValueProps>`
  position: absolute;
  top: ${getBadgeSpacing};
  right: ${getBadgeSpacing};
`;

type Props = Pick<ComponentProps<typeof Badge>, 'value'>;

export const getButtonBadgeVariables = ({
  buttonStyle,
}: { readonly buttonStyle: QuinaryButtonStyle }) => {
  const { color, backgroundColor } =
    buttonStyle === 'inverse' ? inverseBadgeStyles : defaultBadgeStyles;

  return {
    [badgeBackgroundColorVariableName]: backgroundColor,
    [badgeColorValueVariableName]: color,
  };
};

export const QuinaryButtonBadge = React.forwardRef<HTMLDivElement, Props>(
  ({ value, ...otherProps }, forwardedRef) => {
    const withValue = !!value && value <= maxBadgeValue;

    return (
      <StyledQuinaryButtonBadgePositioner
        withValue={withValue}
        {...getDataUiComponentAttribute(QuinaryButtonBadge)}
      >
        <Badge ref={forwardedRef} value={value} {...otherProps} />
      </StyledQuinaryButtonBadgePositioner>
    );
  },
);

QuinaryButtonBadge.displayName = 'QuinaryButtonBadge';
