import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLCols2080 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
          <path d="M.5 2C.223858 2 0 2.22386 0 2.5V13.5C0 13.7761.223858 14 .5 14H2.50069C2.77684 14 3.00069 13.7761 3.00069 13.5V2.5C3.00069 2.22386 2.77684 2 2.50069 2H.5ZM1 13V3H2.00069V13H1ZM4.50732 2C4.23118 2 4.00732 2.22386 4.00732 2.5V13.5C4.00732 13.7761 4.23118 14 4.50732 14H15.5026C15.7787 14 16.0026 13.7761 16.0026 13.5V2.5C16.0026 2.22386 15.7787 2 15.5026 2H4.50732ZM5.00732 13V3H15.0026V13H5.00732Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
