import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgArrowDownLine = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M8.50195 0C8.7781 0 9.00195.223725 9.00195.499703L9.00195 8.28796 10.6484 6.64249C10.8437 6.44735 11.1602 6.44735 11.3555 6.64249 11.5508 6.83764 11.5508 7.15403 11.3555 7.34918L8.85551 9.84769C8.66024 10.0428 8.34366 10.0428 8.1484 9.84769L5.6484 7.34918C5.45314 7.15403 5.45314 6.83764 5.6484 6.64249 5.84366 6.44735 6.16024 6.44735 6.35551 6.64249L8.00195 8.28796 8.00195.499703C8.00195.223724 8.22581 0 8.50195 0ZM2.00342 11.5027C2.00342 11.2267 1.77956 11.003 1.50342 11.003 1.22728 11.003 1.00342 11.2267 1.00342 11.5027V13.5015C1.00342 14.8814 2.12271 16 3.50342 16H13.5001C14.8808 16 16.0001 14.8814 16.0001 13.5015V11.5027C16.0001 11.2267 15.7762 11.003 15.5001 11.003 15.2239 11.003 15.0001 11.2267 15.0001 11.5027V13.5015C15.0001 14.3294 14.3285 15.0006 13.5001 15.0006H3.50342C2.67499 15.0006 2.00342 14.3294 2.00342 13.5015V11.5027Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
