import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgPlus = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M7.00293 14C7.00293 14.5523 7.45064 15 8.00293 15C8.55521 15 9.00293 14.5523 9.00293 14V9.02885H14C14.5523 9.02885 15 8.58114 15 8.02885C15 7.47657 14.5523 7.02885 14 7.02885H9.00293V2C9.00293 1.44771 8.55521 1 8.00293 1C7.45064 1 7.00293 1.44771 7.00293 2V7.02885H2C1.44772 7.02885 1 7.47657 1 8.02885C1 8.58114 1.44772 9.02885 2 9.02885H7.00293V14Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
