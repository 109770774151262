import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgBrandFacebook = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3 14.9825H7.36238C7.40494 14.9943 7.44978 15.0006 7.49609 15.0006C7.54241 15.0006 7.58725 14.9943 7.62981 14.9825H13C14.1046 14.9825 15 14.0871 15 12.9825V3C15 1.89543 14.1046 1 13 1H3C1.89543 1 1 1.89543 1 3V12.9825C1 14.0871 1.89543 14.9825 3 14.9825ZM13 2H3C2.44772 2 2 2.44772 2 3V12.9825C2 13.5348 2.44772 13.9825 3 13.9825H6.99609V9.00344H5.49609C5.21995 9.00344 4.99609 8.77958 4.99609 8.50344C4.99609 8.22729 5.21995 8.00344 5.49609 8.00344H6.99609V7.30636C6.99609 6.6346 7.15725 6.03808 7.56786 5.61093C7.98201 5.1801 8.56651 5.0078 9.22502 5.0078H9.49609C9.77224 5.0078 9.99609 5.23166 9.99609 5.5078C9.99609 5.78394 9.77224 6.0078 9.49609 6.0078H9.22502C8.73092 6.0078 8.45095 6.13526 8.28879 6.30395C8.12309 6.47632 7.99609 6.77908 7.99609 7.30636V8.00344H9.49609C9.77224 8.00344 9.99609 8.22729 9.99609 8.50344C9.99609 8.77958 9.77224 9.00344 9.49609 9.00344H7.99609V13.9825H13C13.5523 13.9825 14 13.5348 14 12.9825V3C14 2.44772 13.5523 2 13 2Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
