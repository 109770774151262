import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgTwoSquaresLine = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M6.99274 2.49854C6.99274 1.67092 7.66492 1 8.4941 1H13.4986C14.3278 1 15 1.67092 15 2.49854V7.49366C15 8.321 14.3285 8.9922 13.4991 8.9922H11.5066C11.2302 8.9922 11.0061 8.76856 11.0061 8.49268 11.0061 8.21681 11.2302 7.99317 11.5066 7.99317H13.4991C13.7752 7.99317 13.9991 7.76981 13.9991 7.49366V2.49854C13.9991 2.22266 13.775 1.99902 13.4986 1.99902H8.4941C8.21771 1.99902 7.99365 2.22266 7.99365 2.49854V4.50806C7.99365 4.78394 7.76959 5.00758 7.49319 5.00758 7.2168 5.00758 6.99274 4.78394 6.99274 4.50806V2.49854ZM2.14113 2.15216C2.33657 1.95709 2.65344 1.95709 2.84888 2.15216L13.8589 13.1414C14.0543 13.3365 14.0543 13.6528 13.8589 13.8478 13.6634 14.0429 13.3466 14.0429 13.1511 13.8478L2.14113 2.85857C1.94569 2.6635 1.94569 2.34723 2.14113 2.15216ZM7.5059 15C8.33508 15 9.00726 14.3862 9.00726 13.5586V11.5039C9.00726 11.228 8.7832 11.0044 8.50681 11.0044 8.23041 11.0044 8.00635 11.228 8.00635 11.5039V13.5586C8.00635 13.8789 7.95361 14.001 7.5059 14.001H2.50136C2.22497 14.001 2.00091 13.7773 2.00091 13.5015L2.00091 8.50634C2.00091 8.23047 2.22497 8.00683 2.50136 8.00683H4.41977C4.69616 8.00683 4.92022 7.78319 4.92022 7.50732 4.92022 7.23144 4.69616 7.0078 4.41977 7.0078H2.50136C1.67218 7.0078 1 7.67872 1 8.50634V13.5015C1 14.3291 1.67218 15 2.50136 15L7.5059 15Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
