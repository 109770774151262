import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgChevronUpSquare = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M11.8521 8.81246C12.0474 9.00772 12.0474 9.32431 11.8521 9.51957C11.6568 9.71483 11.3402 9.71483 11.145 9.51957L7.99978 6.37437L4.85459 9.51957C4.65932 9.71483 4.34274 9.71483 4.14748 9.51957C3.95222 9.32431 3.95222 9.00772 4.14748 8.81246L7.64623 5.31371C7.84149 5.11845 8.15807 5.11845 8.35334 5.31371L11.8521 8.81246Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.00244 3.00244C1.00244 1.89787 1.89787 1.00244 3.00244 1.00244H12.9974C14.102 1.00244 14.9974 1.89787 14.9974 3.00244V12.9974C14.9974 14.102 14.102 14.9974 12.9974 14.9974H3.00244C1.89787 14.9974 1.00244 14.102 1.00244 12.9974V3.00244ZM3.00244 2.00244H12.9974C13.5497 2.00244 13.9974 2.45016 13.9974 3.00244V12.9974C13.9974 13.5497 13.5497 13.9974 12.9974 13.9974H3.00244C2.45016 13.9974 2.00244 13.5497 2.00244 12.9974V3.00244C2.00244 2.45016 2.45016 2.00244 3.00244 2.00244Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
