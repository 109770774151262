import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgCrosshairO = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M8.99899 5.49756V1.49756C8.99899 1.22142 8.77513.997559 8.49899.997559 8.22284.997559 7.99899 1.22142 7.99899 1.49756L7.99899 5.49756C7.99899 5.7737 8.22285 5.99756 8.49899 5.99756 8.77513 5.99756 8.99899 5.7737 8.99899 5.49756ZM11.4999 8C11.2237 8 10.9999 8.22386 10.9999 8.5 10.9999 8.77614 11.2237 9 11.4999 9H15.4999C15.776 9 15.9999 8.77614 15.9999 8.5 15.9999 8.22386 15.776 8 15.4999 8H11.4999ZM1.50415 8.00003C1.22801 8.00003 1.00415 8.22389 1.00415 8.50003 1.00415 8.77617 1.22801 9.00003 1.50415 9.00003H5.50415C5.78029 9.00003 6.00415 8.77617 6.00415 8.50003 6.00415 8.22389 5.78029 8.00003 5.50415 8.00003H1.50415ZM8.99879 11.4954 8.99879 15.4954C8.99879 15.7716 8.77493 15.9954 8.49879 15.9954 8.22265 15.9954 7.99879 15.7716 7.99879 15.4954L7.99879 11.4954C7.99879 11.2193 8.22265 10.9954 8.49879 10.9954 8.77493 10.9954 8.99879 11.2193 8.99879 11.4954Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
