import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLHeaderListImg = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.5 0C1.22386 0 1 .22386 1 .500006V2.50003C1 2.77618 1.22386 3.00004 1.5 3.00004H14.5C14.7761 3.00004 15 2.77618 15 2.50003V.500006C15 .22386 14.7761 0 14.5 0H1.5ZM2 2.00002V1.00001H14V2.00002H2ZM1.5 3.99549C1.22386 3.99549 1 4.21935 1 4.49549V15.5C1 15.7761 1.22386 16 1.5 16H14.5C14.7761 16 15 15.7761 15 15.5V4.49549C15 4.21935 14.7761 3.99549 14.5 3.99549H1.5ZM4.99805 4.9955H2V7.98393H4.99805V4.9955ZM4.99805 8.98395H2V11.9856H4.99805V8.98395ZM5.99805 11.9856V8.98395H9.99805V11.9856H5.99805ZM4.99805 12.9856H2V15H4.99805V12.9856ZM5.99805 15V12.9856H9.99805V15H5.99805ZM10.998 15H14V12.9856H10.998V15ZM10.998 11.9856H14V8.98395H10.998V11.9856ZM10.998 7.98393H14V4.9955H10.998V7.98393ZM9.99805 4.9955H5.99805V7.98393H9.99805V4.9955Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
