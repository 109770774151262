import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgFactory = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1.51444 1.00342C1.23841 1.00342 1.01464 1.22713 1.01464 1.50309 1.01464 1.77904 1.23841 2.00275 1.51444 2.00275H7.51203C7.78807 2.00275 8.01183 1.77904 8.01183 1.50309 8.01183 1.22713 7.78807 1.00342 7.51203 1.00342H1.51444ZM6.0058 11.5013C6.0058 11.2254 6.22957 11.0017 6.5056 11.0017H7.5052C7.78123 11.0017 8.005 11.2254 8.005 11.5013 8.005 11.7773 7.78123 12.001 7.5052 12.001H6.5056C6.22957 12.001 6.0058 11.7773 6.0058 11.5013ZM9.50391 11.0017C9.22788 11.0017 9.00411 11.2254 9.00411 11.5013 9.00411 11.7773 9.22788 12.001 9.50391 12.001H12.5027C12.7787 12.001 13.0025 11.7773 13.0025 11.5013 13.0025 11.2254 12.7787 11.0017 12.5027 11.0017H9.50391Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.49984 3.01136C1.22381 3.01136 1.00004 3.23507 1.00004 3.51103L1.00003 6.48751C0.999686 6.49513 0.999512 6.50279 0.999512 6.5105C0.999512 6.5182 0.999686 6.52587 1.00003 6.53349L1 14.5037C0.999999 14.6362 1.05266 14.7633 1.14639 14.857C1.24012 14.9507 1.36724 15.0034 1.4998 15.0034H3.99273L3.99831 15.0034H14.4997C14.7757 15.0034 14.9995 14.7797 14.9995 14.5037V6.50904C14.9995 6.32628 14.8997 6.15811 14.7393 6.07051C14.5789 5.98291 14.3834 5.98986 14.2296 6.08863L11.8726 7.60232V6.50913C11.8726 6.3259 11.7723 6.15737 11.6112 6.06997C11.4501 5.98257 11.2541 5.99033 11.1004 6.09019L8.78242 7.59645V6.50913C8.78242 6.33382 8.69052 6.17133 8.54025 6.08096C8.38998 5.99059 8.20333 5.98557 8.04842 6.06772L5.00304 7.68267V3.51103C5.00304 3.23507 4.77928 3.01136 4.50325 3.01136H1.49984ZM1.9996 14.0041L1.99963 7.01017H4.00345V8.4322C3.99377 8.48804 3.99351 8.54574 4.00345 8.6028V14.0041H1.9996ZM1.99963 6.01083H4.00345V4.0107H1.99964L1.99963 6.01083ZM5.00304 8.81389L7.78282 7.33979V8.51717C7.78282 8.70041 7.88314 8.86894 8.04422 8.95634C8.20531 9.04374 8.40133 9.03598 8.555 8.93612L10.873 7.42985V8.51717C10.873 8.69993 10.9728 8.8681 11.1332 8.9557C11.2937 9.0433 11.4891 9.03635 11.6429 8.93757L13.9999 7.42389V14.0041H5.00304V8.81389Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
