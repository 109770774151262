import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgParagraphCenter = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1.5 3C1.22386 3 1 3.22386 1 3.5 1 3.77614 1.22386 4 1.5 4H14.5C14.7761 4 15 3.77614 15 3.5 15 3.22386 14.7761 3 14.5 3H1.5ZM1.5 6C1.22386 6 1 6.22386 1 6.5 1 6.77614 1.22386 7 1.5 7H14.5C14.7761 7 15 6.77614 15 6.5 15 6.22386 14.7761 6 14.5 6H1.5ZM1 9.5C1 9.22386 1.22386 9 1.5 9H14.5C14.7761 9 15 9.22386 15 9.5 15 9.77614 14.7761 10 14.5 10H1.5C1.22386 10 1 9.77614 1 9.5ZM1.5 12C1.22386 12 1 12.2239 1 12.5 1 12.7761 1.22386 13 1.5 13H5.5C5.77614 13 6 12.7761 6 12.5 6 12.2239 5.77614 12 5.5 12H1.5Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
