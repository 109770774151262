import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgBrandYoutube = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M6.77184 5.0677C6.61771 4.97137 6.42343 4.96626 6.26446 5.05438C6.10548 5.14249 6.00684 5.30994 6.00684 5.4917V10.4917C6.00684 10.6735 6.10547 10.8409 6.26445 10.929C6.42342 11.0171 6.61769 11.012 6.77183 10.9157L10.7718 8.4158C10.918 8.32443 11.0068 8.1642 11.0068 7.9918C11.0068 7.81941 10.918 7.65917 10.7718 7.5678L6.77184 5.0677ZM7.00684 9.58959V6.39384L9.56344 7.99178L7.00684 9.58959Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3.07424 1C1.93119 1 0.998047 1.93314 0.998047 3.07619V12.9274C0.998047 14.0704 1.93119 15.0036 3.07424 15.0036H12.9254C14.0685 15.0036 15.0016 14.0704 15.0016 12.9274V3.07619C15.0016 1.93314 14.0685 1 12.9254 1H3.07424ZM1.99805 3.07619C1.99805 2.48543 2.48347 2 3.07424 2H12.9254C13.5162 2 14.0016 2.48543 14.0016 3.07619V12.9274C14.0016 13.5181 13.5162 14.0036 12.9254 14.0036H3.07424C2.48347 14.0036 1.99805 13.5181 1.99805 12.9274V3.07619Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
