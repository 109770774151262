import { gridUnit } from './spacing.ts';

export const IconSize = {
  XS: 1.5 * gridUnit,
  S: 2 * gridUnit,
  M: 2.5 * gridUnit,
  L: 3 * gridUnit,
  XL: 4 * gridUnit,
  XXL: 6 * gridUnit,
  XXXL: 8 * gridUnit,
} as const;

export type IconSize = (typeof IconSize)[keyof typeof IconSize];
