import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgClockBack = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 17 17"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M5.04182 2.65482C3.50821 3.36996 2.35624 4.56892 1.68159 5.98588C1.67929 5.99071 1.67692 5.99549 1.67449 6.00021H3.5C3.77614 6.00021 4 6.22407 4 6.50021C4 6.77636 3.77614 7.00021 3.5 7.00021H0.5C0.223858 7.00021 0 6.77636 0 6.50021V3.50021C0 3.22407 0.223858 3.00021 0.5 3.00021C0.776142 3.00021 1 3.22407 1 3.50021V5.12519C1.79023 3.69466 3.025 2.49191 4.6192 1.74852C8.62352 -0.118728 13.3834 1.61371 15.2506 5.61803C17.1179 9.62235 15.3854 14.3822 11.3811 16.2494C7.37677 18.1167 2.61693 16.3842 0.749686 12.3799C0.632983 12.1297 0.741261 11.8322 0.991531 11.7155C1.2418 11.5988 1.53929 11.707 1.65599 11.9573C3.28983 15.4611 7.4547 16.977 10.9585 15.3431C14.4623 13.7093 15.9781 9.54443 14.3443 6.04065C12.7105 2.53687 8.5456 1.02099 5.04182 2.65482Z"
        />
        <path
          fill="currentColor"
          d="M7.5 6.00021C7.77614 6.00021 8 6.22407 8 6.50021V10.1398L10.6581 11.0259C10.9201 11.1132 11.0617 11.3964 10.9743 11.6583C10.887 11.9203 10.6039 12.0619 10.3419 11.9746L7.34189 10.9746C7.13771 10.9065 7 10.7154 7 10.5002V6.50021C7 6.22407 7.22386 6.00021 7.5 6.00021Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
