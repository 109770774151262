import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgChevronRight = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M5.72174 1.08422C5.95064 0.930884 6.2601 0.992759 6.41293 1.22242L11.4161 7.72218C11.528 7.89026 11.528 8.10937 11.4161 8.27745L6.41293 14.7777C6.2601 15.0073 5.95064 15.0692 5.72174 14.9159C5.49283 14.7626 5.43116 14.4521 5.58399 14.2224L10.4024 7.99981L5.58399 1.77769C5.43116 1.54803 5.49283 1.23755 5.72174 1.08422Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
