import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgParentChildrenScheme2 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.97413 1.5037C4.97413 1.22767 5.19741 1.00391 5.47284 1.00391H10.46C10.7354 1.00391 10.9587 1.22767 10.9587 1.5037V6.50161C10.9587 6.77763 10.7354 7.0014 10.46 7.0014H8.00147V8.01172H12.5091C12.7845 8.01172 13.0078 8.23548 13.0078 8.51151V10.006H14.5052C14.7806 10.006 15.0039 10.2298 15.0039 10.5058V14.5041C15.0039 14.7801 14.7806 15.0039 14.5052 15.0039H10.5155C10.2401 15.0039 10.0168 14.7801 10.0168 14.5041V10.5058C10.0168 10.2298 10.2401 10.006 10.5155 10.006H12.0104V9.0113H4.00391V10.006H5.49232C5.76775 10.006 5.99103 10.2298 5.99103 10.5058V14.5041C5.99103 14.7801 5.76775 15.0039 5.49232 15.0039H1.50262C1.22719 15.0039 1.00391 14.7801 1.00391 14.5041V10.5058C1.00391 10.2298 1.22719 10.006 1.50262 10.006H3.00648V8.51151C3.00648 8.23548 3.22976 8.01172 3.50519 8.01172H7.00405V7.0014H5.47284C5.19741 7.0014 4.97413 6.77763 4.97413 6.50161V1.5037ZM9.96126 6.00181V2.00349H5.97155V6.00181H9.96126ZM2.00133 11.0056H3.37677C3.41406 11.0056 3.45304 11.0056 3.49309 11.0056C3.53314 11.0056 3.54688 11.0056 3.6094 11.0056H4.99361V14.0043H2.00133V11.0056ZM12.4716 11.0056C12.4315 11.0056 12.3906 11.0056 12.3552 11.0056H11.0142V14.0043H14.0065V11.0056H12.5879C12.5273 11.0056 12.5116 11.0056 12.4716 11.0056Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
