import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgRocket = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M11.75 2.5C10.7832 2.5 10 3.28323 10 4.25C10 5.21677 10.7832 6 11.75 6C12.7168 6 13.5 5.21677 13.5 4.25C13.5 3.28323 12.7168 2.5 11.75 2.5ZM11 4.25C11 3.83552 11.3355 3.5 11.75 3.5C12.1645 3.5 12.5 3.83552 12.5 4.25C12.5 4.66448 12.1645 5 11.75 5C11.3355 5 11 4.66448 11 4.25Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M10 7C10 7.55228 9.55229 8 9 8C8.44772 8 8 7.55228 8 7C8 6.44772 8.44772 6 9 6C9.55229 6 10 6.44772 10 7Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M14.6781 0C11.094 0 8.46544 1.66094 6.6871 3.75854C6.02614 3.80428 5.02227 4.02949 4.05976 4.34678C3.50605 4.5293 2.94691 4.74847 2.45213 4.99455C1.96419 5.23723 1.50861 5.52079 1.18672 5.84242C0.746711 6.28179 0.357752 6.79729 0.0563965 7.37682L0.801579 8.00631C0.923613 7.91403 1.10209 7.85862 1.36611 7.86587C1.63263 7.87318 1.95071 7.94409 2.30472 8.06658C2.99129 8.30413 3.71605 8.70005 4.31025 9.03297L6.98011 11.7028C7.31283 12.297 7.70637 13.0187 7.94163 13.7019C8.06291 14.0541 8.13271 14.3703 8.13932 14.6353C8.14587 14.8976 8.09034 15.0757 7.99763 15.1982L8.62568 15.9443C9.20788 15.6436 9.72285 15.2536 10.1629 14.8136C10.8047 14.1724 11.1824 13.0828 11.4041 12.1003C11.6011 11.2272 11.6942 10.3473 11.7115 9.73929C14.0695 7.978 16 5.27302 16 1.4025L16 1.36563L16 1.36334C15.9987 1.07879 15.9876 0.787424 15.9648 0.489319L15.9318 0.0589071L15.5012 0.0287238C15.2217 0.00913501 14.9476 0 14.6781 0ZM14.6781 1C9.30985 1 6.25699 5.15019 5.18137 8.48988L7.5176 10.8261C10.9804 9.62956 15 6.69523 15 1.4025V1.36688C14.9994 1.24729 14.997 1.12652 14.9925 1.00455C14.887 1.00149 14.7822 1 14.6781 1ZM8.88713 13.3763C8.68442 12.7876 8.38494 12.1889 8.10813 11.677C8.94275 11.37 9.8144 10.9634 10.6611 10.448C10.6162 10.8834 10.541 11.3821 10.4286 11.8801C10.212 12.84 9.88169 13.6811 9.45603 14.1062C9.35097 14.2113 9.24104 14.3128 9.12685 14.4098C9.09428 14.0656 9.00331 13.7136 8.88713 13.3763ZM5.82646 4.90014C5.16221 5.89203 4.66498 6.92834 4.32532 7.89889C3.81496 7.62294 3.21839 7.32454 2.6317 7.12155C2.29174 7.00392 1.93693 6.91165 1.59038 6.87851C1.68725 6.76433 1.78843 6.65477 1.89329 6.55008C2.10389 6.33959 2.44916 6.11288 2.89745 5.88992C3.339 5.67032 3.85176 5.46827 4.37283 5.29651C4.88618 5.12728 5.3898 4.99316 5.82646 4.90014Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.1252 9.91808L3.82312 10.0323C1.97267 10.7322 1.3437 12.4083 1.25251 14.2268L1.22478 14.7798L1.77767 14.7512C2.7247 14.7023 3.59778 14.5136 4.32587 14.1038C5.0653 13.6876 5.62982 13.0571 5.9668 12.1792L6.08315 11.876L4.1252 9.91808ZM3.83539 13.2323C3.41584 13.4685 2.90346 13.6222 2.30169 13.7011C2.4597 12.476 2.93434 11.5732 3.88731 11.0944L4.90482 12.1119C4.65056 12.6167 4.28882 12.9771 3.83539 13.2323Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
