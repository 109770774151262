import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgPaperPlane = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M15.9366 1.25685C15.8444 1.09116 15.672 0.998382 15.4949 1.00003C15.4897 0.999972 15.4844 0.999996 15.4792 1.0001H0.507079C0.458227 0.999311 0.408969 1.00568 0.360933 1.01957C0.301496 1.0367 0.246606 1.06457 0.198501 1.10095C0.14091 1.14434 0.0950714 1.19829 0.0619957 1.25836C0.0287994 1.31839 0.00758347 1.38598 0.00157653 1.45789C-0.00355674 1.51799 0.00210958 1.5793 0.0192757 1.63873C0.0331129 1.68682 0.0539669 1.73193 0.0806568 1.77288L4.00824 8.01781L4.00869 12.5002C4.00871 12.6917 4.11813 12.8665 4.29042 12.95C4.4627 13.0336 4.66756 13.0113 4.81785 12.8926L6.32538 11.7021L8.2526 14.7664C8.3478 14.9178 8.51654 15.0068 8.69512 14.9997C8.87371 14.9927 9.03493 14.8907 9.11794 14.7323L15.8873 1.81618C16.0128 1.66213 16.0391 1.44106 15.9366 1.25685ZM1.40429 2.0001L4.5629 7.02236L13.5757 2.0001H1.40429ZM5.00787 11.4687L5.00769 9.60695L5.79008 10.851L5.00787 11.4687ZM5.09595 7.86991L6.82189 10.6142C6.83241 10.6253 6.84251 10.6369 6.85216 10.6492C6.8847 10.6904 6.9097 10.7352 6.92736 10.7819L8.63607 13.4988L14.2636 2.76139L5.09595 7.86991Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
