import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgEngageUsers = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M4.00134 1.07313C0.174994 3.28227 -1.13601 8.175 1.07313 12.0013C3.28227 15.8277 8.17499 17.1387 12.0013 14.9295C13.6758 13.9628 14.8693 12.4809 15.496 10.8002C15.5925 10.5415 15.4609 10.2535 15.2022 10.157C14.9434 10.0606 14.6555 10.1921 14.559 10.4508C14.0105 11.9218 12.9673 13.2172 11.5013 14.0635C8.15329 15.9965 3.87215 14.8494 1.93916 11.5013C0.00616133 8.15329 1.15329 3.87215 4.50134 1.93916C7.69637 0.0945028 11.7412 1.05487 13.7841 4.05617L12.1347 3.80693C11.8617 3.76566 11.6069 3.95356 11.5656 4.2266C11.5244 4.49964 11.7123 4.75444 11.9853 4.7957L14.7101 5.20746C14.9593 5.24513 15.1977 5.09135 15.266 4.8487L15.9764 2.32813C16.0513 2.06234 15.8966 1.78615 15.6308 1.71124C15.365 1.63634 15.0888 1.79108 15.0139 2.05687L14.6095 3.49162C12.2746 0.0623128 7.65247 -1.03485 4.00134 1.07313Z" />
          <path
            fillRule="evenodd"
            d="M9.81127 7.41087C10.5336 6.86306 11.0002 5.99553 11.0002 5.01904C11.0002 3.36219 9.65701 2.01904 8.00016 2.01904C6.3433 2.01904 5.00016 3.36219 5.00016 5.01904C5.00016 6.00739 5.47809 6.8841 6.21546 7.43068C5.72386 7.6823 5.31083 8.03734 4.98048 8.46207C4.31691 9.31524 4.00016 10.4291 4.00016 11.519C4.00016 11.7952 4.22401 12.019 4.50016 12.019C4.7763 12.019 5.00016 11.7952 5.00016 11.519C5.00016 10.609 5.26674 9.72285 5.76983 9.07601C6.26247 8.44262 6.99424 8.01904 8.00016 8.01904C9.02647 8.01904 9.75431 8.40327 10.2362 8.99988C10.7292 9.61034 11.0002 10.489 11.0002 11.519C11.0002 11.7952 11.224 12.019 11.5002 12.019C11.7763 12.019 12.0002 11.7952 12.0002 11.519C12.0002 10.3268 11.6877 9.20553 11.0141 8.37154C10.6879 7.9676 10.285 7.64155 9.81127 7.41087ZM8.00016 3.01904C6.89559 3.01904 6.00016 3.91447 6.00016 5.01904C6.00016 6.12361 6.89559 7.01904 8.00016 7.01904C9.10472 7.01904 10.0002 6.12361 10.0002 5.01904C10.0002 3.91447 9.10472 3.01904 8.00016 3.01904Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
