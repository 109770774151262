import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgPrinter = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M3.51318 11.9854C3.23704 11.9854 3.01318 12.2092 3.01318 12.4854C3.01318 12.7615 3.23704 12.9854 3.51318 12.9854H4.51318C4.78933 12.9854 5.01318 12.7615 5.01318 12.4854C5.01318 12.2092 4.78933 11.9854 4.51318 11.9854H3.51318Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3.51318 1.00293C3.23704 1.00293 3.01318 1.22679 3.01318 1.50293V6.99819H2.5C1.67157 6.99819 1 7.66976 1 8.49819V14.4982C1 14.7743 1.22386 14.9982 1.5 14.9982H14.4991C14.7753 14.9982 14.9991 14.7743 14.9991 14.4982V8.49819C14.9991 7.66976 14.3276 6.99819 13.4991 6.99819H13.0132V5.03695C13.0132 4.9031 12.9595 4.77485 12.8642 4.68089L9.27953 1.14687C9.18598 1.05464 9.05988 1.00293 8.9285 1.00293H3.51318ZM13.0132 7.99819V9.50293C13.0132 9.77907 12.7893 10.0029 12.5132 10.0029H3.51318C3.23704 10.0029 3.01318 9.77907 3.01318 9.50293V7.99819H2.5C2.22386 7.99819 2 8.22205 2 8.49819V13.9982H13.9991V8.49819C13.9991 8.22205 13.7753 7.99819 13.4991 7.99819H13.0132ZM4.01318 9.00293V2.00293H8.00195V4.48309C8.00195 5.31152 8.67353 5.98309 9.50195 5.98309H12.0132V9.00293H4.01318ZM9.00195 4.48309V2.27747L11.7464 4.98309H9.50195C9.22581 4.98309 9.00195 4.75923 9.00195 4.48309Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
