import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgKopilotAi = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="#6956CC"
          d="M16 7.88889C16 12.2458 12.6429 16 8.00498 16C3.32406 16 0 12.2458 0 7.88889C0 3.53198 3.58172 0 8 0C12.4183 0 16 3.53198 16 7.88889Z"
        />
        <path
          fill="#F3F3F3"
          d="M3.91649 14.9056C4.00563 12.725 5.8755 10.8889 8.10862 10.8889C10.2958 10.8889 12.0787 12.7062 12.2353 14.8235C11.0369 15.6575 9.5833 16 8.00498 16C6.51324 16 5.0761 15.6577 3.91649 14.9056Z"
        />
        <path
          fill="#DDE0E4"
          d="M4.94118 15.4132C5.03052 12.9156 6.45009 10.8889 8.11287 10.8889C9.75424 10.8889 11.1467 12.9177 11.267 15.3699C10.2961 15.8352 9.15832 16 8.0063 16C6.93625 16 5.85848 15.8181 4.94118 15.4132Z"
        />
        <path
          fill="#C2C7CF"
          d="M3.83101 7.51532C3.83101 5.20567 5.72972 3.33333 8.07191 3.33333C10.4141 3.33333 12.3128 5.20567 12.3128 7.51532V10.3218C12.3128 10.69 12.0101 10.9885 11.6367 10.9885H4.50707C4.13369 10.9885 3.83101 10.69 3.83101 10.3218V7.51532Z"
        />
        <path
          fill="#F3F3F3"
          d="M3.25594 3.90038H3.54346V6.73563H3.25594V3.90038ZM12.4565 3.9004H12.744V6.73565H12.4565V3.9004Z"
        />
        <path
          fill="#C2C7CF"
          d="M9.8689 10.9885C9.8689 11.3017 9.09654 11.5556 8.14379 11.5556C7.19104 11.5556 6.41868 11.3017 6.41868 10.9885C6.41868 10.6753 7.19104 10.4215 8.14379 10.4215C9.09654 10.4215 9.8689 10.6753 9.8689 10.9885Z"
        />
        <path
          fill="#DDE0E4"
          d="M3.68721 6.4521C4.24298 6.4521 4.69353 6.95986 4.69353 7.5862 4.69353 8.21255 4.24298 8.7203 3.68721 8.7203 3.13144 8.7203 2.6809 8.21255 2.6809 7.5862 2.6809 6.95986 3.13144 6.4521 3.68721 6.4521ZM12.3127 6.4521C12.8685 6.4521 13.3191 6.95986 13.3191 7.5862 13.3191 8.21255 12.8685 8.7203 12.3127 8.7203 11.757 8.7203 11.3064 8.21255 11.3064 7.5862 11.3064 6.95986 11.757 6.4521 12.3127 6.4521Z"
        />
        <path
          fill="#F3F3F3"
          d="M3.83101 7.52177C3.83101 5.20856 5.73265 3.33333 8.07844 3.33333C10.4242 3.33333 12.3259 5.20856 12.3259 7.52177V10.1992C12.3259 10.322 12.225 10.4215 12.1005 10.4215H4.05636C3.9319 10.4215 3.83101 10.322 3.83101 10.1992V7.52177Z"
        />
        <path
          fill="#DDE0E4"
          d="M4.81118 7.19958C4.81118 5.42018 6.27398 3.9777 8.07843 3.9777C9.88288 3.9777 11.3457 5.42018 11.3457 7.19958V9.55485C11.3457 9.67758 11.2448 9.77707 11.1203 9.77707H5.03653C4.91207 9.77707 4.81118 9.67758 4.81118 9.55485V7.19958Z"
        />
        <path
          fill="#175D4B"
          d="M4.81118 7.5218C4.81118 5.7424 6.27398 4.29992 8.07843 4.29992C9.88288 4.29992 11.3457 5.7424 11.3457 7.5218V9.55485C11.3457 9.67758 11.2448 9.77707 11.1203 9.77707H5.03653C4.91207 9.77707 4.81118 9.67758 4.81118 9.55485V7.5218Z"
        />
        <path
          fill="#71D9BA"
          d="M6.89643 7.55537C6.89643 8.15639 7.39051 8.65359 8 8.65359 8.60948 8.65359 9.10356 8.15639 9.10356 7.55537 9.10356 7.49467 9.05365 7.44444 8.99209 7.44444H7.0079C6.94634 7.44444 6.89643 7.49467 6.89643 7.55537ZM6.89643 6.47712C6.89643 6.74424 6.67684 6.96078 6.40596 6.96078 6.13508 6.96078 5.91549 6.74424 5.91549 6.47712 5.91549 6.21 6.13508 5.99346 6.40596 5.99346 6.67684 5.99346 6.89643 6.21 6.89643 6.47712ZM10.0845 6.47712C10.0845 6.74424 9.86491 6.96078 9.59403 6.96078 9.32315 6.96078 9.10356 6.74424 9.10356 6.47712 9.10356 6.21 9.32315 5.99346 9.59403 5.99346 9.86491 5.99346 10.0845 6.21 10.0845 6.47712Z"
        />
        <path
          fill="#DDE0E4"
          d="M3.83096 3.75862C3.83096 3.9935 3.63787 4.18391 3.39968 4.18391 3.16149 4.18391 2.9684 3.9935 2.9684 3.75862 2.9684 3.52374 3.16149 3.33333 3.39968 3.33333 3.63787 3.33333 3.83096 3.52374 3.83096 3.75862ZM13.0316 3.75862C13.0316 3.9935 12.8385 4.18391 12.6003 4.18391 12.3621 4.18391 12.169 3.9935 12.169 3.75862 12.169 3.52374 12.3621 3.33333 12.6003 3.33333 12.8385 3.33333 13.0316 3.52374 13.0316 3.75862Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
