import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgMobile = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M7.00439 3.50195C7.00439 3.22581 7.22825 3.00195 7.50439 3.00195H8.50439C8.78054 3.00195 9.00439 3.22581 9.00439 3.50195 9.00439 3.7781 8.78054 4.00195 8.50439 4.00195H7.50439C7.22825 4.00195 7.00439 3.7781 7.00439 3.50195ZM6.5 12.001C6.22386 12.001 6 12.2248 6 12.501 6 12.7771 6.22386 13.001 6.5 13.001H9.5C9.77614 13.001 10 12.7771 10 12.501 10 12.2248 9.77614 12.001 9.5 12.001H6.5Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M2 2.03846C2 0.889435 2.9847 0 4.12963 0H11.8704C13.0153 0 14 0.889435 14 2.03846V13.9615C14 15.1106 13.0153 16 11.8704 16H4.12963C2.9847 16 2 15.1106 2 13.9615V2.03846ZM4.12963 1C3.48197 1 3 1.49518 3 2.03846V13.9615C3 14.5048 3.48197 15 4.12963 15H11.8704C12.518 15 13 14.5048 13 13.9615V2.03846C13 1.49518 12.518 1 11.8704 1H4.12963Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
