import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgBrandPinterest = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M8.78122 5.76599C9.03761 5.86854 9.16232 6.15953 9.05976 6.41592L8.22713 8.4975C8.22171 8.51331 8.21545 8.52899 8.20833 8.5445L8.17405 8.63021C8.1229 8.79271 8.16745 8.91663 8.25668 9.02077C8.38085 9.1657 8.59219 9.26152 8.78181 9.26152C9.22379 9.26152 9.62616 9.06683 9.93027 8.67735C10.2397 8.28106 10.46 7.66248 10.46 6.82137C10.46 5.43563 9.45039 4.34888 8.07911 4.34888C6.44364 4.34888 5.50781 5.56015 5.50781 6.81611C5.50781 7.30398 5.70161 7.8177 5.89992 8.05914C6.07518 8.27254 6.04427 8.58761 5.83088 8.76287C5.61748 8.93814 5.30241 8.90723 5.12715 8.69383C4.76265 8.25004 4.50781 7.50316 4.50781 6.81611C4.50781 5.05635 5.8441 3.34888 8.07911 3.34888C10.0509 3.34888 11.46 4.93281 11.46 6.82137C11.46 7.82992 11.1958 8.68141 10.7185 9.29278C10.2358 9.91097 9.54905 10.2615 8.78181 10.2615C8.38997 10.2615 7.98977 10.1132 7.68379 9.85585L6.69471 12.3286C6.59215 12.585 6.30117 12.7097 6.04477 12.6071C5.78838 12.5045 5.66367 12.2136 5.76623 11.9572L7.23465 8.28613C7.2505 8.24061 7.26875 8.19487 7.28952 8.14896L8.13129 6.04453C8.23384 5.78814 8.52483 5.66343 8.78122 5.76599Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.00049 3.07576C1.00049 1.93295 1.93344 1 3.07625 1H12.9247C14.0675 1 15.0005 1.93295 15.0005 3.07576V12.9242C15.0005 14.067 14.0675 15 12.9247 15H3.07625C1.93344 15 1.00049 14.067 1.00049 12.9242V3.07576ZM3.07625 2C2.48572 2 2.00049 2.48523 2.00049 3.07576V12.9242C2.00049 13.5148 2.48572 14 3.07625 14H12.9247C13.5153 14 14.0005 13.5148 14.0005 12.9242V3.07576C14.0005 2.48523 13.5153 2 12.9247 2H3.07625Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
