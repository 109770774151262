import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgSpinner = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.00049 7.99955C1.00049 4.13388 4.13422 1.00006 7.99993 1C8.27607 0.999995 8.49993 1.22385 8.49993 1.49999C8.49994 1.77613 8.27608 2 7.99994 2C4.68652 2.00006 2.00049 4.68616 2.00049 7.99955C2.00049 11.313 4.68658 13.9991 8.00004 13.9991C11.3135 13.9991 13.9996 11.313 13.9996 7.99955C13.9996 6.40505 13.3782 4.95674 12.3634 3.88182C12.1739 3.68102 12.183 3.36457 12.3838 3.17501C12.5845 2.98544 12.901 2.99454 13.0906 3.19534C14.2736 4.44847 14.9996 6.13978 14.9996 7.99955C14.9996 11.8653 11.8658 14.9991 8.00004 14.9991C4.13429 14.9991 1.00049 11.8653 1.00049 7.99955Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
