import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgPlusSquare = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M5.00049 8.5C5.00049 8.22386 5.22435 8 5.50049 8H7.9956V5.50098C7.9956 5.22483 8.21946 5.00098 8.4956 5.00098C8.77175 5.00098 8.9956 5.22483 8.9956 5.50098V8H11.5005C11.7766 8 12.0005 8.22386 12.0005 8.5C12.0005 8.77614 11.7766 9 11.5005 9H8.99561V11.501C8.99561 11.7771 8.77175 12.001 8.49561 12.001C8.21946 12.001 7.99561 11.7771 7.99561 11.501V9H5.50049C5.22435 9 5.00049 8.77614 5.00049 8.5Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M2.00317 3.50293C2.00317 2.6745 2.67475 2.00293 3.50317 2.00293H13.4997C14.3281 2.00293 14.9997 2.6745 14.9997 3.50293V13.4995C14.9997 14.3279 14.3281 14.9995 13.4997 14.9995H3.50317C2.67474 14.9995 2.00317 14.3279 2.00317 13.4995V3.50293ZM3.50317 3.00293C3.22703 3.00293 3.00317 3.22679 3.00317 3.50293V13.4995C3.00317 13.7756 3.22703 13.9995 3.50317 13.9995H13.4997C13.7759 13.9995 13.9997 13.7756 13.9997 13.4995V3.50293C13.9997 3.22679 13.7759 3.00293 13.4997 3.00293H3.50317Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
