import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgArrowUpLine = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M3.83236 14.9982C2.8571 14.9982 1.99902 14.1301 1.99902 12.9982L1.99902 10.4982C1.99902 10.222 1.77517 9.99817 1.49902 9.99817C1.22288 9.99817 0.999023 10.222 0.999023 10.4982L0.999024 12.9982C0.999024 14.6163 2.24095 15.9982 3.83236 15.9982H13.1657C14.7571 15.9982 15.999 14.6163 15.999 12.9982V10.4982C15.999 10.222 15.7752 9.99817 15.499 9.99817C15.2229 9.99817 14.999 10.222 14.999 10.4982V12.9982C14.999 14.1301 14.1409 14.9982 13.1657 14.9982H3.83236Z" />
          <path d="M8.50537 10.0018C8.22923 10.0018 8.00537 9.77797 8.00537 9.50183L8.00537 1.70894L6.35892 3.35538C6.16366 3.55065 5.84708 3.55065 5.65182 3.35538C5.45655 3.16012 5.45655 2.84354 5.65182 2.64828L8.15182 0.148278C8.34708 -0.0469844 8.66366 -0.0469844 8.85892 0.148278L11.3589 2.64828C11.5542 2.84354 11.5542 3.16012 11.3589 3.35538C11.1637 3.55065 10.8471 3.55065 10.6518 3.35538L9.00537 1.70894L9.00537 9.50183C9.00537 9.77797 8.78151 10.0018 8.50537 10.0018Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" transform="rotate(-90 8 8)" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
