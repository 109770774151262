import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLLogotypeMenuHCol = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.00366 2.99415C1.00366 1.89012 1.89809 0.995117 3.00143 0.995117C4.10477 0.995117 4.9992 1.89012 4.9992 2.99415C4.9992 4.09819 4.10477 4.99319 3.00143 4.99319C1.89809 4.99319 1.00366 4.09819 1.00366 2.99415ZM3.00143 1.99463C2.44976 1.99463 2.00255 2.44213 2.00255 2.99415C2.00255 3.54617 2.44976 3.99367 3.00143 3.99367C3.5531 3.99367 4.00032 3.54617 4.00032 2.99415C4.00032 2.44213 3.5531 1.99463 3.00143 1.99463Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M1.00366 6.51099C1.00366 6.23498 1.22727 6.01123 1.5031 6.01123H4.49976C4.77559 6.01123 4.9992 6.23498 4.9992 6.51099 4.9992 6.787 4.77559 7.01075 4.49976 7.01075H1.5031C1.22727 7.01075 1.00366 6.787 1.00366 6.51099ZM5.99809 6.51099C5.99809 6.23498 6.2217 6.01123 6.49753 6.01123L9.49419 6.01123C9.77002 6.01123 9.99363 6.23498 9.99363 6.51099 9.99363 6.787 9.77002 7.01075 9.49419 7.01075L6.49753 7.01075C6.2217 7.01075 5.99809 6.787 5.99809 6.51099ZM11.5076 6.01123C11.2317 6.01123 11.0081 6.23498 11.0081 6.51099 11.0081 6.787 11.2317 7.01075 11.5076 7.01075L14.5042 7.01075C14.7801 7.01075 15.0037 6.787 15.0037 6.51099 15.0037 6.23498 14.7801 6.01123 14.5042 6.01123L11.5076 6.01123Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.5031 8.00148C1.22727 8.00148 1.00366 8.22523 1.00366 8.50124V14.4954C1.00366 14.7714 1.22727 14.9951 1.5031 14.9951H14.4888C14.7647 14.9951 14.9883 14.7714 14.9883 14.4954V8.50124C14.9883 8.22523 14.7647 8.00148 14.4888 8.00148H1.5031ZM2.00255 13.9956V9.001H13.9894V13.9956H2.00255Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
