import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLHeaderText = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.49979 1C1.22376 1 1 1.22383 1 1.49994V4.49961C1 4.77572 1.22376 4.99955 1.49979 4.99955H14.4944C14.7704 4.99955 14.9941 4.77572 14.9941 4.49961V1.49994C14.9941 1.22383 14.7704 1 14.4944 1H1.49979ZM1.99958 3.99966V1.99989H13.9946V3.99966H1.99958Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M1.00586 7.49878C1.00586 7.22267 1.22962 6.99884 1.50565 6.99884H14.5002C14.7762 6.99884 15 7.22267 15 7.49878 15 7.77489 14.7762 7.99872 14.5002 7.99872H1.50565C1.22962 7.99872 1.00586 7.77489 1.00586 7.49878ZM1.00586 9.53419C1.00586 9.25808 1.22962 9.03425 1.50565 9.03425H14.5002C14.7762 9.03425 15 9.25808 15 9.53419 15 9.81031 14.7762 10.0341 14.5002 10.0341H1.50565C1.22962 10.0341 1.00586 9.81031 1.00586 9.53419ZM1.50565 10.9994C1.22962 10.9994 1.00586 11.2232 1.00586 11.4993 1.00586 11.7754 1.22962 11.9992 1.50565 11.9992H14.5002C14.7762 11.9992 15 11.7754 15 11.4993 15 11.2232 14.7762 10.9994 14.5002 10.9994H1.50565ZM1.00586 13.5001C1.00586 13.2239 1.22962 13.0001 1.50565 13.0001H5.50397C5.78 13.0001 6.00377 13.2239 6.00377 13.5001 6.00377 13.7762 5.78 14 5.50397 14H1.50565C1.22962 14 1.00586 13.7762 1.00586 13.5001Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
