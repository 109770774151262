import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgChrome = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M8.00009 -0.000488281C3.58149 -0.000488281 -0.000488281 3.58149 -0.000488281 8.00009C-0.000488281 12.4187 3.58149 16.0007 8.00009 16.0007C12.4187 16.0007 16.0007 12.4187 16.0007 8.00009C16.0007 3.58149 12.4187 -0.000488281 8.00009 -0.000488281ZM2.28951 3.94975C3.55814 2.16434 5.64308 0.999512 8.00009 0.999512C10.65 0.999512 12.956 2.47184 14.1448 4.64307H8.00021C6.33339 4.64316 4.9783 5.81222 4.69682 7.38876L2.28951 3.94975ZM1.72676 4.88961C1.26125 5.82666 0.999512 6.88281 0.999512 8.00009C0.999512 11.5654 3.66479 14.5083 7.1116 14.9448L8.97511 11.2178C8.66744 11.3087 8.34032 11.3574 8.00021 11.3574C6.74734 11.3574 5.67068 10.6969 5.0936 9.69938L1.72676 4.88961ZM8.20316 14.9978C11.9756 14.8903 15.0007 11.7985 15.0007 8.00009C15.0007 7.17309 14.8573 6.37957 14.594 5.64307H10.4071C10.9972 6.24586 11.3574 7.07499 11.3574 8.00021C11.3574 8.7697 11.1082 9.47272 10.6849 10.0343L8.20316 14.9978ZM8.00021 10.3574C7.22047 10.3574 6.54153 9.99889 6.11515 9.43373L6.12397 9.42756L5.94075 9.16582C5.75061 8.82415 5.64307 8.4273 5.64307 8.00021C5.64307 6.67642 6.67624 5.64318 8 5.64307C9.32386 5.64307 10.3574 6.67635 10.3574 8.00021C10.3574 8.57125 10.1651 9.08822 9.8402 9.49153L9.83864 9.49075L9.83508 9.49786C9.40664 10.0259 8.75003 10.3574 8.00021 10.3574Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
