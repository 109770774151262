import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgBrushSlashed = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M13.2541 10.9148L14.8062 12.1159C15.0347 12.2927 15.0656 12.608 14.8752 12.8201C14.6848 13.0322 14.3453 13.0609 14.1168 12.8841L1.19376 2.88411C0.965304 2.70733 0.934437 2.39205 1.12482 2.17991C1.3152 1.96777 1.65473 1.93911 1.88319 2.11589L5.3077 4.76581V3C5.3077 1.92386 6.30262 1 7.46154 1C8.62046 1 9.61538 1.92386 9.61538 3V5.23241L12.6064 7.08397C12.7562 7.17671 12.8461 7.33282 12.8461 7.5V9.38344L13.2541 10.9148ZM6.38462 3C6.38462 2.47614 6.89739 2 7.46154 2C8.02569 2 8.53846 2.47614 8.53846 3V5.5C8.53846 5.66718 8.62844 5.82329 8.77824 5.91603L11.7692 7.76759V9.49678L11.7671 9.49727L11.7693 9.5054C11.7702 9.58583 11.7915 9.66171 11.8288 9.72876L11.8567 9.83347L6.3755 5.59209C6.38149 5.56224 6.38462 5.53146 6.38462 5.5V3Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M3.93355 6.735C4.09116 6.96917 4.0145 7.27764 3.76232 7.424L3.15386 7.77712V9H6.89485C7.19223 9 7.43331 9.22386 7.43331 9.5C7.43331 9.77614 7.19223 10 6.89485 10H3.01379L2.18971 14H7.42381L8.29516 12.7312C8.39404 12.5872 8.56524 12.5 8.74916 12.5C8.93308 12.5 9.10428 12.5872 9.20317 12.7312L10.0745 14H13.3846C13.682 14 13.9231 14.2239 13.9231 14.5C13.9231 14.7761 13.682 15 13.3846 15H9.77926C9.59534 15 9.42414 14.9128 9.32525 14.7688L8.74916 13.9299L8.17307 14.7688C8.07418 14.9128 7.90298 15 7.71906 15H1.53847C1.37794 15 1.22579 14.9335 1.12349 14.8186C1.0212 14.7037 0.979441 14.5525 1.0096 14.4061L2.0397 9.40605C2.04766 9.36744 2.06027 9.33058 2.07693 9.29593V7.5C2.07693 7.3276 2.17258 7.16737 2.33001 7.076L3.19155 6.576C3.44373 6.42965 3.77593 6.50083 3.93355 6.735Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
