import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgParentChildSchemeInverted = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path
            fillRule="evenodd"
            d="M0.499023 0C0.222881 0 -0.000976562 0.223858 -0.000976562 0.5V7.4997C-0.000976562 7.77584 0.222882 7.9997 0.499025 7.9997L7.49902 7.9997C7.77517 7.9997 7.99902 7.77584 7.99902 7.4997L7.99902 0.499999C7.99902 0.223856 7.77517 0 7.49902 0H0.499023ZM0.999025 6.9997L0.999024 1L6.99902 0.999999L6.99902 6.9997L0.999025 6.9997Z"
            clipRule="evenodd"
          />
          <path d="M6.01329 12.9694H7.01329V11.9694H6.01329V12.9694ZM3.99463 12.9694H2.99463L2.99463 11.9694H3.99463L3.99463 12.9694ZM8.99502 16.0004H9.99502L9.99502 15.0004H8.99502L8.99502 16.0004ZM12.9986 16.0004H11.9986L11.9986 15.0004H12.9986L12.9986 16.0004ZM8.99502 12.9971H9.99502V11.9971H8.99502V12.9971ZM15.9991 16.0003H14.9991V15.0003L15.9991 15.0003V16.0003ZM8.99502 9.99709H9.99502V8.99709H8.99502V9.99709ZM12.9989 9.99709H11.9989V8.99709H12.9989V9.99709ZM14.9992 10.0219H15.9992V9.02188H14.9992V10.0219ZM15.9999 13.0009 14.9999 13.0009V12.0009L15.9999 12.0009V13.0009ZM2.99463 10.0225 2.99463 9.02246H3.99463L3.99463 10.0225H2.99463Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
