import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgIntercom = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 17"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.20098 6.09986C4.42199 5.9343 4.73536 5.97924 4.90093 6.20025C5.11428 6.48505 5.57165 6.94735 6.15333 7.33559C6.73886 7.72639 7.39076 8.00233 8.00076 8.00233C8.61077 8.00233 9.26266 7.72639 9.84819 7.33559C10.4299 6.94735 10.8872 6.48505 11.1006 6.20025C11.2662 5.97924 11.5795 5.9343 11.8005 6.09986C12.0215 6.26543 12.0665 6.5788 11.9009 6.79981C11.6143 7.18244 11.0716 7.72129 10.4033 8.16734C9.73886 8.61083 8.89076 9.00233 8.00076 9.00233C7.11077 9.00233 6.26266 8.61083 5.59819 8.16734C4.92988 7.72129 4.38724 7.18244 4.1006 6.79981C3.93503 6.5788 3.97998 6.26543 4.20098 6.09986Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M11.7585 13.0112L14.0054 14.5815L14.0054 3.01123C14.0054 1.90666 13.1099 1.01123 12.0054 1.01123H4.00537C2.9008 1.01123 2.00537 1.90666 2.00537 3.01123V11.0112C2.00537 12.1158 2.9008 13.0112 4.00537 13.0112H11.7585ZM1.00537 3.01123C1.00537 1.35438 2.34852 0.0112305 4.00537 0.0112305H12.0054C13.6622 0.0112305 15.0054 1.35438 15.0054 3.01123L15.0054 15.477C15.0054 15.9083 14.5201 16.1612 14.1666 15.9141L11.4437 14.0112H4.00537C2.34852 14.0112 1.00537 12.6681 1.00537 11.0112V3.01123Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
