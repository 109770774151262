import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgIndent = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1.0083 2.49504C1.0083 2.21921 1.23203 1.99561 1.50801 1.99561H14.5003C14.7763 1.99561 15 2.21921 15 2.49504 15 2.77087 14.7763 2.99448 14.5003 2.99448H1.50801C1.23203 2.99448 1.0083 2.77087 1.0083 2.49504ZM1.00001 14.4962C1.00001 14.2203 1.22373 13.9967 1.49971 13.9967H14.492C14.768 13.9967 14.9917 14.2203 14.9917 14.4962 14.9917 14.772 14.768 14.9956 14.492 14.9956H1.49971C1.22373 14.9956 1.00001 14.772 1.00001 14.4962ZM8.50385 5.00784C8.22788 5.00784 8.00415 5.23144 8.00415 5.50727 8.00415 5.78311 8.22788 6.00671 8.50385 6.00671H14.5003C14.7763 6.00671 15 5.78311 15 5.50727 15 5.23144 14.7763 5.00784 14.5003 5.00784H8.50385ZM8.00415 7.50063C8.00415 7.2248 8.22788 7.0012 8.50385 7.0012H14.5003C14.7763 7.0012 15 7.2248 15 7.50063 15 7.77646 14.7763 8.00007 14.5003 8.00007H8.50385C8.22788 8.00007 8.00415 7.77646 8.00415 7.50063ZM8.50385 8.9887C8.22788 8.9887 8.00415 9.21231 8.00415 9.48814 8.00415 9.76397 8.22788 9.98758 8.50385 9.98758H14.5003C14.7763 9.98758 15 9.76397 15 9.48814 15 9.21231 14.7763 8.9887 14.5003 8.9887H8.50385ZM8.00415 11.4956C8.00415 11.2198 8.22788 10.9962 8.50385 10.9962H14.5003C14.7763 10.9962 15 11.2198 15 11.4956 15 11.7715 14.7763 11.9951 14.5003 11.9951H8.50385C8.22788 11.9951 8.00415 11.7715 8.00415 11.4956ZM1.82877 12.3992C1.62107 12.5809 1.30538 12.5598 1.12365 12.3523.941912 12.1447.962958 11.8291 1.17065 11.6475L4.73849 8.52731 1.17065 5.40711C.962958 5.22548.941912 4.90995 1.12365 4.70237 1.30538 4.49478 1.62107 4.47375 1.82877 4.65538L5.8264 8.15144C5.93484 8.24628 5.99704 8.38329 5.99704 8.52731 5.99704 8.67133 5.93484 8.80834 5.8264 8.90317L1.82877 12.3992Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
