import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgChevronUp = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.72636 4.58405C7.89436 4.47198 8.11329 4.47198 8.28129 4.58405L14.7812 9.58484C15.0109 9.73809 15.0729 10.0485 14.9197 10.2783C14.7664 10.508 14.456 10.57 14.2262 10.4167L8.00382 5.60104L1.78118 10.4167C1.55146 10.57 1.24101 10.508 1.08777 10.2783C0.934527 10.0485 0.996526 9.73809 1.22625 9.58484L7.72636 4.58405Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
