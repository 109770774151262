import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgPinInverted = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M11.062 1.30323C11.4598 0.898922 12.1046 0.898922 12.5023 1.30323L14.7017 3.53911C15.0994 3.94342 15.0994 4.59894 14.7017 5.00325L11.6275 8.12841C12.126 9.82063 11.86 11.6959 10.8295 13.1928C10.507 13.6612 9.85284 13.6572 9.45512 13.2529L6.44585 10.1938C6.4373 10.2039 6.42831 10.2138 6.41887 10.2234L1.86928 14.8484C1.67042 15.0505 1.348 15.0505 1.14914 14.8484C0.950285 14.6462 0.950285 14.3185 1.14914 14.1163L5.69874 9.49129C5.70818 9.4817 5.7179 9.47255 5.72787 9.46387L2.70612 6.39203C2.3084 5.98771 2.30447 5.32272 2.76528 4.99486C4.34493 3.87098 6.35303 3.64158 8.10758 4.30667L11.062 1.30323Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
