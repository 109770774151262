import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgBriefcase = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.01301 1.49829C4.01302 1.22215 4.23687 0.998291 4.51301 0.998291H11.4932C11.6258 0.998291 11.753 1.05097 11.8468 1.14474C11.9405 1.23851 11.9932 1.36569 11.9932 1.4983L11.9932 3.00391H14.5016C14.7778 3.00391 15.0016 3.22776 15.0016 3.5039L15.0017 14.5019C15.0017 14.778 14.7778 15.0019 14.5017 15.0019H1.49807C1.22193 15.0019 0.998068 14.778 0.998068 14.5019L0.998047 3.50391C0.998047 3.3713 1.05072 3.24412 1.14449 3.15035C1.23826 3.05658 1.36544 3.00391 1.49805 3.00391H4.013L4.01301 1.49829ZM5.013 3.00391H10.9932L10.9932 1.99829H5.01301L5.013 3.00391ZM1.99805 4.00391L1.99807 7.99282H4.02588V7.51001C4.02588 7.23387 4.24974 7.01001 4.52588 7.01001C4.80202 7.01001 5.02588 7.23387 5.02588 7.51001V7.99282H11.0015V7.51001C11.0015 7.23387 11.2253 7.01001 11.5015 7.01001C11.7776 7.01001 12.0015 7.23387 12.0015 7.51001V7.99282H14.0017L14.0016 4.00391H1.99805ZM4.02588 8.99282V9.51001C4.02588 9.78615 4.24974 10.01 4.52588 10.01C4.80202 10.01 5.02588 9.78615 5.02588 9.51001V8.99282H11.0015V9.51001C11.0015 9.78615 11.2253 10.01 11.5015 10.01C11.7776 10.01 12.0015 9.78615 12.0015 9.51001V8.99282H14.0017V14.0019H1.99807V8.99282H4.02588Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
