import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgPersonalisationVariants = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.99976 1C6.06676 1 4.49976 2.56547 4.49976 4.49659C4.49976 5.71566 5.12424 6.78903 6.07105 7.41483C4.21064 8.25119 3.00439 10.2927 3.00439 12.7522V14.5005C3.00439 14.7764 3.22825 15 3.50439 15H12.5044C12.7805 15 13.0044 14.7764 13.0044 14.5005V12.7522C13.0044 10.2902 11.7958 8.24714 9.93221 7.41235C10.8769 6.7862 11.4998 5.71405 11.4998 4.49659C11.4998 2.56547 9.93275 1 7.99976 1ZM5.49976 4.49659C5.49976 3.11722 6.61904 1.99902 7.99976 1.99902C9.38047 1.99902 10.4998 3.11722 10.4998 4.49659C10.4998 5.87595 9.38047 6.99415 7.99976 6.99415C6.61904 6.99415 5.49976 5.87595 5.49976 4.49659ZM4.00439 12.7522C4.00439 9.9249 5.77673 8.00683 8.00439 8.00683C10.2321 8.00683 12.0044 9.9249 12.0044 12.7522V14.001H4.00439V12.7522Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M2.35444 4.57079C2.11977 4.88833 2.00977 5.32303 2.00977 5.73636 2.00977 6.41253 2.5985 7.00773 3.38449 7.00773 3.39628 7.00773 3.40797 7.00813 3.41955 7.00894 3.44773 7.00818 3.47603 7.0078 3.50442 7.0078 3.78057 7.0078 4.00442 7.23144 4.00442 7.50732 4.00442 7.78319 3.78057 8.00683 3.50442 8.00683 2.22144 8.00683 1 9.26587 1 11.2519V12.0004H1.52837C1.80451 12.0004 2.02837 12.224 2.02837 12.4999 2.02837 12.7758 1.80451 12.9994 1.52837 12.9994H.5C.223858 12.9994 0 12.7758 0 12.4999V11.2519C0 9.61147.732138 8.18586 1.86337 7.47965 1.34525 7.06745 1.00977 6.44644 1.00977 5.73636 1.00977 5.17166 1.15571 4.51087 1.54994 3.97742 1.95998 3.42255 2.61111 3.04585 3.51342 3.04585 3.78957 3.04585 4.01342 3.26949 4.01342 3.54537 4.01342 3.82124 3.78957 4.04488 3.51342 4.04488 2.92398 4.04488 2.57328 4.27466 2.35444 4.57079ZM12.0022 3.52C12.0022 3.24413 12.2261 3.02049 12.5022 3.02049 13.3986 3.02049 14.0452 3.40939 14.4517 3.96769 14.843 4.50507 14.993 5.1699 14.993 5.73654 14.993 6.44668 14.6572 7.06737 14.1387 7.47946 15.2693 8.18564 15.9998 9.61143 15.9998 11.2515V12.4996C15.9998 12.7755 15.7759 12.9991 15.4998 12.9991H14.5025C14.2264 12.9991 14.0025 12.7755 14.0025 12.4996 14.0025 12.2237 14.2264 12.0001 14.5025 12.0001H14.9998V11.2515C14.9998 9.26487 13.781 8.00683 12.4985 8.00683 12.2224 8.00683 11.9985 7.78319 11.9985 7.50732 11.9985 7.23144 12.2224 7.0078 12.4985 7.0078 12.5268 7.0078 12.5549 7.00818 12.583 7.00892 12.5936 7.00825 12.6044 7.00791 12.6152 7.00791 13.4037 7.00791 13.993 6.41196 13.993 5.73654 13.993 5.32515 13.8806 4.88171 13.643 4.55533 13.4206 4.24987 13.0718 4.01951 12.5022 4.01951 12.2261 4.01951 12.0022 3.79587 12.0022 3.52Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
