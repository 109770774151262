import { UnreachableCaseException } from '@kontent-ai/errors';
import React from 'react';
import styled, { css } from 'styled-components';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { BadgeStyle } from '../badgeStyle.ts';
import {
  badgeBackgroundColorVariableName,
  badgeColorValueVariableName,
} from '../cssVariableNames.ts';
import { defaultBadgeStyles, inverseBadgeStyles } from '../styles.ts';

export interface IStyledBadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  readonly withValue?: boolean;
  readonly $badgeStyle: BadgeStyle | 'contextual';
}

const getBadgeSizeStyles = ({ withValue }: IStyledBadgeProps) => {
  const spacing = withValue ? Spacing.L : Spacing.S;

  return css`
    height: ${px(spacing)};
    width: ${px(spacing)};
  `;
};

const getBadgeColorTokens = (badgeStyle: BadgeStyle) => {
  switch (badgeStyle) {
    case 'default':
      return defaultBadgeStyles;
    case 'inverse':
      return inverseBadgeStyles;
    default:
      throw UnreachableCaseException(badgeStyle);
  }
};

const mapBadgeStyleToColorVariables = ({ $badgeStyle }: IStyledBadgeProps) => {
  if ($badgeStyle === 'contextual') {
    return;
  }

  const { color, backgroundColor } = getBadgeColorTokens($badgeStyle);
  return {
    style: {
      [badgeBackgroundColorVariableName]: backgroundColor,
      [badgeColorValueVariableName]: color,
    },
  };
};

export const StyledBadge = styled.div.attrs<IStyledBadgeProps>(
  mapBadgeStyleToColorVariables,
)<IStyledBadgeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${px(BorderRadius.Pill)};
  background-color: var(${badgeBackgroundColorVariableName});
  color: var(${badgeColorValueVariableName});
  ${getBadgeSizeStyles};
`;
