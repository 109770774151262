import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgMagnifierMinus = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M5.49219 7.00586C5.21604 7.00586 4.99219 7.22972 4.99219 7.50586C4.99219 7.782 5.21604 8.00586 5.49219 8.00586H9.49219C9.76833 8.00586 9.99219 7.782 9.99219 7.50586C9.99219 7.22972 9.76833 7.00586 9.49219 7.00586H5.49219Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M14 7.5C14 9.11493 13.4111 10.5923 12.4362 11.7291L14.8538 14.1462C15.0491 14.3414 15.0491 14.658 14.8539 14.8533C14.6586 15.0486 14.3421 15.0486 14.1468 14.8534L11.7291 12.4362C10.5924 13.411 9.11496 14 7.5 14C3.91015 14 1 11.0899 1 7.5C1 3.91015 3.91015 1 7.5 1C11.0899 1 14 3.91015 14 7.5ZM7.5 13C10.5376 13 13 10.5376 13 7.5C13 4.46243 10.5376 2 7.5 2C4.46243 2 2 4.46243 2 7.5C2 10.5376 4.46243 13 7.5 13Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
