import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgArrowDownCircle = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M8.50049 3.9939C8.77663 3.9939 9.00049 4.21775 9.00049 4.4939L9.00049 11.283L10.6426 9.64083C10.8379 9.44557 11.1545 9.44557 11.3497 9.64083C11.545 9.83609 11.545 10.1527 11.3497 10.3479L8.85018 12.8475C8.65492 13.0428 8.33834 13.0428 8.14308 12.8475L5.64352 10.3479C5.44825 10.1527 5.44825 9.83609 5.64352 9.64083C5.83878 9.44557 6.15536 9.44557 6.35062 9.64083L8.00049 11.2907L8.00049 4.4939C8.00049 4.21775 8.22435 3.9939 8.50049 3.9939Z" />
          <path
            fillRule="evenodd"
            d="M1 8.5C1 4.35786 4.35786 1 8.5 1C12.6421 1 16 4.35786 16 8.5C16 12.6421 12.6421 16 8.5 16C4.35786 16 1 12.6421 1 8.5ZM8.5 2C4.91015 2 2 4.91015 2 8.5C2 12.0899 4.91015 15 8.5 15C12.0899 15 15 12.0899 15 8.5C15 4.91015 12.0899 2 8.5 2Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" transform="rotate(-90 8 8)" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
