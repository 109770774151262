import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgArrowCrookedLeft = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            d="M4.33581 3.13179C4.54036 3.32038 4.55578 3.64183 4.37027 3.84976L1.62999 6.99551H14.3521C14.8284 6.99551 15.2548 7.18021 15.5573 7.5247C15.8553 7.86397 16 8.24487 16 8.73637V11.4917C16 11.7724 15.7762 12 15.5 12C15.2239 12 15 11.7724 15 11.4917V8.73637C15 8.44366 14.9151 8.31991 14.8114 8.20173C14.7121 8.08877 14.5646 8.01207 14.3521 8.01207H1.63002L4.37027 11.0089C4.55578 11.2168 4.54036 11.5383 4.33581 11.7269C4.13127 11.9155 3.81506 11.8998 3.62955 11.6919L0.152958 7.8697C0.0586569 7.77725 0 7.64748 0 7.50379C0 7.36022 0.0585513 7.23056 0.152703 7.13813L3.62955 3.16681C3.81506 2.95888 4.13127 2.9432 4.33581 3.13179Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
