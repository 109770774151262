import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgModalClose = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1.85355 1.14644C1.65828 0.951182 1.3417 0.951187 1.14644 1.14645C0.951182 1.34172 0.951187 1.6583 1.14645 1.85356L7.29191 7.99883L1.14645 14.1441C0.951187 14.3394 0.951182 14.6559 1.14644 14.8512C1.3417 15.0465 1.65828 15.0465 1.85355 14.8512L7.99903 8.70592L14.1443 14.8511C14.3396 15.0463 14.6562 15.0463 14.8515 14.851C15.0467 14.6558 15.0467 14.3392 14.8514 14.1439L8.70614 7.99883L14.8514 1.85372C15.0467 1.65846 15.0467 1.34188 14.8515 1.14661C14.6562 0.951346 14.3396 0.951341 14.1443 1.1466L7.99903 7.29173L1.85355 1.14644Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
