import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgPda = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M7.01105 12.4934C7.01105 12.2173 7.23491 11.9934 7.51105 11.9934H8.5089C8.78504 11.9934 9.0089 12.2173 9.0089 12.4934 9.0089 12.7696 8.78504 12.9934 8.5089 12.9934H7.51105C7.23491 12.9934 7.01105 12.7696 7.01105 12.4934ZM5.53095 12.9934C5.80709 12.9934 6.03095 12.7696 6.03095 12.4934 6.03095 12.2173 5.80709 11.9934 5.53095 11.9934H5.50756C5.23142 11.9934 5.00756 12.2173 5.00756 12.4934 5.00756 12.7696 5.23142 12.9934 5.50756 12.9934H5.53095ZM11.0122 12.4934C11.0122 12.7696 10.7883 12.9934 10.5122 12.9934H10.5046C10.2285 12.9934 10.0046 12.7696 10.0046 12.4934 10.0046 12.2173 10.2285 11.9934 10.5046 11.9934H10.5122C10.7883 11.9934 11.0122 12.2173 11.0122 12.4934Z" />
          <path
            fillRule="evenodd"
            d="M2.49289 0.450768C2.81465 0.147026 3.25028 -0.00292969 3.72222 -0.00292969H12.2778C12.7497 -0.00292969 13.1854 0.147026 13.5071 0.450768C13.8315 0.756968 14 1.18211 14 1.65084V14.3423C14 14.811 13.8315 15.2362 13.5071 15.5424C13.1854 15.8461 12.7497 15.9961 12.2778 15.9961H3.72222C3.25028 15.9961 2.81465 15.8461 2.49289 15.5424C2.16852 15.2362 2 14.811 2 14.3423V1.65084C2 1.18211 2.16852 0.756968 2.49289 0.450768ZM3.17934 1.17795C3.07593 1.27556 3 1.4273 3 1.65084V9.98074H13V1.65084C13 1.4273 12.9241 1.27556 12.8207 1.17795C12.7146 1.07787 12.5392 0.99707 12.2778 0.99707H3.72222C3.46083 0.99707 3.28535 1.07787 3.17934 1.17795ZM13 10.9807H3V14.3423C3 14.5658 3.07593 14.7176 3.17934 14.8152C3.28535 14.9153 3.46083 14.9961 3.72222 14.9961H12.2778C12.5392 14.9961 12.7146 14.9153 12.8207 14.8152C12.9241 14.7176 13 14.5658 13 14.3423V10.9807Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
