import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgTruck = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M0.501953 1.99512C0.225811 1.99512 0.00195312 2.21883 0.00195312 2.49479V10.4945C0.00195312 10.7704 0.225811 10.9941 0.501953 10.9941H1.50391C1.78005 10.9941 2.00391 10.7704 2.00391 10.4945C2.00391 10.2185 1.78005 9.99479 1.50391 9.99479H1.00195V2.99447H9.00391V9.99865H6.50007C6.22393 9.99865 6.00007 10.2224 6.00007 10.4983C6.00007 10.7743 6.22393 10.998 6.50007 10.998H9.50389C9.78004 10.998 10.0039 10.7743 10.0039 10.4983V7.99707L14.9982 7.99707V10.0063L14.4887 10.0022C14.2126 9.99996 13.9869 10.2219 13.9847 10.4978C13.9825 10.7738 14.2045 10.9993 14.4806 11.0015L15.4941 11.0097C15.6274 11.0107 15.7557 10.9586 15.8503 10.8647C15.9449 10.7709 15.9982 10.6432 15.9982 10.51V6.14782C15.9982 6.01879 15.9482 5.89476 15.8588 5.80169L13.3206 3.1607C13.2263 3.0626 13.0961 3.00715 12.96 3.00715H10.0039V2.49479C10.0039 2.36227 9.95124 2.23518 9.85747 2.14147C9.7637 2.04776 9.63652 1.99512 9.50391 1.99512H0.501953ZM14.9982 6.99772V6.34891L12.747 4.0065H10.0039V6.99772L14.9982 6.99772Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.02832 13.9951C5.13289 13.9951 6.02832 13.1003 6.02832 11.9964 6.02832 10.8926 5.13289 9.99772 4.02832 9.99772 2.92375 9.99772 2.02832 10.8926 2.02832 11.9964 2.02832 13.1003 2.92375 13.9951 4.02832 13.9951ZM4.02832 12.9958C4.58061 12.9958 5.02832 12.5483 5.02832 11.9964 5.02832 11.4445 4.58061 10.9971 4.02832 10.9971 3.47604 10.9971 3.02832 11.4445 3.02832 11.9964 3.02832 12.5483 3.47604 12.9958 4.02832 12.9958ZM12.02 13.9951C13.1246 13.9951 14.02 13.1003 14.02 11.9964 14.02 10.8926 13.1246 9.99772 12.02 9.99772 10.9155 9.99772 10.02 10.8926 10.02 11.9964 10.02 13.1003 10.9155 13.9951 12.02 13.9951ZM12.02 12.9958C12.5723 12.9958 13.02 12.5483 13.02 11.9964 13.02 11.4445 12.5723 10.9971 12.02 10.9971 11.4677 10.9971 11.02 11.4445 11.02 11.9964 11.02 12.5483 11.4677 12.9958 12.02 12.9958Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
