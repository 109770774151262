import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgUserFrame = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1 1.5C1 1.22386 1.22386 1 1.5 1H14.5C14.7761 1 15 1.22386 15 1.5V14.5C15 14.7761 14.7761 15 14.5 15H12.501C12.5007 15 12.5003 15 12.5 15C12.4997 15 12.4993 15 12.499 15H3.50099C3.50066 15 3.50033 15 3.5 15C3.49967 15 3.49934 15 3.49901 15H1.5C1.22386 15 1 14.7761 1 14.5V1.5ZM4.05443 14H11.9474C11.691 12.7183 10.4832 11 8 11C5.55308 11 4.31835 12.7202 4.05443 14ZM12.9609 14C12.7028 12.2875 11.155 10 8 10C4.88741 10 3.30462 12.2852 3.04004 14H2V2H14V14H12.9609ZM8.00009 3.5C6.6198 3.5 5.50085 4.61894 5.50085 5.99923C5.50085 7.37952 6.6198 8.49846 8.00009 8.49846C9.38037 8.49846 10.4993 7.37952 10.4993 5.99923C10.4993 4.61894 9.38037 3.5 8.00009 3.5ZM4.50085 5.99923C4.50085 4.06666 6.06751 2.5 8.00009 2.5C9.93266 2.5 11.4993 4.06666 11.4993 5.99923C11.4993 7.9318 9.93266 9.49846 8.00009 9.49846C6.06751 9.49846 4.50085 7.9318 4.50085 5.99923Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
