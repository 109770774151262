import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgChevronLeftLine = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M3 1.49928C3 1.22354 3.22418 1 3.50071 1 3.77725 1 4.00143 1.22354 4.00143 1.49928L4.00143 14.4807C4.00143 14.7564 3.77725 14.98 3.50071 14.98 3.22418 14.98 3 14.7564 3 14.4807L3 1.49928ZM12.0945 14.7946C12.2573 15.0175 12.5705 15.0666 12.7941 14.9043 13.0176 14.7419 13.0668 14.4296 12.904 14.2067L8.12862 8.00998 12.904 1.81324C13.0668 1.59035 13.0176 1.27805 12.7941 1.1157 12.5705.953352 12.2573 1.00243 12.0945 1.22531L7.10442 7.71602C6.97645 7.89122 6.97645 8.12874 7.10442 8.30394L12.0945 14.7946Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
