import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgBrandBing = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M6.02002 4.52148C6.02002 4.24534 5.79616 4.02148 5.52002 4.02148C5.24388 4.02148 5.02002 4.24534 5.02002 4.52148V10.9428C5.02002 11.5742 5.65182 12.0063 6.23919 11.7835L10.842 10.0377C11.5759 9.75934 11.6266 8.74033 10.9238 8.39052L7.8916 6.88117C7.64439 6.75812 7.34423 6.85877 7.22118 7.10598C7.09813 7.35319 7.19877 7.65334 7.44598 7.7764L10.2735 9.18383L6.02002 10.7971V4.52148Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.00293 3C1.00293 1.89543 1.89836 1 3.00293 1H13.0039C14.1085 1 15.0039 1.89543 15.0039 3V13.001C15.0039 14.1055 14.1085 15.001 13.0039 15.001H3.00293C1.89836 15.001 1.00293 14.1055 1.00293 13.001V3ZM3.00293 2H13.0039C13.5562 2 14.0039 2.44772 14.0039 3V13.001C14.0039 13.5533 13.5562 14.001 13.0039 14.001H3.00293C2.45064 14.001 2.00293 13.5533 2.00293 13.001V3C2.00293 2.44772 2.45064 2 3.00293 2Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
