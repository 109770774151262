import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgCompass = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
          <path d="M6.3019 5.82549C6.09318 5.92435 5.92511 6.09242 5.82624 6.30114L3.54122 11.1251C3.13898 11.9742 4.02387 12.8591 4.87305 12.4569L9.69698 10.1719C9.9057 10.073 10.0738 9.90494 10.1726 9.69622L12.4576 4.87229C12.8599 4.02312 11.975 3.13823 11.1258 3.54047L6.3019 5.82549ZM11.5539 4.4442L6.72998 6.72922L6.69621 6.80052C6.71051 6.81198 6.72433 6.82434 6.73759 6.8376L9.16195 9.26196C9.17474 9.27475 9.18669 9.28805 9.1978 9.30181L9.26889 9.26814L11.5539 4.4442ZM8.24087 9.75509L6.24302 7.75725L4.44496 11.5532L8.24087 9.75509Z" />
          <path d="M15.9993 7.99925C15.9993 12.4175 12.4175 15.9993 7.99927 15.9993C3.58099 15.9993 -0.000733497 12.4175 -0.000732317 7.99925C-0.000731895 3.58098 3.58099 -0.00074522 7.99927 -0.00074522C12.4175 -0.000745262 15.9993 3.58098 15.9993 7.99925ZM7.99927 14.9993C11.8653 14.9993 14.9993 11.8652 14.9993 7.99925C14.9993 4.13326 11.8653 0.999255 7.99927 0.999255C4.13327 0.999255 0.999268 4.13326 0.999268 7.99925C0.999269 11.8652 4.13327 14.9993 7.99927 14.9993Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
