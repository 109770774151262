import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgBubbleTimes = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M4.96133 3.04013C4.76361 2.84736 4.44706 2.85136 4.25428 3.04908C4.0615 3.2468 4.06551 3.56335 4.26323 3.75613L7.28723 6.70456L4.26323 9.65299C4.06551 9.84576 4.0615 10.1623 4.25428 10.36C4.44706 10.5578 4.76361 10.5618 4.96133 10.369L8.00346 7.40288L11.0455 10.3689C11.2432 10.5617 11.5598 10.5577 11.7526 10.36C11.9454 10.1623 11.9414 9.84571 11.7436 9.65293L8.71969 6.70456L11.7436 3.75619C11.9414 3.56341 11.9454 3.24685 11.7526 3.04914C11.5598 2.85142 11.2432 2.84741 11.0455 3.04019L8.00346 6.00623L4.96133 3.04013Z" />
          <path
            fillRule="evenodd"
            d="M2.3182 1C1.59435 1 1.00002 1.59432 1.00002 2.31818L1 10.6947C1 11.4155 1.59126 12.0044 2.31818 12.0044H4.49776C4.7739 12.0044 4.99776 12.2283 4.99776 12.5044V14.4144L7.67499 12.1244C7.76555 12.047 7.88081 12.0044 7.99999 12.0044H13.6818C14.4087 12.0044 15 11.4155 15 10.6947V2.31818C15 1.59432 14.4057 1 13.6818 1H2.3182ZM2.46763e-05 2.31818C2.46763e-05 1.04204 1.04206 0 2.3182 0H13.6818C14.958 0 16 1.04204 16 2.31818V10.6947C16 11.9739 14.9549 13.0044 13.6818 13.0044H8.18465L4.82276 15.88C4.67444 16.0068 4.46587 16.0358 4.28859 15.9541C4.11132 15.8725 3.99776 15.6952 3.99776 15.5V13.0044H2.31818C1.0451 13.0044 0 11.9739 0 10.6947L2.46763e-05 2.31818Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
