import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgSuperscript = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M14.9896 3.11266C14.7394 1.90796 13.7983.991766 12.4968 1.00006 11.8991 1.00386 11.2925 1.24217 10.8319 1.61636 10.3717 1.99033 10.0042 2.54681 10 3.21115L11 3.21752C11.0018 2.9351 11.161 2.63745 11.4625 2.39248 11.7638 2.14772 12.1542 2.00226 12.5032 2.00004 13.2591 1.99522 13.8423 2.50629 14.0104 3.316 14.0356 3.43725 14.0217 3.60502 13.952 3.81889 13.8835 4.02882 13.7726 4.24857 13.647 4.45525 13.5225 4.66009 13.3911 4.83995 13.2902 4.96912 13.24 5.03332 13.1981 5.08406 13.1693 5.11804L13.1475 5.14364 10 7.84151V9.00001H15V8.00001H11.3517L13.85 5.85853 13.8717 5.83443 13.5 5.50001C13.8717 5.83443 13.8716 5.83452 13.8717 5.83443L13.8725 5.83354 13.8737 5.83216 13.8775 5.82795 13.8899 5.81383C13.9003 5.80193 13.9148 5.78512 13.9329 5.76381 13.9689 5.72124 14.019 5.66048 14.0781 5.58492 14.1956 5.43458 14.3513 5.22189 14.5016 4.97455 14.6508 4.72906 14.8022 4.43701 14.9027 4.12894 15.0019 3.82482 15.0634 3.46838 14.9896 3.11266ZM1 4.50001C1 4.22387 1.22386 4.00001 1.5 4.00001H8.5C8.77614 4.00001 9 4.22387 9 4.50001 9 4.77616 8.77614 5.00001 8.5 5.00001H6V14.5C6 14.7762 5.77614 15 5.5 15 5.22386 15 5 14.7762 5 14.5V5.00001H1.5C1.22386 5.00001 1 4.77616 1 4.50001Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
