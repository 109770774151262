import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgGift = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.26756 4C4.09739 3.70583 4 3.36429 4 3C4 1.89543 4.89543 1 6 1C7.10457 1 8 1.89543 8 3C8 1.89543 8.89543 1 10 1C11.1046 1 12 1.89543 12 3C12 3.36429 11.9026 3.70583 11.7324 4H13C14.1046 4 15 4.89543 15 6V8H14V12C14 13.1046 13.1046 14 12 14H4C2.89543 14 2 13.1046 2 12L2 8L1 8V6C1 4.89543 1.89543 4 3 4H4.26756ZM10.0014 4C10.553 3.99924 11 3.55181 11 3C11 2.44772 10.5523 2 10 2C9.44772 2 9 2.44772 9 3C9 3.55181 9.44695 3.99924 9.99859 4H10.0014ZM8 3C8 3.36429 8.09739 3.70583 8.26756 4H7.73244C7.90261 3.70583 8 3.36429 8 3ZM5.99876 4C5.44705 3.99933 5 3.55187 5 3C5 2.44772 5.44772 2 6 2C6.55229 2 7 2.44772 7 3C7 3.55187 6.55295 3.99933 6.00124 4H5.99876ZM3 5C2.44772 5 2 5.44772 2 6L2 7L7 7V5L3 5ZM9 5L13 5C13.5523 5 14 5.44771 14 6V7H9V5ZM9 8V13H12C12.5523 13 13 12.5523 13 12V8H9ZM7 8H3V12C3 12.5523 3.44772 13 4 13H7V8Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
