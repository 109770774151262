import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgBadge = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M8.0332 3.4214C8.24717 3.4214 8.43742 3.55755 8.50646 3.76007L9.27134 6.00372L11.5554 6.10523C11.7643 6.11451 11.9453 6.25276 12.0092 6.4518C12.0732 6.65084 12.0065 6.86866 11.8422 6.99785L10.0173 8.43215L10.597 10.8026C10.6477 11.0098 10.5614 11.2263 10.3821 11.3417C10.2028 11.4572 9.97002 11.4463 9.80236 11.3145L8.0332 9.92402L6.26405 11.3145C6.09638 11.4463 5.86359 11.4572 5.68431 11.3417C5.50503 11.2263 5.41873 11.0098 5.46939 10.8026L6.04913 8.43215L4.22423 6.99785C4.05986 6.86866 3.99322 6.65084 4.05717 6.4518C4.12111 6.25276 4.30215 6.11451 4.511 6.10523L6.79507 6.00372L7.55995 3.76007C7.62899 3.55755 7.81924 3.4214 8.0332 3.4214ZM8.0332 5.47095L7.63146 6.64941C7.56504 6.84422 7.38603 6.97844 7.1804 6.98758L5.90174 7.04441L6.9203 7.84496C7.07451 7.96616 7.14361 8.16633 7.09701 8.35685L6.78484 9.63329L7.72423 8.89496C7.90556 8.75244 8.16085 8.75244 8.34218 8.89496L9.28156 9.63329L8.96939 8.35685C8.9228 8.16633 8.9919 7.96616 9.14611 7.84496L10.1647 7.04441L8.886 6.98758C8.68038 6.97844 8.50136 6.84422 8.43495 6.64941L8.0332 5.47095Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.83271 0.0288145C7.94093 -0.00960483 8.05907 -0.00960483 8.16728 0.0288145L14.6673 2.33651C14.8667 2.40732 15 2.59604 15 2.80769V9.73077C15 12.0406 13.1487 13.6116 11.4999 14.5629C10.6557 15.0499 9.81559 15.4037 9.18906 15.6354C8.87499 15.7516 8.61262 15.8378 8.42763 15.8952C8.33509 15.924 8.26179 15.9456 8.21092 15.9602C8.18548 15.9675 8.16564 15.973 8.1518 15.9769L8.13558 15.9813L8.13094 15.9826L8.1295 15.9829L8 15.9998L7.871 15.9831L7.86906 15.9826L7.86441 15.9813L7.8482 15.9769C7.83435 15.973 7.81452 15.9675 7.78908 15.9602C7.73821 15.9456 7.66491 15.924 7.57237 15.8952C7.38738 15.8378 7.12501 15.7516 6.81094 15.6354C6.18441 15.4037 5.34435 15.0499 4.50014 14.5629C2.85128 13.6116 1 12.0406 1 9.73077V2.80822C1 2.59657 1.13326 2.40732 1.33272 2.33651L7.83271 0.0288145ZM2 3.16075V9.73077C2 11.4594 3.39872 12.773 4.99986 13.6967C5.78065 14.1472 6.56559 14.4785 7.15781 14.6975C7.45311 14.8067 7.69855 14.8873 7.86904 14.9403C7.91953 14.9559 7.9634 14.9692 8 14.98C8.0366 14.9692 8.08047 14.9559 8.13096 14.9403C8.30144 14.8873 8.54688 14.8067 8.84219 14.6975C9.43441 14.4785 10.2193 14.1472 11.0001 13.6967C12.6013 12.773 14 11.4594 14 9.73077V3.16075L8 1.03058L2 3.16075ZM8 15.9998L7.871 15.9831C7.9553 16.0055 8.0452 16.0054 8.1295 15.9829L8 15.9998Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
