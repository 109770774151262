import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgFolderInverted = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            d="M2.50195 1.00195C1.12124 1.00195 0.00195312 2.12124 0.00195312 3.50195V12.502C0.00195312 13.8827 1.12124 15.002 2.50195 15.002H13.502C14.8827 15.002 16.002 13.8827 16.002 12.502V4.51312C16.002 3.6847 15.3304 3.01312 14.502 3.01312H9.15805C8.97893 3.01312 8.81349 2.91731 8.72435 2.76194L8.14694 1.7555C7.87953 1.2894 7.38322 1.00195 6.84586 1.00195H2.50195Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
