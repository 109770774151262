import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLHeaderCols3Footer = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1 .499847C1 .223789 1.22386 0 1.5 0H14.5C14.7761 0 15 .223789 15 .499847V2.49924C15 2.7753 14.7761 2.99908 14.5 2.99908H1.5C1.22386 2.99908 1 2.7753 1 2.49924V.499847ZM2 .999695V1.99939H14V.999695H2ZM1 4.48838C1 4.21232 1.22386 3.98853 1.5 3.98853H4.5C4.77614 3.98853 5 4.21232 5 4.48838V11.4891C5 11.7652 4.77614 11.989 4.5 11.989H1.5C1.22386 11.989 1 11.7652 1 11.4891V4.48838ZM2 4.98822V10.9893H4V4.98822H2ZM6 4.48838C6 4.21232 6.22386 3.98853 6.5 3.98853H9.5C9.77614 3.98853 10 4.21232 10 4.48838V11.4891C10 11.7652 9.77614 11.989 9.5 11.989H6.5C6.22386 11.989 6 11.7652 6 11.4891V4.48838ZM7 4.98822V10.9893H9V4.98822H7ZM11.5 3.98853C11.2239 3.98853 11 4.21232 11 4.48838V11.4891C11 11.7652 11.2239 11.989 11.5 11.989H14.5C14.7761 11.989 15 11.7652 15 11.4891V4.48838C15 4.21232 14.7761 3.98853 14.5 3.98853H11.5ZM12 10.9893V4.98822H14V10.9893H12ZM1.5 13.0009C1.22386 13.0009 1 13.2247 1 13.5008V15.5002C1 15.7762 1.22386 16 1.5 16H14.5C14.7761 16 15 15.7762 15 15.5002V13.5008C15 13.2247 14.7761 13.0009 14.5 13.0009H1.5ZM2 15.0003V14.0006H14V15.0003H2Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
