import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLCols3070 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M.50026 2C.223974 2 0 2.22395 0 2.5002V13.4998C0 13.7761.223974 14 .50026 14H3.50182C3.7781 14 4.00208 13.7761 4.00208 13.4998V2.5002C4.00208 2.22395 3.7781 2 3.50182 2H.50026ZM1.00052 12.9996V3.00039H3.00156V12.9996H1.00052ZM6.49507 2C6.21878 2 5.99481 2.22395 5.99481 2.5002V13.4998C5.99481 13.7761 6.21878 14 6.49507 14H15.4997C15.776 14 16 13.7761 16 13.4998V2.5002C16 2.22395 15.776 2 15.4997 2H6.49507ZM6.99533 12.9996V3.00039H14.9995V12.9996H6.99533Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
