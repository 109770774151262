import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgH5 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M0 2V15H1V9H6V15H7V2H6V8H1V2H0ZM10.6595 14.5679C11.1607 14.856 11.7287 15 12.3636 15 13.0652 15 13.6889 14.8163 14.2347 14.4489 14.7841 14.0816 15.2148 13.5806 15.5267 12.946 15.8422 12.3073 16 11.583 16 10.7731 16 9.94653 15.8422 9.21596 15.5267 8.58141 15.2148 7.94268 14.7897 7.44172 14.2514 7.07852 13.7168 6.71532 13.1135 6.53372 12.4415 6.53372 12.0369 6.53372 11.6526 6.60886 11.2888 6.75915 11.2065 6.79349 11.1109 6.83012 11.0092 6.86903 10.6661 7.00044 10.2548 7.15793 10.06 7.34152H10V3H15.5V2H9V8.23073L10 8.38102C10.2049 8.18901 10.6296 8.02619 10.9781 7.89256 11.0463 7.8664 11.1117 7.84136 11.1718 7.81744 11.5394 7.67132 11.9143 7.60035 12.2967 7.60453 12.8016 7.60453 13.2564 7.74021 13.6611 8.01156 14.0695 8.28292 14.3925 8.65864 14.6301 9.13873 14.8677 9.61464 14.9883 10.1657 14.992 10.7919 14.992 11.3931 14.8788 11.9337 14.6523 12.4138 14.4296 12.8897 14.1196 13.2654 13.7224 13.5409 13.3251 13.8123 12.8722 13.948 12.3636 13.948 11.7102 13.948 11.1626 13.7309 10.7208 13.2967 10.063 12.5697 10.0333 11.8236 10.0078 11.1816 10.0053 11.1201 10.0029 11.0595 10 11H9C9.00146 11.0516 9.00269 11.1044 9.00395 11.1583 9.01865 11.7886 9.03684 12.5682 9.46778 13.3656 9.76478 13.8749 10.162 14.2757 10.6595 14.5679Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
