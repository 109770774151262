import React, { ComponentProps } from 'react';
import { Box } from '../../layout/Box/Box.tsx';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';

export const ButtonLabel = React.forwardRef<HTMLSpanElement, ComponentProps<typeof Box>>(
  (props, forwardedRef) => {
    const { children, ...otherProps } = props;

    return (
      <Box
        component="span"
        ref={forwardedRef}
        {...otherProps}
        {...getDataUiComponentAttribute(ButtonLabel)}
      >
        {children}
      </Box>
    );
  },
);

ButtonLabel.displayName = 'ButtonLabel';
