import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgBreadcrumb = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M11.1678 11.8737C11.3742 12.0572 11.6903 12.0386 11.8737 11.8322L15.8737 7.33219C16.0421 7.14274 16.0421 6.85727 15.8737 6.66782L11.8737 2.16782C11.6903 1.96143 11.3742 1.94284 11.1678 2.1263 10.9614 2.30976 10.9428 2.6258 11.1263 2.83219L14.831 7.00001 11.1263 11.1678C10.9428 11.3742 10.9614 11.6903 11.1678 11.8737ZM.167824 11.8738C.374215 12.0572.690252 12.0386.873711 11.8322L4.87371 7.33223C5.0421 7.14279 5.0421 6.85731 4.87371 6.66787L.87371 2.16787C.690251 1.96147.374215 1.94288.167824 2.12634-.0385687 2.3098-.0571577 2.62584.126301 2.83223L3.83103 7.00005.126301 11.1679C-.0571581 11.3743-.0385672 11.6903.167824 11.8738ZM6.00391 14H7.00391V13H6.00391V14ZM5.00489 14H4.00489V13H5.00489V14ZM8.00025 14H9.00025V13H8.00025V14Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
