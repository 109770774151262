import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgCollapseScheme = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M14.9952 2.54249V4.99715H15.0024V6.99547H14.0027V6.00319H2.00561L2.00561 6.99547H1.00586L1.00586 6.00319H1.00244V2.54249C1.00244 1.69346 1.62932 1.00439 2.40172 1.00439H13.596C14.3684 1.00439 14.9952 1.69346 14.9952 2.54249ZM13.9955 5.00403H2.00561V4.99715H2.0022V2.54249C2.0022 2.15367 2.26888 2.00356 2.40172 2.00356H13.596C13.7288 2.00356 13.9955 2.15366 13.9955 2.54249V5.00403Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M1.00625 14.0052H3.00537L3.00498 15.0044H1.50732C1.37433 15.0044 1.24678 14.938 1.15273 14.8198 1.05869 14.7016 1.00586 14.5412 1.00586 14.3741L1.00625 14.0052ZM13.0029 13.9964H15.0021L15.0024 14.3653C15.0024 14.5325 14.9496 14.6928 14.8556 14.811 14.7615 14.9292 14.634 14.9956 14.501 14.9956H13.0033L13.0029 13.9964ZM1.00586 11.0039 1.00586 13.0022H2.00561L2.00561 11.0039H1.00586ZM14.0027 13.0022V11.0039H15.0024V13.0022H14.0027ZM1.00586 7.99464 1.00586 9.99296H2.00561L2.00561 7.99464H1.00586ZM14.0027 7.99464V9.99296H15.0024V7.99464H14.0027ZM6.00464 14.0052H4.00513L4.00513 15.0044H6.00464V14.0052ZM7.00439 14.0052H9.00391V15.0044H7.00439V14.0052ZM12.0032 14.0052H10.0037V15.0044H12.0032V14.0052Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
