import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgMonitorSmartphone = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1.16581 2.16612C1.07232 2.25733 0.999664 2.40354 0.999664 2.62353V9.36741C0.999664 9.5874 1.07232 9.73361 1.16581 9.82482C1.26039 9.91708 1.41636 9.99185 1.652 9.99185H7.26252C7.30266 9.99185 7.3417 9.99658 7.3791 10.0055H7.49748C7.77353 10.0055 7.99732 10.2292 7.99732 10.5051C7.99732 10.7809 7.77353 11.0046 7.49748 11.0046H6.28511C6.25769 11.1854 6.20289 11.3974 6.10202 11.6317C5.92259 12.0486 5.60564 12.518 5.06695 13.0023H7.48858C7.76463 13.0023 7.98841 13.226 7.98841 13.5019C7.98841 13.7778 7.76463 14.0014 7.48858 14.0014H3.49883C3.27215 14.0014 3.07385 13.849 3.01571 13.63C2.95758 13.411 3.05416 13.1804 3.25101 13.068C4.51118 12.349 4.99574 11.6737 5.18372 11.2369C5.22268 11.1464 5.24988 11.064 5.26873 10.9909H1.652C1.19634 10.9909 0.776227 10.8409 0.467546 10.5398C0.157783 10.2376 0 9.82181 0 9.36741V2.62353C0 2.16913 0.157783 1.75335 0.467546 1.45116C0.776227 1.15003 1.19634 1 1.652 1H13.343C13.7986 1 14.2187 1.15003 14.5274 1.45116C14.8372 1.75335 14.995 2.16913 14.995 2.62353V3.46651C14.995 3.74241 14.7712 3.96606 14.4951 3.96606C14.2191 3.96606 13.9953 3.74241 13.9953 3.46651V2.62353C13.9953 2.40354 13.9227 2.25733 13.8292 2.16612C13.7346 2.07386 13.5786 1.99909 13.343 1.99909H1.652C1.41636 1.99909 1.26039 2.07386 1.16581 2.16612Z"
        />
        <path
          fill="currentColor"
          d="M11.4938 7.00407C11.2178 7.00407 10.994 7.22772 10.994 7.50361C10.994 7.7795 11.2178 8.00316 11.4938 8.00316H13.4931C13.7692 8.00316 13.993 7.7795 13.993 7.50361C13.993 7.22772 13.7692 7.00406 13.4931 7.00407H11.4938Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M10.3908 5.00906C9.60839 5.00906 9.00235 5.66566 9.00235 6.43085V13.5782C9.00235 14.3434 9.60839 15 10.3908 15H14.6116C15.394 15 16 14.3434 16 13.5782V6.43085C16 5.66566 15.394 5.00906 14.6116 5.00906H10.3908ZM10.002 6.43085C10.002 6.18157 10.1957 6.00815 10.3908 6.00815H14.6116C14.8066 6.00815 15.0003 6.18157 15.0003 6.43085V13.5782C15.0003 13.8275 14.8066 14.0009 14.6116 14.0009H10.3908C10.1957 14.0009 10.002 13.8275 10.002 13.5782V6.43085Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
