import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLCols3 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M0 2.5C0 2.22386.224152 2 .500657 2H3.5046C3.7811 2 4.00526 2.22386 4.00526 2.5V13.5C4.00526 13.7761 3.7811 14 3.5046 14H.500657C.224152 14 0 13.7761 0 13.5V2.5ZM1.00131 3V13H3.00394V3H1.00131ZM5.97904 2.5C5.97904 2.22386 6.20319 2 6.47969 2H9.48364C9.76014 2 9.98429 2.22386 9.98429 2.5V13.5C9.98429 13.7761 9.76014 14 9.48364 14H6.47969C6.20319 14 5.97904 13.7761 5.97904 13.5V2.5ZM6.98035 3V13H8.98298V3H6.98035ZM12.4954 2C12.2189 2 11.9947 2.22386 11.9947 2.5V13.5C11.9947 13.7761 12.2189 14 12.4954 14H15.4993C15.7758 14 16 13.7761 16 13.5V2.5C16 2.22386 15.7758 2 15.4993 2H12.4954ZM12.9961 13V3H14.9987V13H12.9961Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
