import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLayout = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1 1.5C1 1.22386 1.22386 1 1.5 1H14.5C14.7761 1 15 1.22386 15 1.5L15 6.5C15 6.63261 14.9473 6.75979 14.8536 6.85355 14.7598 6.94732 14.6326 7 14.5 7H1.5C1.22386 7 1 6.77614 1 6.5V1.5ZM2 2V6H14L14 2H2ZM1 8.5C1 8.22386 1.22386 8 1.5 8H5.5C5.77614 8 6 8.22386 6 8.5V14.4999C6 14.7761 5.77614 14.9999 5.5 14.9999H1.5C1.22386 14.9999 1 14.7761 1 14.4999V8.5ZM2 9V13.9999H5V9H2ZM7.5 8C7.22386 8 7 8.22386 7 8.5V14.5C7 14.7761 7.22386 15 7.5 15H14.5C14.7761 15 15 14.7761 15 14.5V8.5C15 8.22386 14.7761 8 14.5 8H7.5ZM8 14V9H14V14H8Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
