import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgBoxes = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M3.5 9C3.22386 9 3 9.22386 3 9.5 3 9.77614 3.22386 10 3.5 10H6.5C6.77614 10 7 9.77614 7 9.5 7 9.22386 6.77614 9 6.5 9H3.5ZM4.5 2C4.22386 2 4 2.22386 4 2.5 4 2.77614 4.22386 3 4.5 3H5.5C5.77614 3 6 2.77614 6 2.5 6 2.22386 5.77614 2 5.5 2H4.5Z" />
          <path
            fillRule="evenodd"
            d="M0.996094 0.496094C0.996094 0.219951 1.21995 -0.00390625 1.49609 -0.00390625H8.49609C8.77224 -0.00390625 8.99609 0.219951 8.99609 0.496094V5.99585H9.49805C9.77419 5.99585 9.99805 6.21971 9.99805 6.49585V7.99634H15.502C15.7781 7.99634 16.002 8.2202 16.002 8.49634V15.4963C16.002 15.7725 15.7781 15.9963 15.502 15.9963H9.50195L9.48858 15.9962L9.47966 15.9958H0.498047C0.221904 15.9958 -0.00195312 15.772 -0.00195312 15.4958V6.49585C-0.00195312 6.21971 0.221904 5.99585 0.498047 5.99585H0.996094V0.496094ZM1.49609 6.99609L1.48032 6.99585H0.998047V14.9958H8.99805V6.99585H8.51187L8.49609 6.99609H1.49609ZM7.99609 0.996094V5.99585H1.99609V0.996094H7.99609ZM10.002 14.9963V11H12.5C12.7761 11 13 10.7761 13 10.5C13 10.2239 12.7761 10 12.5 10H10.002V8.99634H15.002V14.9963H10.002Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
