import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgCheck = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M14.8332 2.13877C15.0391 2.33951 15.0568 2.68433 14.8728 2.90894L5.9353 13.818C5.84296 13.9307 5.71188 13.9966 5.57335 13.9999C5.43481 14.0032 5.30124 13.9436 5.2045 13.8353L1.142 9.28987C0.949225 9.07418 0.953232 8.72885 1.15095 8.51855C1.34867 8.30825 1.66523 8.31262 1.858 8.52831L5.54661 12.6554L14.1272 2.18198C14.3112 1.95737 14.6273 1.93802 14.8332 2.13877Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
