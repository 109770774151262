import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgDocMove = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M7.18649 8.14645C6.99122 8.34171 6.99122 8.65829 7.18649 8.85355L8.33293 10H4.54004C4.2639 10 4.04004 10.2239 4.04004 10.5C4.04004 10.7761 4.2639 11 4.54004 11H8.33293L7.18649 12.1464C6.99122 12.3417 6.99122 12.6583 7.18649 12.8536C7.38175 13.0488 7.69833 13.0488 7.89359 12.8536L9.89359 10.8536C10.0889 10.6583 10.0889 10.3417 9.89359 10.1464L7.89359 8.14645C7.69833 7.95118 7.38175 7.95118 7.18649 8.14645Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M9.2 0H3C2.44772 0 2 0.447715 2 1V15C2 15.5523 2.44772 16 3 16H13C13.5523 16 14 15.5523 14 15V4.57143L9.2 0ZM3 15H13V5.99745H9.54004C8.71161 5.99745 8.04004 5.32588 8.04004 4.49745V1H3L3 15ZM9.04004 1.22861L12.9973 4.99745H9.54004C9.2639 4.99745 9.04004 4.7736 9.04004 4.49745V1.22861Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
