import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgBuildingBlock = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M8.5043 1.00244C8.22834 1.00244 8.00464 1.2263 8.00464 1.50244V7.50244C8.00464 7.77858 8.22834 8.00244 8.5043 8.00244H14.5003C14.7763 8.00244 15 7.77858 15 7.50244V1.50244C15 1.2263 14.7763 1.00244 14.5003 1.00244H8.5043ZM14.0007 7.00244H9.00397V2.00244H14.0007V7.00244Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M1.49967 4.00244C1.22371 4.00244 1 4.2263 1 4.50244V14.5024C1 14.7786 1.22371 15.0024 1.49967 15.0024H11.493C11.769 15.0024 11.9927 14.7786 11.9927 14.5024V9.48711C11.9927 9.21096 11.769 8.98711 11.493 8.98711C11.2171 8.98711 10.9934 9.21096 10.9934 9.48711V14.0024H1.99934V5.00244H6.48696C6.76292 5.00244 6.98663 4.77858 6.98663 4.50244C6.98663 4.2263 6.76292 4.00244 6.48696 4.00244H1.49967Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
