import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgExclamationTriangleInverted = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M8 1C8.52344 1 8.79755 1.59538 8.89431 1.7889L15.3944 14.5528C15.6414 15.0468 15.4412 15.6475 14.9472 15.8945C14.8084 15.9639 14.6552 16 14.5 16H1.5C0.947715 16 0.5 15.5523 0.5 15C0.5 14.8448 0.536145 14.6917 0.605573 14.5528L7.10546 1.7889C7.35245 1.29492 7.47656 1 8 1ZM7.99989 11.5C7.17146 11.5 6.49989 12.1716 6.49989 13C6.49989 13.8285 7.17146 14.5 7.99989 14.5C8.82831 14.5 9.49989 13.8285 9.49989 13C9.49989 12.1716 8.82831 11.5 7.99989 11.5ZM9.49989 6.00005H6.49989L6.99989 10H8.99989L9.49989 6.00005Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
