import styled, { css } from 'styled-components';
import { offsetFocus } from '../../../tokens/decision/focus.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { getButtonBadgeVariables } from '../ButtonBadge.tsx';
import { ButtonSize } from '../buttonSize.ts';
import { ButtonStyle } from '../buttonStyle.ts';
import { getTokens } from '../utils/stylingUtils.ts';
import { IBaseButtonComponentProps } from './BaseButtonComponent.tsx';
import { getBadgeSpacing } from './StyledButtonBadgePositioner.tsx';

interface IStyledButtonWrapperProps extends Pick<IBaseButtonComponentProps, 'buttonDisplay'> {
  readonly isFocusVisible: boolean;
  readonly $buttonStyle: ButtonStyle;
  readonly $size: ButtonSize;
  readonly $activated?: boolean;
  readonly $destructive?: boolean;
  readonly $disabled?: boolean;
}

export const StyledButtonWrapper = styled.div.attrs(getTokens)<IStyledButtonWrapperProps>`
  ${getBadgeSpacing};
  ${getButtonBadgeVariables};

  position: relative;
  border-radius: ${px(BorderRadius.Pill)};

  &::before {
    content: '';
    position: absolute;
    inset: ${px(-1 * offsetFocus)};
    border-radius: ${px(BorderRadius.Pill)};
    ${({ isFocusVisible }) => isFocusVisible && shadowFocusStyles};
  }

  ${(props) =>
    props.buttonDisplay === 'block'
      ? css`
        display: block;
        width: 100%;
      `
      : css`display: inline-block;`};`;
