import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgArrowsCorners = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M2.71397 2.00684H5.50708C5.78322 2.00684 6.00708 1.78298 6.00708 1.50684C6.00708 1.23069 5.78322 1.00684 5.50708 1.00684L1.58263 1.00684C1.42874 0.981185 1.26517 1.02772 1.14644 1.14645C1.02735 1.26555 0.980894 1.42978 1.00708 1.58408L1.00708 5.50684C1.00708 5.78298 1.23094 6.00684 1.50708 6.00684C1.78322 6.00684 2.00708 5.78298 2.00708 5.50684V2.71416L7.29202 7.99895L2.00708 13.2837V10.511C2.00708 10.2349 1.78322 10.011 1.50708 10.011C1.23094 10.011 1.00708 10.2349 1.00708 10.511L1.00708 14.4138C0.988193 14.5251 1.00709 14.6416 1.06378 14.7425C1.1473 14.9021 1.31447 15.011 1.50708 15.011H5.50708C5.78322 15.011 6.00708 14.7872 6.00708 14.511C6.00708 14.2349 5.78322 14.011 5.50708 14.011H2.694L7.99913 8.70605L13.301 14.0078H10.4979C10.2218 14.0078 9.99793 14.2317 9.99793 14.5078C9.99793 14.784 10.2218 15.0078 10.4979 15.0078H14.4979C14.7741 15.0078 14.9979 14.784 14.9979 14.5078V10.5078C14.9979 10.2317 14.7741 10.0078 14.4979 10.0078C14.2218 10.0078 13.9979 10.2317 13.9979 10.5078V13.2905L8.70625 7.99895L14.0222 2.68316V5.50684C14.0222 5.78298 14.246 6.00684 14.5222 6.00684C14.7983 6.00684 15.0222 5.78298 15.0222 5.50684V1.50684C15.0222 1.27062 14.8584 1.07266 14.6382 1.02036C14.5664 0.999458 14.4909 0.994952 14.4175 1.00684L10.5222 1.00684C10.246 1.00684 10.0222 1.23069 10.0222 1.50684C10.0222 1.78298 10.246 2.00684 10.5222 2.00684H13.2843L7.99913 7.29185L2.71397 2.00684Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
