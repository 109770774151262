import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgMoneyBill = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
          <path d="M8 5.75C6.75736 5.75 5.75 6.75736 5.75 8C5.75 9.24264 6.75736 10.25 8 10.25C9.24264 10.25 10.25 9.24264 10.25 8C10.25 6.75736 9.24264 5.75 8 5.75ZM6.75 8C6.75 7.30964 7.30964 6.75 8 6.75C8.69036 6.75 9.25 7.30964 9.25 8C9.25 8.69036 8.69036 9.25 8 9.25C7.30964 9.25 6.75 8.69036 6.75 8Z" />
          <path d="M0.5 3C0.223858 3 4.05472e-07 3.22386 3.93402e-07 3.5L0 12.5C-5.89217e-09 12.6348 0.0533419 12.7571 0.140068 12.8471L0.146447 12.8536L0.155454 12.8623C0.243019 12.9456 0.360743 12.9975 0.490561 12.9999L0.500001 13H11.4987L11.5 13L15.5 13L15.5094 12.9999C15.6383 12.9975 15.7553 12.9464 15.8426 12.8641L15.8536 12.8536L15.8641 12.8426C15.9484 12.7531 16 12.6326 16 12.5V3.5C16 3.22386 15.7761 3 15.5 3L0.5 3ZM1 12L1 10H3.0462C3.57297 10 4 10.427 4 10.9538V12L1 12ZM5 10.9538V12L11 12V10.9538C11 9.87475 11.8747 9 12.9538 9L15 9V7L12.9533 7C11.8743 7 10.9995 6.12525 10.9995 5.0462L10.9995 4H5V5.0462C5 6.12525 4.12525 7 3.0462 7L1 7L1 9H3.0462C4.12525 9 5 9.87475 5 10.9538ZM12 12L15 12V10L12.9538 10C12.427 10 12 10.427 12 10.9538L12 12ZM11.9995 4L14.9995 4V6H12.9533C12.4265 6 11.9995 5.57297 11.9995 5.0462L11.9995 4ZM4 4H1L1 6H3.0462C3.57297 6 4 5.57297 4 5.0462V4Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" transform="rotate(90 8 8)" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
