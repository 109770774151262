import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgCSharp = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M3.05058 3.04835C5.78911 0.312044 10.2165 0.312044 12.9551 3.04835C13.1504 3.24353 13.4671 3.24353 13.6624 3.04835C13.8577 2.85318 13.8577 2.53675 13.6624 2.34157C10.5332 -0.785082 5.47242 -0.785082 2.34323 2.34157C-0.785959 5.46823 -0.785959 10.5249 2.34323 13.6516C5.47242 16.7782 10.5332 16.7782 13.6624 13.6516C13.8577 13.4564 13.8577 13.14 13.6624 12.9448C13.4671 12.7496 13.1504 12.7496 12.9551 12.9448C10.2165 15.6811 5.78911 15.6811 3.05058 12.9448C0.312056 10.2085 0.312056 5.78467 3.05058 3.04835Z" />
          <path
            fillRule="evenodd"
            d="M11.5393 5.00534C11.8156 5.00757 12.0377 5.23312 12.0355 5.50913L12.0314 6.01035L14.0216 5.99977L14.023 5.50374C14.0237 5.22772 14.2483 5.00458 14.5245 5.00532C14.8008 5.00607 15.0241 5.23043 15.0233 5.50645L15.022 5.99445L15.4934 5.99194C15.7697 5.99047 15.9948 6.21303 15.9963 6.48905C15.9977 6.76506 15.775 6.99 15.4988 6.99147L15.0193 6.99402L15.0139 9.00405L15.4962 9.00406C15.7724 9.00407 15.9963 9.22783 15.9963 9.50384C15.9963 9.77986 15.7724 10.0036 15.4962 10.0036L15.0111 10.0036L15.0098 10.5041C15.009 10.7801 14.7845 11.0033 14.5083 11.0025C14.232 11.0018 14.0087 10.7774 14.0094 10.5014L14.0108 10.0036L11.9991 10.0035L11.9951 10.5067C11.9928 10.7827 11.7671 11.0046 11.4909 11.0024C11.2147 11.0002 10.9925 10.7746 10.9948 10.4986L10.9988 10.0035L10.4944 10.0035C10.2182 10.0035 9.99423 9.77975 9.99424 9.50373C9.99424 9.22771 10.2182 9.00396 10.4944 9.00397L11.0068 9.00398L11.0229 7.01526L10.4971 7.01806C10.2208 7.01953 9.99572 6.79697 9.99425 6.52096C9.99278 6.24494 10.2155 6.02 10.4918 6.01853L11.031 6.01567L11.0351 5.50106C11.0374 5.22505 11.2631 5.00311 11.5393 5.00534ZM14.0189 6.99933L14.0135 9.00404L12.0072 9.004L12.0233 7.00994L14.0189 6.99933Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
