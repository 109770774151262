import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgBubbleCheck = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 17"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M11.7169 4.35355C11.9122 4.15829 11.9122 3.84171 11.7169 3.64645C11.5216 3.45118 11.2051 3.45118 11.0098 3.64645L6.55901 8.09724L4.85355 6.39179C4.65829 6.19652 4.34171 6.19652 4.14645 6.39179C3.95118 6.58705 3.95118 6.90363 4.14645 7.09889L6.20545 9.1579C6.40071 9.35316 6.7173 9.35316 6.91256 9.1579L11.7169 4.35355Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M2.3182 0.00341797C1.04206 0.00341797 2.46763e-05 1.04546 2.46763e-05 2.3216L0 10.6982C0 11.9774 1.0451 13.0078 2.31818 13.0078H3.99776V15.5034C3.99776 15.6986 4.11132 15.8759 4.28859 15.9576C4.46587 16.0392 4.67444 16.0102 4.82276 15.8834L8.18465 13.0078H13.6818C14.9549 13.0078 16 11.9774 16 10.6982V2.3216C16 1.04546 14.958 0.00341797 13.6818 0.00341797H2.3182ZM1.00002 2.3216C1.00002 1.59774 1.59435 1.00342 2.3182 1.00342H13.6818C14.4057 1.00342 15 1.59774 15 2.3216V10.6982C15 11.419 14.4087 12.0078 13.6818 12.0078H7.99999C7.88081 12.0078 7.76555 12.0504 7.67499 12.1278L4.99776 14.4178V12.5078C4.99776 12.2317 4.7739 12.0078 4.49776 12.0078H2.31818C1.59126 12.0078 1 11.419 1 10.6982L1.00002 2.3216Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
