import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgPerfume = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M4.98101 11.5215C4.98101 11.2453 5.20529 11.0215 5.48196 11.0215H10.5188C10.7954 11.0215 11.0197 11.2453 11.0197 11.5215C11.0197 11.7976 10.7954 12.0215 10.5188 12.0215H5.48196C5.20529 12.0215 4.98101 11.7976 4.98101 11.5215Z" />
          <path
            fillRule="evenodd"
            d="M5.51806 16.001C3.58658 16.001 2.01875 14.4421 2.01141 12.5143L2.00003 9.52698C1.99332 7.76754 3.2882 6.30732 4.97982 6.05292V3.50579C4.97982 2.67737 5.65267 2.00579 6.48268 2.00579H9.48841C10.3184 2.00579 10.9913 2.67737 10.9913 3.50579V6.05033C12.6818 6.29588 13.9819 7.74523 13.9886 9.50036L14 12.4877C14.0074 14.4258 12.4352 16.001 10.4933 16.001H5.51806ZM3.01331 12.5105C3.01856 13.8875 4.13844 15.001 5.51806 15.001H10.4933C11.8804 15.001 13.0033 13.8759 12.9981 12.4915L12.9867 9.50417C12.9815 8.13169 11.8689 7.02095 10.4955 7.01371L10.4915 7.01367H9.51872C9.24205 7.01367 9.01776 6.78981 9.01776 6.51367C9.01776 6.23753 9.24205 6.01367 9.51872 6.01367H9.98936V3.50579C9.98936 3.22965 9.76507 3.00579 9.48841 3.00579H6.48268C6.20601 3.00579 5.98172 3.22965 5.98172 3.50579V6.01367H7.49974C7.77641 6.01367 8.00069 6.23753 8.00069 6.51367C8.00069 6.78981 7.7764 7.01367 7.49974 7.01367H5.49592C5.49343 7.01367 5.49094 7.01365 5.48845 7.01362C5.48509 7.01372 5.48173 7.01379 5.47835 7.01383C4.10442 7.02896 2.99669 8.14819 3.00193 9.52318L3.01331 12.5105Z"
            clipRule="evenodd"
          />
          <path d="M7.49926 0C7.22259 0 6.9983 0.223858 6.9983 0.5C6.9983 0.776142 7.22259 1 7.49926 1H8.50116C8.77783 1 9.00212 0.776142 9.00212 0.5C9.00212 0.223858 8.77783 0 8.50116 0H7.49926Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" transform="matrix(1 0 0 -1 0 16)" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
