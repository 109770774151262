import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgDocUser = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M5.81152 8.79196C5.81152 7.57199 6.8005 6.58301 8.02047 6.58301C9.24044 6.58301 10.2294 7.57199 10.2294 8.79196C10.2294 9.43076 9.95827 10.0062 9.52478 10.4095C10.4336 10.9522 11.0088 11.9851 11.0088 13.2015V13.5016C11.0088 13.7777 10.7849 14.0016 10.5088 14.0016C10.2326 14.0016 10.0088 13.7777 10.0088 13.5016V13.2015C10.0088 11.8825 9.1276 11.001 8.00879 11.001C6.88998 11.001 6.00879 11.8825 6.00879 13.2015V13.5016C6.00879 13.7777 5.78493 14.0016 5.50879 14.0016C5.23265 14.0016 5.00879 13.7777 5.00879 13.5016V13.2015C5.00879 11.9787 5.59005 10.9413 6.5071 10.4011C6.0789 9.99817 5.81152 9.42629 5.81152 8.79196ZM8.02047 7.58301C7.35279 7.58301 6.81152 8.12427 6.81152 8.79196C6.81152 9.45964 7.35279 10.0009 8.02047 10.0009C8.68816 10.0009 9.22942 9.45964 9.22942 8.79196C9.22942 8.12427 8.68816 7.58301 8.02047 7.58301Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M9.20439 0H3.00439C2.45211 0 2.00439 0.447715 2.00439 1V15C2.00439 15.5523 2.45211 16 3.00439 16H13.0044C13.5567 16 14.0044 15.5523 14.0044 15V4.57143L9.20439 0ZM13.0044 15V5.99106H9.50439C8.67597 5.99106 8.00439 5.31948 8.00439 4.49106V1H3.00439L3.00439 15H13.0044ZM9.00439 1.19048L12.995 4.99106H9.50439C9.22825 4.99106 9.00439 4.7672 9.00439 4.49106V1.19048Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
