import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgX = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M2.8817 1.17704C2.70333 0.966236 2.38784 0.939945 2.17704 1.11832C1.96624 1.29669 1.93995 1.61218 2.11832 1.82298L7.34503 8.00001L2.11832 14.177C1.93995 14.3878 1.96624 14.7033 2.17704 14.8817C2.38784 15.0601 2.70333 15.0338 2.8817 14.823L8.00001 8.77407L13.1183 14.823C13.2967 15.0338 13.6122 15.0601 13.823 14.8817C14.0338 14.7033 14.0601 14.3878 13.8817 14.177L8.65499 8.00001L13.8817 1.82298C14.0601 1.61218 14.0338 1.29669 13.823 1.11832C13.6122 0.939945 13.2967 0.966236 13.1183 1.17704L8.00001 7.22595L2.8817 1.17704Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
