import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgDrawers = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M5.99951 4.49477C5.99951 4.21863 6.22337 3.99477 6.49951 3.99477H9.49951C9.77565 3.99477 9.99951 4.21863 9.99951 4.49477 9.99951 4.77091 9.77565 4.99477 9.49951 4.99477H6.49951C6.22337 4.99477 5.99951 4.77091 5.99951 4.49477ZM6.50293 9.99711C6.22679 9.99711 6.00293 10.221 6.00293 10.4971 6.00293 10.7733 6.22679 10.9971 6.50293 10.9971H9.50293C9.77907 10.9971 10.0029 10.7733 10.0029 10.4971 10.0029 10.221 9.77907 9.99711 9.50293 9.99711H6.50293Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.24282 7.49394C1.08068 7.26797 1.00195 6.99254 1.00195 6.70457V2.3007C1.00195 1.94925 1.11922 1.61646 1.36229 1.36948C1.60606 1.12178 1.93751 1 2.28996 1H13.716C14.0685 1 14.3999 1.12178 14.6437 1.36948C14.8868 1.61646 15.004 1.94925 15.004 2.3007V6.70457C15.004 6.99258 14.9253 7.26805 14.7631 7.49404C14.9239 7.71526 15.0029 7.98524 15.0029 8.26847V13.7207C15.0029 14.0697 14.8829 14.3986 14.6378 14.6409C14.3932 14.8828 14.0633 14.9996 13.7151 14.9996H2.29081C1.94253 14.9996 1.61269 14.8828 1.36808 14.6409C1.12295 14.3986 1.00293 14.0697 1.00293 13.7207L1.00293 8.26847C1.00293 7.9852 1.08199 7.71518 1.24282 7.49394ZM2.00195 2.3007C2.00195 2.17174 2.04229 2.10417 2.07502 2.07091C2.10705 2.03836 2.16961 2 2.28996 2H13.716C13.8364 2 13.8989 2.03836 13.931 2.07091C13.9637 2.10417 14.004 2.17174 14.004 2.3007V6.70457C14.004 6.83354 13.9637 6.90111 13.931 6.93437C13.9101 6.95563 13.8761 6.97937 13.8215 6.99328C13.7863 6.99082 13.7508 6.98959 13.7151 6.98959H2.29081C2.25514 6.98959 2.21966 6.99081 2.18445 6.99327C2.12988 6.97936 2.09594 6.95562 2.07502 6.93437C2.04229 6.90111 2.00195 6.83354 2.00195 6.70457V2.3007ZM2.18283 8.00147C2.12622 8.0154 2.09156 8.03914 2.07111 8.05936C2.04048 8.08964 2.00293 8.15017 2.00293 8.26847V13.7207C2.00293 13.839 2.04048 13.8995 2.07111 13.9298C2.10226 13.9606 2.16636 13.9996 2.29081 13.9996H13.7151C13.8395 13.9996 13.9036 13.9606 13.9347 13.9298C13.9654 13.8995 14.0029 13.839 14.0029 13.7207V8.26847C14.0029 8.15017 13.9654 8.08964 13.9347 8.05936C13.9143 8.03915 13.8797 8.01541 13.8231 8.00148C13.7877 8.00401 13.752 8.00528 13.716 8.00528H2.28996C2.25401 8.00528 2.21828 8.00401 2.18283 8.00147Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
