import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgPicture = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M5.66667 8C6.95533 8 8 6.95533 8 5.66667C8 4.378 6.95533 3.33333 5.66667 3.33333C4.378 3.33333 3.33333 4.378 3.33333 5.66667C3.33333 6.95533 4.378 8 5.66667 8ZM5.66667 7C6.40305 7 7 6.40305 7 5.66667C7 4.93029 6.40305 4.33333 5.66667 4.33333C4.93029 4.33333 4.33333 4.93029 4.33333 5.66667C4.33333 6.40305 4.93029 7 5.66667 7Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3 1C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15H13C14.1046 15 15 14.1046 15 13V3C15 1.89543 14.1046 1 13 1H3ZM13 2H3C2.44772 2 2 2.44772 2 3V12.1262L4.72978 9.39645C4.82355 9.30268 4.95073 9.25 5.08333 9.25C5.21594 9.25 5.34312 9.30268 5.43689 9.39645L7.41667 11.3762L11.7298 7.06311C11.8235 6.96935 11.9507 6.91667 12.0833 6.91667C12.2159 6.91667 12.3431 6.96935 12.4369 7.06311L14 8.62623V3C14 2.44772 13.5523 2 13 2ZM14 10.0404L12.0833 8.12377L7.77022 12.4369C7.67645 12.5307 7.54927 12.5833 7.41667 12.5833C7.28406 12.5833 7.15688 12.5307 7.06311 12.4369L5.08333 10.4571L2.10132 13.4391C2.26392 13.7713 2.60525 14 3 14H13C13.5523 14 14 13.5523 14 13V10.0404Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
