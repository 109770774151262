import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Inline } from '../../layout/Inline/Inline.tsx';
import { colorTextDefault, colorTextDefaultInverse } from '../../tokens/decision/colors.ts';
import { Typography } from '../../tokens/quarks/typography.ts';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { ShortcutKey } from './components/ShortcutKey.tsx';
import { ShortcutStyle } from './shortcutStyle.ts';

type ShortcutsProps = {
  readonly children?: never;
  readonly shortcuts: string;
  readonly shortcutStyle?: ShortcutStyle;
};

const shortcutDividerReg = /(\+)/g;

const StyledShortcutSet = styled(Inline)`
  ${Typography.BodySmall};
`;

export const ShortcutSet = React.forwardRef<
  HTMLDivElement,
  ShortcutsProps & HTMLAttributes<HTMLDivElement>
>(({ shortcuts, shortcutStyle = 'default', ...otherProps }, forwardedRef) => (
  <StyledShortcutSet
    align="center"
    color={shortcutStyle === 'default' ? colorTextDefault : colorTextDefaultInverse}
    component="span"
    ref={forwardedRef}
    noWrap
    {...otherProps}
    {...getDataUiComponentAttribute(ShortcutSet)}
  >
    {shortcuts
      .split(shortcutDividerReg)
      .map((text) =>
        text === '+' ? (
          text
        ) : (
          <ShortcutKey shortcutStyle={shortcutStyle} text={text.trim()} key={text} />
        ),
      )}
  </StyledShortcutSet>
));

ShortcutSet.displayName = 'ShortcutSet';
