import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgCamera = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
          <path d="M8 5.97119C6.34315 5.97119 5 7.31434 5 8.97119C5 10.628 6.34315 11.9712 8 11.9712C9.65685 11.9712 11 10.628 11 8.97119C11 7.31434 9.65685 5.97119 8 5.97119ZM6 8.97119C6 7.86662 6.89543 6.97119 8 6.97119C9.10457 6.97119 10 7.86662 10 8.97119C10 10.0758 9.10457 10.9712 8 10.9712C6.89543 10.9712 6 10.0758 6 8.97119Z" />
          <path d="M5.11783 2C4.93889 2 4.77359 2.09563 4.68438 2.25075L3.67473 4.00649H1.65629C1.20464 4.00649 0.786009 4.1503 0.476243 4.44317C0.163945 4.73843 0.00244141 5.14778 0.00244141 5.5974V13.4091C0.00244141 13.8587 0.163945 14.2681 0.476243 14.5633C0.786009 14.8562 1.20464 15 1.65629 15H14.3486C14.8002 15 15.2189 14.8562 15.5286 14.5633C15.8409 14.2681 16.0024 13.8587 16.0024 13.4091V5.5974C16.0024 5.14778 15.8409 4.73843 15.5286 4.44317C15.2189 4.1503 14.8002 4.00649 14.3486 4.00649H12.3302L11.3205 2.25075C11.2313 2.09563 11.066 2 10.8871 2H5.11783ZM4.39742 4.75575L5.40708 3H10.5978L11.6075 4.75575C11.6967 4.91087 11.862 5.00649 12.0409 5.00649H14.3486C14.5893 5.00649 14.7475 5.08087 14.8416 5.16982C14.9332 5.25637 15.0024 5.39248 15.0024 5.5974V13.4091C15.0024 13.614 14.9332 13.7501 14.8416 13.8367C14.7475 13.9256 14.5893 14 14.3486 14H1.65629C1.41563 14 1.25734 13.9256 1.16325 13.8367C1.07171 13.7501 1.00244 13.614 1.00244 13.4091V5.5974C1.00244 5.39248 1.07171 5.25637 1.16325 5.16982C1.25734 5.08087 1.41563 5.00649 1.65629 5.00649H3.96398C4.14292 5.00649 4.30822 4.91087 4.39742 4.75575Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
