import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgFlag = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M14.5013 3.01087H9.77303C8.42869 3.01087 7.76739 2.59955 7.00249 2.12379L6.93198 2.07996C6.52427 1.82679 6.06782 1.54984 5.48113 1.34175C4.89048 1.13226 4.18678 0.998552 3.27165 0.998535L1.49895 0.998535C1.22281 0.998545 0.998535 1.2224 0.998535 1.49854V14.5015C0.998535 14.7776 1.22239 15.0015 1.49854 15.0015C1.77468 15.0015 1.99854 14.7776 1.99854 14.5015V9.02693H3.27164C4.10812 9.02693 4.70887 9.14601 5.18537 9.31482C5.665 9.48474 6.03933 9.71167 6.43452 9.96228L6.52581 10.0203C6.88979 10.252 7.28999 10.5067 7.77701 10.7009C8.3156 10.9158 8.94683 11.0531 9.77303 11.0531H14.5005C14.6867 11.0531 14.8583 10.9496 14.9445 10.7846C15.0307 10.6195 15.018 10.4202 14.9116 10.2674L12.7395 7.1477L14.9209 3.78286C15.0205 3.6291 15.0281 3.43313 14.9406 3.27212C14.8531 3.11111 14.6846 3.01087 14.5013 3.01087ZM1.99854 8.02693V1.99853L3.27163 1.99854C4.08416 1.99855 4.67368 2.1164 5.14686 2.28423C5.624 2.45346 6.00201 2.67959 6.40444 2.92949L6.47869 2.97566C7.28159 3.47521 8.14253 4.01087 9.77303 4.01087H13.5813L11.7176 6.88567C11.6071 7.05617 11.6107 7.2766 11.7268 7.44336L13.5439 10.0531H9.77303C9.06607 10.0531 8.56133 9.93719 8.14753 9.77212C7.75111 9.61399 7.42582 9.40731 7.05014 9.16862L6.97007 9.11778C6.56648 8.86184 6.11204 8.58222 5.51931 8.37223C4.92345 8.16113 4.20827 8.02693 3.27164 8.02693H1.99854Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
