import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgDatabase = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M4.5 3C4.22386 3 4 3.22386 4 3.5 4 3.77614 4.22386 4 4.5 4L7.5 4C7.77614 4 8 3.77614 8 3.5 8 3.22386 7.77614 3 7.5 3L4.5 3ZM4.5 12C4.22386 12 4 12.2239 4 12.5 4 12.7761 4.22386 13 4.5 13H7.5C7.77614 13 8 12.7761 8 12.5 8 12.2239 7.77614 12 7.5 12H4.5ZM4.5 7C4.22386 7 4 7.22386 4 7.5 4 7.77614 4.22386 8 4.5 8L7.5 8C7.77614 8 8 7.77614 8 7.5 8 7.22386 7.77614 7 7.5 7L4.5 7Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M14 4.83333C14 5.08222 13.9246 5.31159 13.7863 5.5C13.9246 5.68841 14 5.91778 14 6.16667V8.83333C14 9.09431 13.9172 9.33382 13.7657 9.52716C13.9224 9.74885 13.9999 10.0176 13.9999 10.3L14 14.7C14 15.0564 13.8765 15.391 13.6262 15.6368C13.3767 15.8818 13.0405 16 12.6852 16L3.31481 16C2.95947 16 2.6233 15.8818 2.37378 15.6368C2.12344 15.391 2 15.0564 2 14.7V10.3C2 10.0175 2.07752 9.74881 2.23428 9.52712C2.08283 9.33378 2 9.09429 2 8.83333L2.00003 6.16667C2.00003 5.91779 2.07538 5.68843 2.21373 5.50002C2.07536 5.3116 2 5.08223 2 4.83333L2.00003 2.16667C2.00003 1.819 2.14706 1.50943 2.40748 1.29635C2.65911 1.09048 2.98329 1 3.31484 1H12.6852C13.0167 1 13.3409 1.09048 13.5925 1.29635C13.853 1.50943 14 1.819 14 2.16667V4.83333ZM3.00003 2.16667C3.00003 2.11434 3.01596 2.09057 3.04072 2.07031C3.07428 2.04285 3.1575 2 3.31484 2H12.6852C12.8425 2 12.9257 2.04285 12.9593 2.07031C12.9841 2.09057 13 2.11434 13 2.16667V4.83333C13 4.88566 12.9841 4.90943 12.9593 4.92969C12.9257 4.95715 12.8425 5 12.6852 5H3.31484C3.1575 5 3.07425 4.95715 3.04069 4.92969C3.01593 4.90943 3 4.88566 3 4.83334L3.00003 2.16667ZM3.31481 10H12.6851C12.8187 10 12.8899 10.0418 12.9256 10.0768C12.9604 10.111 12.9999 10.1764 12.9999 10.3L13 14.7C13 14.8236 12.9604 14.889 12.9256 14.9232C12.8899 14.9582 12.8187 15 12.6852 15L3.31481 15C3.18127 15 3.11003 14.9582 3.07437 14.9232C3.03952 14.889 3 14.8236 3 14.7V10.3C3 10.1764 3.03952 10.111 3.07437 10.0768C3.11003 10.0418 3.18127 10 3.31481 10ZM12.6851 9H3.31481C3.15747 9 3.07425 8.95715 3.04069 8.92969C3.01593 8.90943 3 8.88566 3 8.83334L3.00003 6.16667C3.00003 6.11434 3.01596 6.09057 3.04072 6.07031C3.07428 6.04285 3.1575 6 3.31484 6H12.6852C12.8425 6 12.9257 6.04285 12.9593 6.07031C12.9841 6.09057 13 6.11434 13 6.16667V8.83333C13 8.88566 12.9841 8.90943 12.9593 8.92969C12.9257 8.95715 12.8425 9 12.6851 9Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
