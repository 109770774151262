import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgB = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3.5 1C3.22386 1 3 1.22436 3 1.50113V14.4989C3 14.7756 3.22386 15 3.5 15H8.9995C9.00999 15 9.0204 14.9997 9.03072 14.999C11.2263 14.9815 13.0035 13.1976 13.0035 10.9962C13.0035 9.37213 12.0362 7.9753 10.6473 7.34732C11.4722 6.70523 11.9965 5.65293 11.9965 4.4978C11.9965 2.62005 10.611 1.01405 8.80112 1.00009L8.79146 1H3.5ZM8.99805 7.99188H8.92843C8.87841 7.99435 8.82804 7.99559 8.77734 7.99559V7.99188H4V13.9977H8.99805C10.6595 13.9977 12.0035 12.6519 12.0035 10.9962C12.0035 9.34056 10.6595 7.99555 8.99805 7.99555V7.99188ZM10.9965 4.4978C10.9965 5.87325 10.0289 6.91976 8.89785 6.98961H4V2.00227H8.77734C9.95934 2.00227 10.9965 3.07373 10.9965 4.4978Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
