import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgInvoice = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M5.5033 2.99412C5.2272 2.99412 5.00339 3.21798 5.00339 3.49412 5.00339 3.77027 5.2272 3.99412 5.5033 3.99412L7.50295 3.99412C7.77904 3.99412 8.00286 3.77027 8.00286 3.49412 8.00286 3.21798 7.77904 2.99412 7.50295 2.99412L5.5033 2.99412ZM5.00338 5.49461C5.00338 5.21847 5.2272 4.99461 5.50329 4.99461L10.5024 4.99461C10.7785 4.99461 11.0023 5.21847 11.0023 5.49461 11.0023 5.77075 10.7785 5.99461 10.5024 5.99461L5.50329 5.99461C5.2272 5.99461 5.00338 5.77075 5.00338 5.49461ZM5.50329 6.99656C5.2272 6.99656 5.00338 7.22042 5.00338 7.49656 5.00338 7.77271 5.2272 7.99656 5.50329 7.99656L10.5024 7.99656C10.7785 7.99656 11.0023 7.77271 11.0023 7.49656 11.0023 7.22042 10.7785 6.99656 10.5024 6.99656L5.50329 6.99656ZM5.00338 9.49803C5.00338 9.22189 5.2272 8.99803 5.50329 8.99803L10.5024 8.99803C10.7785 8.99803 11.0023 9.22189 11.0023 9.49803 11.0023 9.77417 10.7785 9.99803 10.5024 9.99803L5.50329 9.99803C5.2272 9.99803 5.00338 9.77417 5.00338 9.49803ZM9.5052 12.0024C9.22911 12.0024 9.00529 12.2263 9.00529 12.5024 9.00529 12.7786 9.22911 13.0024 9.5052 13.0024H11.5008C11.7769 13.0024 12.0007 12.7786 12.0007 12.5024 12.0007 12.2263 11.7769 12.0024 11.5008 12.0024H9.5052Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3.49974 0C2.67145 0 2 0.671573 2 1.5V14.5C2 15.3284 2.67145 16 3.49974 16H12.5003C13.3285 16 14 15.3284 14 14.5V1.5C14 0.671573 13.3285 0 12.5003 0H3.49974ZM2.99982 1.5C2.99982 1.22386 3.22364 1 3.49974 1H12.5003C12.7764 1 13.0002 1.22386 13.0002 1.5V14.5C13.0002 14.7761 12.7764 15 12.5003 15H3.49974C3.22364 15 2.99982 14.7761 2.99982 14.5V1.5Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
