import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgConvert = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1.00317 2.49703C1.00317 1.66917 1.67474 0.998047 2.50317 0.998047H5.51016C6.33858 0.998047 7.01015 1.66914 7.01016 2.49699L7.01023 6.49634C7.01024 6.77229 7.23409 6.99599 7.51023 6.99599H10.7005L9.62617 5.92245C9.43091 5.72732 9.43091 5.41095 9.62617 5.21582C9.82144 5.02069 10.138 5.02069 10.3333 5.21582L12.2765 7.1577C12.4718 7.35283 12.4718 7.6692 12.2765 7.86432L10.3333 9.8062C10.138 10.0013 9.82144 10.0013 9.62617 9.8062C9.43091 9.61108 9.43091 9.29471 9.62617 9.09958L10.7312 7.9953H7.51023C6.68182 7.9953 6.01025 7.32421 6.01023 6.49636L6.01016 2.49701C6.01016 2.22106 5.7863 1.99736 5.51016 1.99736H2.50317C2.22703 1.99736 2.00317 2.22107 2.00317 2.49703L2.00326 13.4991C2.00327 13.775 2.22712 13.9987 2.50327 13.9987H5.51025C5.78639 13.9987 6.01025 13.775 6.01025 13.4991V11.4988C6.01025 11.2228 6.23411 10.9991 6.51025 10.9991C6.78639 10.9991 7.01025 11.2228 7.01025 11.4988V13.4991C7.01025 14.3269 6.33868 14.998 5.51025 14.998H2.50327C1.67484 14.998 1.00327 14.3269 1.00326 13.4991L1.00317 2.49703Z"
        />
        <path
          fill="currentColor"
          d="M13.5042 2.00615L10.4969 2.00618C10.2207 2.00618 9.99685 2.22988 9.99685 2.50583V3.50659C9.99685 3.78254 9.773 4.00625 9.49685 4.00625C9.22071 4.00625 8.99685 3.78254 8.99685 3.50659V2.50583C8.99685 1.67798 9.66842 1.00687 10.4968 1.00686L13.5042 1.00684C14.3326 1.00683 15.0042 1.67794 15.0042 2.50579L15.0043 13.499C15.0043 14.3269 14.3327 14.998 13.5043 14.998H10.495C9.6645 14.998 8.99207 14.3236 8.99504 13.4937L9.00221 11.4886C9.00319 11.2126 9.22785 10.9897 9.50399 10.9907C9.78013 10.9917 10.0032 11.2162 10.0022 11.4921L9.99503 13.4973C9.99404 13.7739 10.2182 13.9987 10.495 13.9987H13.5043C13.7805 13.9987 14.0043 13.775 14.0043 13.499L14.0042 2.5058C14.0042 2.22985 13.7803 2.00615 13.5042 2.00615Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
