import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLRows2 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
          <path d="M15 1.50384C15 1.228 14.7761 1.0044 14.5 1.0044L1.5 1.00439C1.22386 1.00439 1 1.228 1 1.50384L1 6.49826C1 6.7741 1.22386 6.9977 1.5 6.9977L14.5 6.99771C14.7761 6.99771 15 6.7741 15 6.49826L15 1.50384ZM2 2.00328 14 2.00328V5.99882L2 5.99882 2 2.00328ZM15 8.51164C15 8.23581 14.7761 8.0122 14.5 8.0122L1.5 8.0122C1.22386 8.0122 1 8.23581 1 8.51164L1 14.505C1 14.7808 1.22386 15.0044 1.5 15.0044L14.5 15.0044C14.7761 15.0044 15 14.7808 15 14.505V8.51164ZM2 9.01108 14 9.01108V14.0055L2 14.0055 2 9.01108Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" transform="rotate(90 8 8)" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
