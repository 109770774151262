import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgPc = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M0 3.49971C0 2.67144 0.671556 2 1.49996 2H11.4926C12.321 2 12.9925 2.67144 12.9925 3.49971V9.47646C12.9925 10.3047 12.321 10.9762 11.4926 10.9762H7.02863V12.9963H8.52677C8.8029 12.9963 9.02675 13.2201 9.02675 13.4962C9.02675 13.7723 8.8029 13.9961 8.52677 13.9961H6.59136C6.57082 13.9987 6.54989 14 6.52865 14C6.5074 14 6.48647 13.9987 6.46593 13.9961H4.52967C4.25354 13.9961 4.02968 13.7723 4.02968 13.4962C4.02968 13.2201 4.25354 12.9963 4.52967 12.9963H6.02866V10.9762H1.49996C0.671557 10.9762 0 10.3047 0 9.47646V3.49971ZM1.49996 2.9998C1.22383 2.9998 0.999975 3.22362 0.999975 3.49971V9.47646C0.999975 9.75254 1.22383 9.97636 1.49996 9.97636H11.4926C11.7687 9.97636 11.9925 9.75255 11.9925 9.47646V3.49971C11.9925 3.22362 11.7687 2.9998 11.4926 2.9998H1.49996Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M14.0013 4.5232C14.0013 4.24711 14.2252 4.0233 14.5013 4.0233H15.5C15.7761 4.0233 16 4.24711 16 4.5232V13.4966C16 13.7727 15.7761 13.9965 15.5 13.9965H10.5036C10.2275 13.9965 10.0037 13.7727 10.0037 13.4966V12.4685C10.0037 12.1925 10.2275 11.9686 10.5036 11.9686C10.7798 11.9686 11.0036 12.1925 11.0036 12.4685V12.9967H15V12.0149H13.4894C13.2133 12.0149 12.9894 11.7911 12.9894 11.515C12.9894 11.2389 13.2133 11.0151 13.4894 11.0151H15V5.0231H14.5013C14.2252 5.0231 14.0013 4.79929 14.0013 4.5232Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
