import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgS = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M9.51025.00488281C11.4432.00488281 13.0103 1.57237 13.0103 3.50596V4.08608C13.0103 4.36231 12.7864 4.58624 12.5103 4.58624 12.2341 4.58624 12.0103 4.36231 12.0103 4.08608V3.50596C12.0103 2.12482 10.891 1.00519 9.51025 1.00519L6.51025 1.00519C5.12954 1.00519 4.01025 2.12482 4.01025 3.50596 4.01025 4.88481 5.1378 6.00673 6.5208 6.00673V6.00982C6.53375 6.00881 6.54685 6.0083 6.56006 6.0083 6.83512 6.0083 7.0581 6.23101 7.0581 6.50574 7.0581 6.78046 6.83512 7.00318 6.56006 7.00318 6.54685 7.00318 6.53375 7.00266 6.5208 7.00165V7.00703C4.59009 7.00703 3.01025 5.44183 3.01025 3.50596 3.01025 1.57237 4.57726.00488281 6.51025.00488281H9.51025ZM6.48779 16.0049C4.5548 16.0049 2.98779 14.4374 2.98779 12.5038V12.4443C2.98779 12.1681 3.21165 11.9441 3.48779 11.9441 3.76394 11.9441 3.98779 12.1681 3.98779 12.4443V12.5038C3.98779 13.8849 5.10708 15.0046 6.48779 15.0046H9.48779C10.8685 15.0046 11.9878 13.8849 11.9878 12.5038V11.6995C11.9878 10.4845 11.1147 9.4453 9.9182 9.23611L8.6332 9.01146C8.62508 9.01004 8.61704 9.00843 8.60909 9.00664H1.5C1.22386 9.00664 1 8.78271 1 8.50649 1 8.23026 1.22386 8.00633 1.5 8.00633H14.5C14.7761 8.00633 15 8.23026 15 8.50649 15 8.78271 14.7761 9.00664 14.5 9.00664H11.7248C12.5026 9.65389 12.9878 10.6279 12.9878 11.6995V12.5038C12.9878 14.4374 11.4208 16.0049 9.48779 16.0049H6.48779Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
