import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgRotateRight = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M13.4171 7.01252C12.965 4.15593 10.5841 2.00017 7.74713 2.00017C4.59141 2.00017 1.99991 4.66764 1.99991 8C1.99991 11.3324 4.5914 13.9998 7.74713 13.9998C9.13137 13.9998 10.4023 13.4894 11.3968 12.6349C11.6063 12.4549 11.9219 12.4788 12.1018 12.6883C12.2818 12.8978 12.2578 13.2136 12.0484 13.3935C10.8826 14.3952 9.38339 15 7.74713 15C4.00241 15 1 11.8472 1 8C1 4.15276 4.00241 1 7.74713 1C11.1658 1 13.9658 3.62766 14.4275 7.01252H15.4992C15.8916 7.01252 16.1311 7.44402 15.9235 7.77712L14.3254 10.342C14.1297 10.6561 13.6725 10.6561 13.4768 10.342L11.8786 7.77712C11.6711 7.44402 11.9105 7.01252 12.3029 7.01252H13.4171Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
