import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLHeaderMenuText = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.49965 1C1.22397 1 1.00049 1.22419 1.00049 1.50075V4.50527C1.00049 4.78182 1.22397 5.00602 1.49965 5.00602H14.4779C14.7536 5.00602 14.9771 4.78182 14.9771 4.50527V1.50075C14.9771 1.22419 14.7536 1 14.4779 1H1.49965ZM1.99882 4.00451V2.0015H13.9788V4.00451H1.99882Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M1 7.51271C1 7.23616 1.22348 7.01196 1.49916 7.01196H3.49582C3.7715 7.01196 3.99499 7.23616 3.99499 7.51271 3.99499 7.78927 3.7715 8.01347 3.49582 8.01347H1.49916C1.22348 8.01347 1 7.78927 1 7.51271ZM1 9.51817C1 9.24161 1.22348 9.01742 1.49916 9.01742H3.49582C3.7715 9.01742 3.99499 9.24161 3.99499 9.51817 3.99499 9.79473 3.7715 10.0189 3.49582 10.0189H1.49916C1.22348 10.0189 1 9.79473 1 9.51817ZM6.51421 7.01196C6.23852 7.01196 6.01504 7.23616 6.01504 7.51271 6.01504 7.78927 6.23852 8.01347 6.51421 8.01347H14.5008C14.7765 8.01347 15 7.78927 15 7.51271 15 7.23616 14.7765 7.01196 14.5008 7.01196H6.51421ZM6.01455 9.51817C6.01455 9.24161 6.23804 9.01742 6.51372 9.01742L14.5003 9.01742C14.776 9.01742 14.9995 9.24161 14.9995 9.51817 14.9995 9.79473 14.776 10.0189 14.5003 10.0189L6.51372 10.0189C6.23804 10.0189 6.01455 9.79473 6.01455 9.51817ZM6.51421 11.0058C6.23852 11.0058 6.01504 11.2299 6.01504 11.5065 6.01504 11.7831 6.23852 12.0073 6.51421 12.0073H14.5008C14.7765 12.0073 15 11.7831 15 11.5065 15 11.2299 14.7765 11.0058 14.5008 11.0058H6.51421ZM6.01504 13.4992C6.01504 13.2227 6.23852 12.9985 6.51421 12.9985H10.5075C10.7832 12.9985 11.0067 13.2227 11.0067 13.4992 11.0067 13.7758 10.7832 14 10.5075 14H6.51421C6.23852 14 6.01504 13.7758 6.01504 13.4992Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
