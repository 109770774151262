import styled from 'styled-components';
import { AnimatedProgressIcon } from '../../Icons/AnimatedProgressIcon.tsx';
import {
  buttonIconColorVariableName,
  buttonIconHoverColorVariableName,
  buttonIconSizeVariableName,
} from '../utils/cssVariableNames.ts';
import { StyledButton } from './StyledButton.tsx';

export const ButtonProgressIcon = styled(AnimatedProgressIcon)`
  color: var(${buttonIconColorVariableName});
  font-size: var(${buttonIconSizeVariableName});

  ${StyledButton}:hover &&,
  ${StyledButton}:active && {
    color: var(${buttonIconHoverColorVariableName});
  }
`;
