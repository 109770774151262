import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLLogotypeMenuColFooter = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.00049 3.00126C1.00049 1.89599 1.89592 1 3.00049 1C4.10506 1 5.00049 1.89599 5.00049 3.00126C5.00049 4.10652 4.10506 5.00251 3.00049 5.00251C1.89592 5.00251 1.00049 4.10652 1.00049 3.00126ZM3.00049 2.00063C2.4482 2.00063 2.00049 2.44862 2.00049 3.00126C2.00049 3.55389 2.4482 4.00188 3.00049 4.00188C3.55277 4.00188 4.00049 3.55389 4.00049 3.00126C4.00049 2.44862 3.55277 2.00063 3.00049 2.00063Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M1.5 5.98653C1.22386 5.98653 1 6.21053 1 6.48684 1 6.76316 1.22386 6.98716 1.5 6.98716H14.5C14.7761 6.98716 15 6.76316 15 6.48684 15 6.21053 14.7761 5.98653 14.5 5.98653H1.5ZM1.5 13.9994C1.22386 13.9994 1 14.2234 1 14.4997 1 14.776 1.22386 15 1.5 15H14.5C14.7761 15 15 14.776 15 14.4997 15 14.2234 14.7761 13.9994 14.5 13.9994H1.5Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.5 7.9956C1.22386 7.9956 1 8.2196 1 8.49592V12.4984C1 12.7747 1.22386 12.9987 1.5 12.9987H14.5C14.7761 12.9987 15 12.7747 15 12.4984V8.49592C15 8.2196 14.7761 7.9956 14.5 7.9956H1.5ZM2 11.9981V8.99623H14V11.9981H2Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
