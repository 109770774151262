import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgPiechart = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58171 12.4183 0 8 0C3.58171 0 0 3.58171 0 8C0 12.4183 3.58171 16 8 16ZM8 15C11.866 15 15 11.866 15 8C15 6.68561 14.6377 5.45583 14.0076 4.40506L11.9904 5.91796C12.3159 6.54055 12.5 7.24878 12.5 8C12.5 10.4853 10.4853 12.5 8 12.5C5.85719 12.5 4.06419 11.0023 3.61073 8.99657H1.0704C1.55415 12.3905 4.47233 15 8 15ZM11.4307 5.08775C10.6076 4.11907 9.38151 3.50346 8.01156 3.50001V1.00001C10.1996 1.00355 12.1522 2.01098 13.4335 3.58633C13.427 3.59072 13.4207 3.59527 13.4144 3.6L11.4307 5.08775ZM1 7.99657C1.00169 4.46762 3.61473 1.54933 7.01156 1.06924V3.6089C5.00284 4.05911 3.5016 5.85238 3.5 7.99657H1ZM8 11.5C9.933 11.5 11.5 9.933 11.5 8C11.5 6.067 9.933 4.5 8 4.5C6.067 4.5 4.5 6.067 4.5 8C4.5 9.933 6.067 11.5 8 11.5Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
