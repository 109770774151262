import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgICircle = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M15 8C15 4.134 11.866 0.999999 8 0.999999C4.134 0.999999 1 4.134 1 8C1 11.866 4.134 15 8 15C11.866 15 15 11.866 15 8ZM16 8C16 3.58171 12.4183 0 8 0C3.58171 0 0 3.58171 0 8C0 12.4183 3.58171 16 8 16C12.4183 16 16 12.4183 16 8ZM9 5.00025C9 4.44703 8.552 3.99805 8 3.99805C7.448 3.99805 7 4.44703 7 5.00025C7 5.55346 7.448 6.00245 8 6.00245C8.552 6.00245 9 5.55346 9 5.00025ZM8 11.998C7.44772 11.998 7 11.5493 7 10.9958V7.98924C7 7.43574 7.44772 7 8 7C8.55229 7 9 7.43574 9 7.98924V10.9958C9 11.5493 8.55228 11.998 8 11.998Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
