import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgFolderBelt = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M2.5 1C1.11929 1 0 2.11929 0 3.5V12.5C0 13.8807 1.11929 15 2.5 15H13.5C14.8807 15 16 13.8807 16 12.5V4.48881C16 3.66038 15.3284 2.98881 14.5 2.98881H9.15287C8.97461 2.98881 8.80984 2.89391 8.72038 2.73972L8.14458 1.74726C7.87622 1.2847 7.3819 1 6.84713 1H2.5ZM1 3.5C1 2.67157 1.67157 2 2.5 2H6.84713C7.02539 2 7.19016 2.0949 7.27962 2.24909L7.85541 3.24155C8.12378 3.70411 8.6181 3.98881 9.15287 3.98881H14.5C14.7761 3.98881 15 4.21267 15 4.48881V8.99463L11.0215 8.99463V8.51758C11.0215 7.68915 10.3499 7.01758 9.52148 7.01758H6.52148C5.69306 7.01758 5.02148 7.68915 5.02148 8.51758V8.99463H1V3.5ZM1 9.99463V12.5C1 13.3284 1.67157 14 2.5 14H13.5C14.3284 14 15 13.3284 15 12.5V9.99463H10.944C10.7447 10.5892 10.1831 11.0176 9.52148 11.0176H6.52148C5.85983 11.0176 5.29823 10.5892 5.09894 9.99463H1ZM6.02148 8.51758C6.02148 8.24144 6.24534 8.01758 6.52148 8.01758H9.52148C9.79763 8.01758 10.0215 8.24144 10.0215 8.51758V9.51758C10.0215 9.79372 9.79763 10.0176 9.52148 10.0176H6.52148C6.24534 10.0176 6.02148 9.79372 6.02148 9.51758V8.51758Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
