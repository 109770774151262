import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgBin = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M5.07471.498291C5.07471.222149 5.29856-.00170898 5.57471-.00170898H10.5747C10.8509-.00170898 11.0747.222149 11.0747.498291 11.0747.774434 10.8508.998291 10.5747.998291L5.57471.998291C5.29856.998291 5.07471.774433 5.07471.498291ZM2 2.50879C2 2.23265 2.22386 2.00879 2.5 2.00879L13.5 2.00879C13.7761 2.00879 14 2.23265 14 2.50879 14 2.78493 13.7761 3.00879 13.5 3.00879L2.5 3.00879C2.22386 3.00879 2 2.78493 2 2.50879ZM4.01172 5.5C4.01172 5.22386 3.78786 5 3.51172 5 3.23558 5 3.01172 5.22386 3.01172 5.5V13.7442C3.01172 14.9747 3.99578 16 5.226 16H10.7974C12.0277 16 13.0117 14.9747 13.0117 13.7442V5.5C13.0117 5.22386 12.7879 5 12.5117 5 12.2356 5 12.0117 5.22386 12.0117 5.5V13.7442C12.0117 14.4451 11.4529 15 10.7974 15H5.226C4.57051 15 4.01172 14.4451 4.01172 13.7442V5.5Z" />
          <path d="M6.52734 5C6.80349 5 7.02734 5.22386 7.02734 5.5V12.5C7.02734 12.7761 6.80349 13 6.52734 13 6.2512 13 6.02734 12.7761 6.02734 12.5V5.5C6.02734 5.22386 6.2512 5 6.52734 5ZM10.0195 5.5C10.0195 5.22386 9.79567 5 9.51953 5 9.24339 5 9.01953 5.22386 9.01953 5.5V12.5C9.01953 12.7761 9.24339 13 9.51953 13 9.79567 13 10.0195 12.7761 10.0195 12.5V5.5Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
