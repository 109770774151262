import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgArrowsCrooked = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M12.8536.146447C12.6583-.0488155 12.3417-.0488155 12.1464.146447 11.9512.341709 11.9512.658291 12.1464.853553L14.2929 3H1.5C1.06353 3 .63764 3.08468.342212 3.40235.0544949 3.711734.85898e-8 4.128074.85898e-8 4.5V6.5C4.85898e-8 6.77614.223858 7 .5 7 .776142 7 1 6.77614 1 6.5V4.5C1 4.18621 1.05664 4.10255 1.07449 4.08336 1.08463 4.07246 1.15874 4 1.5 4H14.2929L12.1464 6.14645C11.9512 6.34171 11.9512 6.65829 12.1464 6.85355 12.3417 7.04882 12.6583 7.04882 12.8536 6.85355L15.8536 3.85355C16.0488 3.65829 16.0488 3.34171 15.8536 3.14645L12.8536.146447ZM15.5002 9C15.7764 9 16.0002 9.22386 16.0002 9.5V11.5C16.0002 11.9126 15.7989 12.2834 15.5413 12.5411 15.2836 12.7987 14.9128 13 14.5002 13H1.70711L3.85355 15.1464C4.04882 15.3417 4.04882 15.6583 3.85355 15.8536 3.65829 16.0488 3.34171 16.0488 3.14645 15.8536L.146447 12.8536C-.047168 12.6599-.0490397 12.3466.142248 12.1507L3.07141 9.1507C3.26433 8.95311 3.58089 8.94933 3.77847 9.14225 3.97605 9.33516 3.97983 9.65172 3.78692 9.8493L1.687 12H14.5002C14.5876 12 14.7168 11.9513 14.8342 11.8339 14.9515 11.7166 15.0002 11.5874 15.0002 11.5V9.5C15.0002 9.22386 15.2241 9 15.5002 9Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" transform="rotate(-90 8 8)" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
