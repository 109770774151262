import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLText = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1 1.50518C1 1.22888 1.22386 1.00488 1.5 1.00488H14.5C14.7761 1.00488 15 1.22888 15 1.50518 15 1.78149 14.7761 2.00548 14.5 2.00548H1.5C1.22386 2.00548 1 1.78149 1 1.50518ZM1 3.50248C1 3.22617 1.22386 3.00218 1.5 3.00218H14.5C14.7761 3.00218 15 3.22617 15 3.50248 15 3.77879 14.7761 4.00278 14.5 4.00278H1.5C1.22386 4.00278 1 3.77879 1 3.50248ZM1.5 5.00338C1.22386 5.00338 1 5.22737 1 5.50368 1 5.77999 1.22386 6.00398 1.5 6.00398H14.5C14.7761 6.00398 15 5.77999 15 5.50368 15 5.22737 14.7761 5.00338 14.5 5.00338H1.5ZM1 7.50488C1 7.22857 1.22386 7.00458 1.5 7.00458H14.5C14.7761 7.00458 15 7.22857 15 7.50488 15 7.78119 14.7761 8.00518 14.5 8.00518H1.5C1.22386 8.00518 1 7.78119 1 7.50488ZM1.5 9.00578C1.22386 9.00578 1 9.22978 1 9.50609 1 9.78239 1.22386 10.0064 1.5 10.0064H14.5C14.7761 10.0064 15 9.78239 15 9.50609 15 9.22978 14.7761 9.00578 14.5 9.00578H1.5ZM1 11.5044C1 11.228 1.22386 11.0041 1.5 11.0041H14.5C14.7761 11.0041 15 11.228 15 11.5044 15 11.7807 14.7761 12.0047 14.5 12.0047H1.5C1.22386 12.0047 1 11.7807 1 11.5044ZM1.49951 13.0043C1.22337 13.0043.999512 13.2283.999512 13.5046.999512 13.7809 1.22337 14.0049 1.49951 14.0049H6.49951C6.77565 14.0049 6.99951 13.7809 6.99951 13.5046 6.99951 13.2283 6.77565 13.0043 6.49951 13.0043H1.49951Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
